import React from 'react';
import { BenefitRow } from 'components/benefit/BenefitRow/BenefitRow';
import PropTypes from 'prop-types';
import { CollapsibleContainer } from 'components/global/CollapsibleContainer/CollapsibleContainer';

export const BenefitRowsList = ({ content, visibleEntriesAmount, value, onChange, dataTestId }) => {
    const renderList = (array) => {
        const handleBenefitRowChange = (newValue) => {
            if (onChange) {
                onChange({
                    ...value,
                    ...newValue
                })
            }
        }

        return array?.map((item, index) => {
            return <BenefitRow
                key={index}
                label={item.name}
                fieldId={item?.fieldId}
                content={item.elements}
                onChange={handleBenefitRowChange}
                isHighlighted={index % 2 === 0}
                dataTestId={`${dataTestId}`}
            />
        })
    }

    return (
        <CollapsibleContainer
            entries={content}
            visibleEntriesAmount={visibleEntriesAmount}
            renderList={renderList}
        />
    )
}

BenefitRowsList.propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        elements: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            link: PropTypes.string,
            img: PropTypes.arrayOf(PropTypes.object)
        }))
    })).isRequired,
    visibleEntriesAmount: PropTypes.number
}

BenefitRowsList.defaultProps = {
    visibleEntriesAmount: 10
}