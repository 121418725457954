import React from 'react'
import './BenefitFormStepFooterButtons.css'
import PropTypes from 'prop-types'
import { getButton } from 'utils/helpers/benefitBuilder.helper'
import { IconsNames } from 'utils/icons'

export const BenefitFormStepFooterButtons = ({
	footerButtons,
}) => {
	return footerButtons.map((button) => button)
}

BenefitFormStepFooterButtons.propTypes = {
	label: PropTypes.string,
	columns: PropTypes.object,
	footerButtonLabel: PropTypes.string,
}
