import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BenefitBuilderLabels, prepareFieldsData } from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_PROVIDER_STEP, BENEFIT_PROVIDER_STEP_NEW } from 'common/endpoints'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import {useSelector} from "react-redux";
import {benefitBuilder} from "store/selectors/benefitBuilder";

const Mode = {
	Existing: 'Existing',
	New: 'New'
}

const Endpoints = {
	[Mode.Existing]: BENEFIT_PROVIDER_STEP,
	[Mode.New]: BENEFIT_PROVIDER_STEP_NEW
}

export const Provider = ({ data, onChange }) => {
	const benefitID = useSelector(benefitBuilder.benefit)?.id
	const [state, setState] = useState();
	const [mode, setMode] = useState(Mode.Existing);
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)

	const { register, control } = useForm()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(Endpoints[mode](benefitID))
				const [{ content, label }] = response.data.steps
				const columns = prepareFieldsData(content)

				setState({ columns, label })

				onChange(label, {
					label,
					columns,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.Provider, {
					columns: [],
					isLoaded: true
				})
			}
		}

		if (mode) {
			setState()
			fetchData()
		}
	}, [mode])

	useEffect(() => {
		if (data && state) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleButtonClick = () => {
		if (mode === Mode.Existing) {
			setMode(Mode.New);
			return;
		}
		setMode(Mode.Existing)
	}

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			errors={benefitBuilderErrors}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
			onInnerButtonClick={handleButtonClick}
		/>
	)
}

Provider.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
