import React from 'react'
import PropTypes from 'prop-types'
import LoadableEditor from 'components/global/TextEditor/TextEditorLoadable'
import { FormField } from 'components/formFields/FormField/FormField'

export const FormEditor = ({
	defaultValue,
	label,
	isRequired,
	version,
	buttons,
	description,
	labelSize,
	placeholder,
	onChange,
	dataTestId,
	fieldErrors
}) => {
	return (
		<FormField
			label={label}
			isRequired={isRequired}
			labelSize={labelSize}
			buttons={buttons}
			version={version}
			description={description}
			fieldErrors={fieldErrors}
			dataTestId={dataTestId}
		>
			<LoadableEditor
				defaultValue={defaultValue}
				onChange={onChange}
				placeholder={placeholder}
				fieldErrors={fieldErrors}
				dataTestId={dataTestId}
			/>
		</FormField>
	)
}

FormEditor.propTypes = {
	defaultValue: PropTypes.string,
	onChange: PropTypes.func,
	label: PropTypes.string,
	description: PropTypes.string,
	version: PropTypes.string,
	placeholder: PropTypes.string,
	isRequired: PropTypes.bool,
	buttons: PropTypes.array,
	labelSize: PropTypes.string,
	dataTestId: PropTypes.string
}
