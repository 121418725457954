import fetchApi from 'common/fetchApi'
import { GET_ROLES, GET_USER, EDIT_USER } from 'common/endpoints';
import { FetchMethods } from 'utils/constants';

const getRoleById = async (id) => {
    try {
        const response = await fetchApi(`${GET_ROLES}/${id}`)
        const { data } = response

        return data
    } catch (error) {}
}

const getUserData = async (id) => {
    try {
        const response = await fetchApi(`${GET_USER}/${id}`)
        const { data } = response

        return data
    } catch(error) {

    }
}
const defaultState = {
    isLoading: false,
    isSending: false,
    isError: false,
    headerLabel: '',
    successMessage: '',
    rowData: {
        user_email: '',
        user_first_name: '',
        user_id: undefined,
        user_sur_name: '',
        user_type: undefined,
        user_type_prst_person_type_id: undefined
    },
    data: {
        date_of_birth: '',
        email: '',
        firstname: '',
        surname: '',
        id: undefined,
        permissions: undefined,
        roles: undefined,
        status: undefined,
    },
    user_type_id: undefined,
    status: undefined,
    role: undefined,
    errors: {},
};

export default {
    state: defaultState,
    reducers: {
        data(state, { data }) {
            return {
                ...state,
                data,
            }
        },

        userType(state, { user_type_id }) {
            return {
                ...state,
                user_type_id
            }
        },

        status(state, payload) {
            return {
                ...state,
                status: payload
            }
        },

        successMessage(state, { successMessage }) {
            return {
                ...state,
                successMessage
            }
        },

        role(state, payload) {
            return {
                ...state,
                role: payload
            }
        },

        errors(state, payload) {
            return {
                ...state,
                errors: {
                    ...payload.errors
                }
            }
        },

        rowData(state, payload) {
            return {
                ...state,
                rowData: payload
            }
        },

        permissions(state, { permissions }) {
            return {
                ...state,
                permissions,
            }
        },

        headerLabel(state, { headerLabel }) {
            return {
                ...state,
                headerLabel,
            }
        },

        isLoading(state, { isLoading }) {
            return {
                ...state,
                isLoading,
            }
        },

        isSending(state, { isSending }) {
            return {
                ...state,
                isSending,
            }
        },

        isError(state, { isError }) {
            return {
                ...state,
                isError,
            }
        },

        clearStore() {
            return defaultState;
        },
    },
    effects: () => ({
        async setSubmittedData({ rowData, data, role, status, user_type_id}) {
            this.isSending({ isSending: true })

            try {
                const response = await fetchApi(`${EDIT_USER}/${rowData.user_id}`, {
                    method: FetchMethods.Patch,
                    data: {
                        ...data,
                        status,
                        user_type_id,
                        role_name: role,
                    }
                })

                this.setSuccessMessage({ successMessage: response.data.message })
                this.setIsSending({ isSending: false})
                this.setIsError({ isError: false })
            } catch(error) {
				const { errors } = error.response.data

                this.setErrors({ errors })
                this.setIsError({ isError: true })
                this.setIsSending({ isSending: false })
            }
        },

        async setRoleData({ role: selectedRole, userTypeId }) {
            this.setIsLoading({ isLoading: true })

            if (Boolean(selectedRole) && Boolean(selectedRole?.length)) {
                const response = await fetchApi(GET_ROLES, {
                    method: FetchMethods.Get,
                    params: {
                        search: selectedRole,
                        user_type_id: userTypeId
                    }
                });
                const id = response.data?.data[0]?.id;

                getRoleById(id).then((data) => {
                    this.setPermissions({ permissions: data.role.permissions }).then(() => {
                        this.setIsLoading({ isLoading: false })
                    })
                })
            } else {
                this.setIsLoading({ isLoading: false })
            }
        },

        async setEditPage(rowData) {
			this.isLoading({ isLoading: true })
            this.setRowData(rowData)

			getUserData(rowData.user_id).then((data) => {
				this.data({ data })
				this.setIsLoading({ isLoading: false })
			})

			this.setHeaderLabel({
				headerLabel: `${rowData.user_first_name} ${rowData.user_sur_name}`,
			})
		},

        setUserType(user_type_id){
            this.userType({ user_type_id })
        },

        setRowData(rowData){
            this.rowData(rowData)
        },

        setHeaderLabel({ headerLabel }) {
            this.headerLabel({ headerLabel })
        },

        setSuccessMessage({ successMessage }) {
            this.successMessage({ successMessage })
        },

        setRole(payload) {
            this.role(payload)
        },

        setIsSending({ isSending }) {
            this.isSending({ isSending })
        },

        setIsLoading({ isLoading }) {
            this.isLoading({ isLoading })
        },

        setIsError({ isError }) {
            this.isError({ isError })
        },

        setPermissions({ permissions }) {
            this.permissions({ permissions })
        },

        setStatus(payload) {
            this.status(payload)
        },

        setClearStore() {
            this.clearStore()
        },

        setErrors(payload) {
            this.errors(payload)
        }
    })
}
