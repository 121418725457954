import React, { useState, useEffect } from 'react';
import './BenefitRowContent.css';
import PropTypes from 'prop-types';
import {ContentText} from 'components/commonFields/ContentText/ContentText';
import {ContentHyperlink} from 'components/commonFields/ContentHyperlink/ContentHyperlink';
import {ContentImage} from 'components/commonFields/ContentImage/ContentImage';
import {isNotEmpty} from 'utils/helpers/array.helper';
import {COPY_HYPERLINK} from 'utils/messages';
import {Tooltip} from 'components/global/Tooltip/Tooltip';
import copyIcon from 'icons/copy.svg';

const renderCopyIcon = (value) => {
    return (
        <Tooltip
            label={COPY_HYPERLINK}
            Component={
                <img
                    alt={COPY_HYPERLINK}
                    src={copyIcon}
                    className='content-hyperlink-icon'
                    onClick={() => copyToClipboard(value)}
                />
            }
        />
    )
};

const copyToClipboard = (value) => {
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(value);
    } else {
        const element = document.createElement('textarea');
        element.value = value;
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
    }
}

const ImageContainer = ({ images, dataTestId }) => {
    return (
        isNotEmpty(images) && (
            <div>
                {images.map((item, index) => {
                    return <ContentImage key={index} content={item} dataTestId={dataTestId} />
                })}
            </div>
        )
    )
}

const LinksContainer = ({ links, dataTestId, renderAdditionalIcon }) => {
    return (
        isNotEmpty(links) && (
            <div>
                {links.map((item, index) => {
                    return <ContentHyperlink
                        key={index}
                        content={item}
                        renderAdditionalIcon={renderAdditionalIcon}
                        dataTestId={dataTestId}
                    />
                })}
            </div>
        )
    )
}

const RadioButtonContainer = ({ fieldId, radioButtons, onChange, dataTestId }) => {
	const [buttons, setButtons] = useState()

	useEffect(() => {
		if (radioButtons) {
			setButtons(radioButtons)
		}
	}, [radioButtons])

	const handleOnChange = (label) => {
		const newRadioButtons = buttons.map((radioButton) => {
			return {
				...radioButton,
				selected: radioButton.label === label,
			}
		})

		setButtons(newRadioButtons)
		onChange({
            [fieldId]: newRadioButtons.find((button) => button.selected).field_id
        })
	}

	return (
		isNotEmpty(buttons) &&
            buttons.map((radioButton) => {
                const label = radioButton.label
                const radioButtonId = fieldId + label
                const updatedDataTestId = `${dataTestId}-label`

                return (
                    <div key={radioButtonId} className='radio-button-container'>
                        <input
                            id={radioButtonId}
                            className="dashboard-item-radio-input"
                            type="radio"
                            checked={radioButton.selected}
                            onChange={() => handleOnChange(label)}
                            data-testid={`${updatedDataTestId}-input`}
                        />
                        <label 
                            htmlFor={radioButtonId} 
                            className='radio-button-label'
                            data-testid={`${updatedDataTestId}-label`}
                        >{label}</label>
                    </div>
                )
            })
	)
}

export const BenefitRowContent = ({
	content,
	fieldId,
	onChange,
	dataTestId,
}) => {
	return content.map((item, index) => {
		const dataTestIdWithIndex = `${dataTestId}-${index}`

		return (
			<div
				key={index}
				className="benefit-row-content"
				data-testid={`${dataTestIdWithIndex}-content`}
			>
				<ContentText content={item.text} dataTestId={dataTestIdWithIndex} />
				<LinksContainer
					links={item.links}
					renderAdditionalIcon={renderCopyIcon}
					dataTestId={dataTestIdWithIndex}
				/>
				<LinksContainer
					links={item.documents}
					dataTestId={dataTestIdWithIndex}
				/>
				<ImageContainer images={item.images} dataTestId={dataTestIdWithIndex} />
				<RadioButtonContainer
					fieldId={fieldId}
					radioButtons={item.radioButtons}
					onChange={onChange}
                    dataTestId={dataTestIdWithIndex}
				/>
			</div>
		)
	})
}

BenefitRowContent.propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        link: PropTypes.string,
			img: PropTypes.arrayOf(PropTypes.object),
		})
	).isRequired,
	dataTestId: PropTypes.string,
	renderAdditionalIcon: PropTypes.func,
}
