export const isObjectEmpty = (obj) => {
	return (
		obj &&
		Object.keys(obj).length === 0 &&
		Object.getPrototypeOf(obj) === Object.prototype
	)
}

export const sort = (array, key) => {
	return array.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
}