import React from 'react'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import PropTypes from 'prop-types'
import {
	isOptionContains,
	isOptionExcluded,
} from 'utils/helpers/dropdown.helper'
import './AsyncDropdown.css'
import {AsyncPaginate} from "react-select-async-paginate";

export const AsyncDropdown = ({
    dropdownLabel,
	loadOptions,
    isSearchable,
    isDisabled,
    isReadOnly,
    isMulti,
	defaultOptions,
	defaultValue,
    placeholder,
    isError,
	handleDropdownChange,
    value,
    width,
    height = '48px',
	excludedOptions,
    filterStyle,
    dataTestId,
	customFilterStyles,
    infiniteScroll
}) => {

    const borderColor = (state) => {
        if (state.isDisabled || isReadOnly) {
            return '1px solid #BAC3D2'
        }

        if (isError) {
            return '1px solid #F44336'
        }

        if (state.isFocused) {
            return '1px solid #0C2146'
        }

        return '1px solid #BAC3D2'
    }

	const customStyles = {
		container: (provided) => ({
            ...provided,
            width: width,
            height: height
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            width: width,
            height: isMulti ? 'auto' : height,
            minHeight: height,
            borderRadius: '8px',
            display: 'flex',
            border: borderColor(state), ':hover': {
                borderColor: state.isDisabled || isReadOnly ? '1px solid #BAC3D2' : '#7E22D6'
            },
            outline: state.isFocused ? 'none' : 'none',
            boxShadow: state.isFocused ? 'none' : 'none',
            backgroundColor: isReadOnly ? '#F7F7FA' : 'white',
						...customFilterStyles
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            width: 0
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '32px',
            color: state.isDisabled || isReadOnly ? '#BAC3D2' : '#071B3F'
        }),
        placeholder: (provided) => ({
            ...provided,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '32px',
            'white-space': 'nowrap',
            color: '#818A9C'
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid #BAC3D2',
            backgroundColor: 'white',
            borderRadius: '8px',
            marginTop: '4px'
        }),
        menuList: (provided) => ({
            ...provided,
            boxShadow: '0px 4px 8px rgba(27, 78, 163, 0.04), 0px 8px 16px rgba(41, 121, 255, 0.04)'
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            color: 'black',
            backgroundColor: state.isSelected ? '#DBDBE1' : 'white'
        }),
        ValueContainer: (provided) => ({
            ...provided,
            padding: '2px 15px'
        })
	}

	const customFilter = (option, inputValue) => {
		const { data } = option
		const isDispayed = !isOptionExcluded(excludedOptions, data)

		return isDispayed && isOptionContains(data, inputValue)
	}

	const formatOptionLabel = ({ label, additional }) => (
		<div className="optionContainer">
			<div className="optionLabel">{label}</div>
			{additional && <div>{additional}</div>}
		</div>
	)

	const SingleValue = ({...props}) => {
        return (
            <div className={filterStyle ? 'filter-container' : ''}>
                { filterStyle && props.hasValue ?
                    <div className='filter-placeholder'>{placeholder}</div>
                    : null
                }
                <div className={filterStyle ? 'filter-value' : ''} {...props}>
                    {props.data.label}
                </div>
            </div>
        );
    };

	const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <div className={isDisabled || isReadOnly ? 'arrowContainerDisabled' : 'arrowContainer'} />
            </components.DropdownIndicator>
        );
    };

	return (
		<div className="async-dropdown-container" data-testid={dataTestId}>
            {dropdownLabel && <div className='dropdownLabel'> {dropdownLabel}</div>}
            {
                infiniteScroll ? (
                        <AsyncPaginate
                            styles={customStyles}
                            components={{ DropdownIndicator, SingleValue }}
                            formatOptionLabel={formatOptionLabel}
                            loadOptions={loadOptions}
                            placeholder={placeholder}
                            isSearchable={isSearchable}
                            onChange={handleDropdownChange}
                            value={value}
                            defaultValue={defaultValue}
                            filterOption={customFilter}
                            isMulti={isMulti}
                            isDisabled={isDisabled || isReadOnly}
                            classNamePrefix={'async-dropdown'}
                            defaultOptions={defaultOptions}
                            additional={{
                                page: 1,
                            }}
                            debounceTimeout={1000}
                        />
                    ) : (
                        <AsyncSelect
                            styles={customStyles}
                            components={{ DropdownIndicator, SingleValue }}
                            formatOptionLabel={formatOptionLabel}
                            loadOptions={loadOptions}
                            placeholder={placeholder}
                            isSearchable={isSearchable}
                            value={value}
                            defaultValue={defaultValue}
                            filterOption={customFilter}
                            isMulti={isMulti}
                            isDisabled={isDisabled || isReadOnly}
                            classNamePrefix={'async-dropdown'}
                            defaultOptions={defaultOptions}
                            onChange={handleDropdownChange}
                        />
                    )
            }
		</div>
	)
}

AsyncDropdown.propTypes = {
    loadOptions: PropTypes.func.isRequired,
    isSearchable: PropTypes.bool,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    defaultValue: PropTypes.object,
    isReadOnly: PropTypes.bool,
    isMulti: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    dropdownLabel: PropTypes.string,
    excludedOptions: PropTypes.array,
    handleDropdownChange: PropTypes.func,
    filterStyle: PropTypes.bool,
    dataTestId: PropTypes.string
};

AsyncDropdown.defaultProps = {
	defaultValue: null,
}