import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { prepareFieldsData } from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_AGE_CALCULATION_STEP } from 'common/endpoints'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import {useSelector} from "react-redux";
import {benefitBuilder} from "store/selectors/benefitBuilder";

export const AgeCalculation = ({ data, onChange }) => {
	const [state, setState] = useState()
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)
	const { register, control } = useForm()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_AGE_CALCULATION_STEP(benefitBuilderContextAPI))
				const [{ content, label }] = response.data.steps
				const columns = prepareFieldsData(content)

				setState({ columns, label })

				onChange(label, {
					label,
					columns,
					isLoaded: true,
				})
			} catch {}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			errors={benefitBuilderErrors}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
		/>
	)
}

AgeCalculation.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
