import axios from 'axios'
import { TimeoutModal } from 'components/modals/TimeoutModal'
import React from 'react'
import { ButtonLabels, FetchMethods } from 'utils/constants'
import { isStringIncludes } from 'utils/helpers/dropdown.helper'
import {AUTHENTICATION_TIMEOUT_MODAL_MESSAGE, ERROR_MESSAGE_403, MODAL_WARNING} from 'utils/messages'
import { toast } from 'react-toastify'
import { BEAM_LOGIN, SESSION_EXPIRY, SESSION_EXTEND } from './endpoints'
import fetchApi from './fetchApi'

let store

export const injectStore = (_store) => {
	store = _store
}

axios.interceptors.request.use((config) => {
	if (store.getState().auth.access_token) {
		// eslint-disable-next-line no-param-reassign
		config.headers.authorization = `Bearer ${
			store.getState().auth.access_token
		}`
	}

	return config
})

axios.interceptors.response.use(async (response) => {
	const { responseURL } = response.request

	if (
		isStringIncludes(responseURL, BEAM_LOGIN) ||
		isStringIncludes(responseURL, SESSION_EXPIRY) ||
		isStringIncludes(responseURL, SESSION_EXTEND)
	) {
		try {
			const { auth } = store.getState()
			const { expires_in } = response.data
			const timeToCheckCurrentSession = 90
			const checkUrlAfter = expires_in - timeToCheckCurrentSession

			if (checkUrlAfter > 0) {
				const { sessionTimeoutTimer } = auth

				if (sessionTimeoutTimer) {
					clearTimeout(sessionTimeoutTimer)
				}

				const sessionTimeoutTimerId = setTimeout(async () => {
					await fetchApi(SESSION_EXPIRY)
				}, checkUrlAfter * 1000)

				store.dispatch({
					type: 'auth/setSessionTimeoutTimer',
					payload: {
						sessionTimeoutTimer: sessionTimeoutTimerId,
					},
				})
			} else {
				const { sessionLogoutTimer } = auth
				if (sessionLogoutTimer) {
					clearTimeout(sessionLogoutTimer)
				}

				const defaultModalTime = 60
				const currentTime = expires_in - defaultModalTime
				const showModalTime = currentTime >= 0 ? currentTime : 0
				const startTimerValue =
					expires_in >= defaultModalTime ? defaultModalTime : expires_in

				const handleLogout = () =>
					store.dispatch({
						type: 'auth/setLogOut',
					})

				const hideModal = () =>
					store.dispatch({
						type: 'modal/hideModal',
					})

				const ModalBody = <TimeoutModal
						startValue={startTimerValue}
						onTimerFinish={() => {
							hideModal()
							handleLogout()
						}}
					/>

				const handleExtend = async () => {
					await fetchApi(SESSION_EXTEND, {
						method: FetchMethods.Patch,
					})

					hideModal()
				}

				const sessionLogoutTimerId = setTimeout(async () => {
					hideModal()

					store.dispatch({
						type: 'modal/showModal',
						payload: {
							data: {
								title: AUTHENTICATION_TIMEOUT_MODAL_MESSAGE,
								body: ModalBody,
								primaryButtonLabel: ButtonLabels.Continue,
								onPrimary: handleExtend,
								secondaryButtonLabel: ButtonLabels.LogOut,
								onSecondary: handleLogout,
								onClose: handleExtend,
							},
						},
					})

					store.dispatch({
						type: 'auth/setSessionLogoutTimer',
						payload: {
							sessionLogoutTimer: sessionLogoutTimerId,
						},
					})

					clearTimeout(sessionLogoutTimerId)
				}, showModalTime * 1000)
			}
		} catch (error) {}
	}

	return response
})

axios.interceptors.response.use((response) => {
	return response
}, (error) => {
	const responseURL = error?.response?.request?.responseURL
	if (error?.response?.status === 401
		&& !isStringIncludes(responseURL, BEAM_LOGIN)
		&& !error?.response?.data?.accountLoggedInElsewhere
	) {
		store.dispatch.auth.setLogOut(error.response.status)
	} else {
		return Promise.reject(error)
	}
})

axios.interceptors.response.use((response) => {
	return response
}, (error) => {
	if (error?.response?.status === 401 && error?.response?.data?.accountLoggedInElsewhere) {
		const handleLogout = () => store.dispatch.auth.setLogOut(error.response.status)

		store.dispatch.modal.showModal({
			data: {
					title: MODAL_WARNING,
					body: error?.response?.data?.error,
					primaryButtonLabel: ButtonLabels.Ok,
					secondaryButtonLabel: undefined,
					onPrimary: handleLogout,
					onClose: handleLogout
				}
		})
	} else {
		return Promise.reject(error)
	}
})

axios.interceptors.response.use((response) => {
	return response
}, (error) => {
	if (error?.response?.status === 403) {
		toast.error(ERROR_MESSAGE_403)
	} else {
		return Promise.reject(error)
	}
})
