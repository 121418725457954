import fetchApi from 'common/fetchApi'
import {
	getSteps,
	getLeftMenuItems,
	getUpdatedData,
	getSelectedStep,
	BenefitBuilderLabels,
	getUpdatedSteps,
	BenefitBuilderKeys,
	getPreparedData,
	getValidatedData,
	TableKeys,
	BenefitStepKeys
} from 'utils/helpers/benefitBuilder.helper'
import { getArrayWithoutDuplcatesByKey } from 'utils/helpers/array.helper'
import {
	GET_BENEFIT_MENU_ITEMS,
	CREATE_BENEFIT,
	GET_BENEFITS,
	PUBLISH_BENEFIT,
	DELETE_DUPLICATED_FIELD, DELETE_BUILDER_STEP
} from 'common/endpoints'
import {ContextTypes, FetchMethods} from 'utils/constants'

const initialState = {
	data: [],
	leftMenuData: [],
	steps: [],
	isSending: false,
	successMessage: undefined,
	errorMessage: undefined,
	isError: false,
	benefit: undefined,
	rowData: {},
	context: '',
	defaultSteps: [
		{
			label: BenefitBuilderLabels.BenefitInfo,
			isDefault: true,
			isValidated: false,
			isDisabled: false
		},
		{
			label: BenefitBuilderLabels.Scheme,
			isDefault: true,
			isValidated: false,
			isDisabled: true
		},
		{
			label: BenefitBuilderLabels.Provider,
			isDefault: true,
			isValidated: false,
			isDisabled: true
		},
		{
			label: BenefitBuilderLabels.BenefitDescription,
			isDefault: true,
			isValidated: false,
			isDisabled: true
		},
	],
	errors: {},
	events: []
}
// Temporary, after backend changes should be removed
const getPreparedStepErrors = (errors) => {
	return Object.keys(errors).reduce((newErrors, err) => {
		const fieldKeyArray = err.split('.')
		let errorKey = fieldKeyArray.length > 2 ?
			`${fieldKeyArray[2]}[${fieldKeyArray[1]}]`
			: fieldKeyArray[1] || fieldKeyArray[0]

		if (err === BenefitBuilderKeys[[BenefitBuilderLabels.LookupTable]]) {
			errorKey = 'pricing_lookup_upload'
		}

		return {
			...newErrors,
			[errorKey]: errors[err]
		}
	}, {})
}

export default {
	state: initialState,
	reducers: {
		data(state, { data }) {
			return {
				...state,
				data,
			}
		},

		leftMenuData(state, { leftMenuData }) {
			return {
				...state,
				leftMenuData: [...leftMenuData],
			}
		},

		steps(state, { steps }) {
			return {
				...state,
				steps,
			}
		},

		defaultSteps(state, { defaultSteps }) {
			return {
				...state,
				defaultSteps
			}
		},

		benefit(state, { benefit }) {
			return {
				...state,
				benefit
			}
		},

		events(state, { events }) {
			return {
				...state,
				events
			}
		},

		errors(state, { errors }) {
			return {
				...state,
				errors: {
					...state.errors,
					...errors,
				},
			}
		},

		successMessage(state, { successMessage }) {
			return {
				...state,
				successMessage,
			}
		},

		errorMessage(state, { errorMessage }) {
			return {
				...state,
				errorMessage,
			}
		},

		activeStep(state, { activeStep }) {
			return {
				...state,
				activeStep
			}
		},

		context(state, { context }) {
			return {
				...state,
				context
			}
		},

		isLoading(state, { isLoading }) {
			return {
				...state,
				isLoading,
			}
		},

		isSending(state, { isSending }) {
			return {
				...state,
				isSending
			}
		},

		isError(state, { isError }) {
			return {
				...state,
				isError
			}
		},

		rowData(state, { rowData }) {
			return {
				...state,
				rowData,
			}
		},

		badges(state, { badges }) {
			return {
				...state,
				badges,
			}
		},

		clearStore() {
			return initialState
		},

	},
	effects: () => ({
		async fetchData({ context }, { benefitBuilder }) {
			const isAddBenefitContext = ContextTypes.AddBenefit === context
			const areItemsDisabledByDefault = isAddBenefitContext
			const benefitBuilderDefaultSteps = benefitBuilder.defaultSteps
			const params = !isAddBenefitContext
				? {
						benefitID: benefitBuilder.rowData?.id,
				  }
				: {}

			try {
				this.setIsLoading({ isLoading: true })

				const response = await fetchApi(GET_BENEFIT_MENU_ITEMS, {
					params,
				})
				const { data } = response.data
				const updatedData = getValidatedData(data, areItemsDisabledByDefault)

				const updatedDefaultSteps = benefitBuilderDefaultSteps.map((defaultStep) => {
					return {
						...defaultStep,
						isDisabled: areItemsDisabledByDefault && defaultStep.label !== BenefitBuilderLabels.BenefitInfo,
					}
				})

				if (!isAddBenefitContext) {
					this.setBenefit({
						benefit: { id: benefitBuilder.rowData?.id }
					})
				}

				this.setDefaultSteps({ defaultSteps: updatedDefaultSteps })
				this.setContext({ context })
				this.setData({ data: updatedData })
				this.setLeftMenuData({ data: updatedData })
				this.setSteps({ steps: getSteps(updatedData), updatedDefaultSteps })
				this.setIsLoading({ isLoading: false })
			} catch(error) {
				this.setIsLoading({ isLoading: false })
			}
		},

		async validateStep({ step }, { benefitBuilder, stepper }) {
			const stepLabel = step.label
			const preparedStepperData = getPreparedData(stepper.data)
			const benefitBuilderData = benefitBuilder.data
			const benefitBuilderBenefit = benefitBuilder.benefit
			const benefitBuilderDefaultSteps = benefitBuilder.defaultSteps
			const stepKey = BenefitBuilderKeys[stepLabel]
			const benefitId = Boolean(benefitBuilderBenefit) ? benefitBuilderBenefit.id : ''
			const mode = benefitBuilder.context === 'Edit' ? GET_BENEFITS : CREATE_BENEFIT
			const endpoint = mode + `${Boolean(benefitId) ? '/' + benefitId : '' }`

			const data = stepKey === BenefitBuilderKeys[BenefitBuilderLabels.Options] ?
				{ [stepKey]: {
						restrict_optional_extra: preparedStepperData[stepKey].restrict_optional_extra
					}
				}
				: Object.values(TableKeys).includes(stepKey) ?
					{ [stepKey]: {} }:
					{ [stepKey]: preparedStepperData[stepKey] }

			try {
				this.setIsSending({ isSending: true })

				const response =  await fetchApi(endpoint, {
					method: benefitBuilder.context === 'Edit' ? 'PATCH' : 'POST',
					data,
				})

				if (!Boolean(benefitBuilder.benefit) && stepLabel === BenefitBuilderLabels.BenefitInfo) {
					const updatedData = getValidatedData(benefitBuilderData, false)

					const updatedDefaultSteps = benefitBuilderDefaultSteps.map((defaultStep) => {
						return {
							...defaultStep,
							isDisabled: false,
							isValidated: defaultStep?.label === stepLabel ? true : defaultStep?.isValidated
						}
					})

					this.setDefaultSteps({ defaultSteps: updatedDefaultSteps })
					this.setData({ data: updatedData })
					this.setLeftMenuData({ data: updatedData })
					this.setSteps({ steps: getSteps(updatedData), updatedDefaultSteps })
				}

				if (stepLabel !== BenefitBuilderLabels.BenefitInfo) {
					const {steps: stepsForValidation } = benefitBuilder
					const {defaultSteps: defaultStepsForValidation} = benefitBuilder
					const updatedDefaultStepsForValidation = defaultStepsForValidation.map((defaultStep) => {
						return {
							...defaultStep,
							isValidated: defaultStep?.label === stepLabel ? true : defaultStep?.isValidated
						}
					})
					const updatedStepsForValidation = stepsForValidation?.map(item => {
						return item?.label === stepLabel ? {...item, isValidated: true} : item
					})
					this.setDefaultSteps({ defaultSteps: updatedDefaultStepsForValidation })
					this.setSteps({ steps: updatedStepsForValidation, updatedDefaultSteps: updatedDefaultStepsForValidation })
				}

				const { benefit, message, events } = response.data

				if (benefit) {
					this.setBenefit({ benefit })
				}
				if (events) {
					this.setEvents({ events })
				}

				if (stepLabel === BenefitBuilderLabels.BenefitInfo) {
					this.fetchMenu({ context: benefitBuilder.context })
				}

				this.setErrors({
					errors: {
						[stepKey]: {},
					},
				})
				this.isError({ isError: false })
				this.setSuccessMessage({ successMessage: message })
				this.setIsSending({ isSending: false })
			} catch(error) {
				const stepErrors = error.response.data?.errors
				const errorMessage = error.response.data?.message

				this.setErrors({
					errors: {
						[stepKey]: stepErrors ? getPreparedStepErrors(stepErrors[0]) : {},
					},
				})
				this.setIsError({ isError: true })
				this.setErrorMessage({ errorMessage })
				this.setIsSending({ isSending: false })
			}
		},

		async setActiveStep({ activeStep }) {
			this.activeStep({ activeStep })
		},

		setSteps({ steps, updatedDefaultSteps }, { benefitBuilder }) {
			const defaultSteps = updatedDefaultSteps
				? updatedDefaultSteps
				: benefitBuilder.defaultSteps

			const newSteps = getArrayWithoutDuplcatesByKey(
				[...defaultSteps, ...steps],
				'label'
			)

			this.steps({ steps: getUpdatedSteps(newSteps) })
		},

		menuItemClick({ item }, { benefitBuilder }) {
			const updatedData = getUpdatedData(benefitBuilder.data, item, false)
			const selectedStep = getSelectedStep(updatedData, item)

			this.setData({ data: updatedData })
			this.setLeftMenuData({ data: updatedData })
			this.setSteps({ steps: [...benefitBuilder.steps, selectedStep] })
		},

		async removeStep({ item }, { benefitBuilder }) {
			const benefitID = benefitBuilder.benefit?.id || benefitBuilder.rowData?.id
			const stepKey = BenefitStepKeys[item.label]

			try {
				this.setIsLoading({ isLoading: true })
				const response = await fetchApi(DELETE_BUILDER_STEP(benefitID, stepKey), {
					method: FetchMethods.Delete
				})
				this.isError({ isError: false })
				this.setSuccessMessage({ successMessage: response?.data?.message })

				const updatedData = getUpdatedData(benefitBuilder.data, item, true)
				const newSteps = benefitBuilder.steps.filter(({ label }) => label !== item.label)

				this.setErrors({
					errors: {
						[stepKey]: {},
					},
				})

				this.setData({ data: updatedData })
				this.setLeftMenuData({ data: updatedData })
				this.setSteps({ steps: newSteps })
			} catch(error) {
				this.isError({ isError: true })
				const errorMessage = error.response.data?.message
				this.setErrorMessage({ errorMessage })
			}
			finally {
				this.setIsLoading({ isLoading: false })
			}
		},

		async fetchMenu({ context }, { benefitBuilder }) {
			const isAddBenefitContext = ContextTypes.AddBenefit === context
			const areItemsDisabledByDefault = isAddBenefitContext && !benefitBuilder?.benefit?.id
			const params = { benefitID: isAddBenefitContext ?
					benefitBuilder.benefit?.id : benefitBuilder.rowData?.id }

			try {
				const response = await fetchApi(GET_BENEFIT_MENU_ITEMS, {
					params,
				})
				const { data } = response.data
				const updatedData = getValidatedData(data, areItemsDisabledByDefault)

				this.setLeftMenuData({ data: updatedData })
				this.setData({ data: updatedData })
				this.setEvents({ events: []})
			} catch(error) {
				console.log(error)
			}
		},

		async publishBenefit({ context }, { benefitBuilder }) {
			const isAddBenefitContext = ContextTypes.AddBenefit === context
			const benefitID = isAddBenefitContext ?
				benefitBuilder.benefit?.id : benefitBuilder.rowData?.id

			try {
				this.setIsLoading({ isLoading: true })

				const response = await fetchApi(PUBLISH_BENEFIT(benefitID), {
					method: FetchMethods.Patch,
					data: {
						status: "published"
					}
				})
				this.isError({ isError: false })
				this.setSuccessMessage({ successMessage: response?.data?.message })
				this.setIsLoading({ isLoading: false })
			} catch(error) {
				this.isError({ isError: true })
				const errorMessage = error.response.data?.errors?.required_steps?.[0]
				this.setErrorMessage({ errorMessage })
				this.setIsLoading({ isLoading: false })
			}
		},

		async deleteDuplicatedField({ stepLabel, removedIndex }, { benefitBuilder }) {
			const benefitID = benefitBuilder.benefit?.id || benefitBuilder.rowData?.id
			const stepKey = BenefitBuilderKeys[stepLabel]

			try {
				const response = await fetchApi(DELETE_DUPLICATED_FIELD(benefitID, stepKey), {
					method: FetchMethods.Delete,
					data: {
						[stepKey]: {
							row_id: removedIndex
						}
					}
				})
				this.isError({ isError: false })
				this.setSuccessMessage({ successMessage: response?.data?.message })
			} catch(error) {
				this.isError({ isError: true })
				const errorMessage = error.response.data?.message
				this.setErrorMessage({ errorMessage })
			}
		},

		setData({ data }) {
			this.data({ data })
		},

		setBenefit({ benefit }) {
			this.benefit({ benefit })
		},

		setEvents({ events }) {
			this.events({ events })
		},

		setContext({ context }) {
			this.context({ context })
		},

		setIsLoading({ isLoading }) {
			this.isLoading({ isLoading })
		},

		setIsSending({ isSending }) {
			this.isSending({ isSending })
		},

		setIsError({ isError }) {
			this.isError({ isError })
		},

		setDefaultSteps({ defaultSteps }) {
			this.defaultSteps({ defaultSteps })
		},

		setSuccessMessage({ successMessage }) {
			this.successMessage({ successMessage })
		},

		setErrorMessage({ errorMessage }) {
			this.errorMessage({ errorMessage })
		},

		setErrors(payload) {
			this.errors(payload)
		},

		setLeftMenuData({ data }) {
			this.leftMenuData({ leftMenuData: getLeftMenuItems(data) })
		},

		setClearMessages() {
			this.setSuccessMessage({ successMessage: undefined })
			this.setErrorMessage({ errorMessage: undefined })
			this.setIsError({ isError: false })
		},

		setRowData({ rowData }) {
			this.rowData({ rowData })
		},

		setBadges({ badges }) {
			this.badges({ badges })
		},

		setClearStore() {
			this.clearStore()
		},
	}),
}
