import React from 'react'
import './FormField.css'
import PropTypes from 'prop-types'

export const FormFieldLabelSizes = {
	Base: 'form-field-label-base',
	Large: 'form-field-label-large',
}

export const FormField = ({
	isRequired,
	label,
	suffix,
	version,
	description,
	fieldErrors,
	errorAsHint,
	fieldSize,
	labelSize,
	prefix,
	children,
	buttons,
	hideFieldErrors,
	dataTestId,
}) => {
	return (
		<div className={`form-field ${fieldSize} form-field-hidden-space`}>
			<div className="form-field-header-container">
				{label && (
					<div className={labelSize}>
						<span data-testid={`${dataTestId}-${label}`}>{label}</span>
						{isRequired && <sup>*</sup>}
					</div>
				)}
				{version && <div className="form-field-version">{`[${version}]`}</div>}
			</div>

			{description && (
				<div className="form-field-description">{description}</div>
			)}

			<div className="form-field-content-container">
				{prefix && <div className="form-input-prefix-container">{prefix}</div>}

				<div className='form-field-content-container-field'>
					<div className='form-field-content-container-field-wrapper'>
						{children}
					</div>
					{!hideFieldErrors &&
						<div className='form-field-content-container-errors-wrapper'>
							{!errorAsHint && fieldErrors?.map((error, index) => <div key={index}>{error}</div>)}
						</div>
					}
				</div>

				{suffix && <div className="form-input-suffix-container">{suffix}</div>}
			</div>
			{
				buttons && (
					<div className="form-field-button-container">
						{buttons?.map((button) => button)}
					</div>
				)
			}
		</div>
	)
}

FormField.propTypes = {
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	children: PropTypes.any,
	dataTestId: PropTypes.string,
	labelSize: PropTypes.string,
}

FormField.defaultProps = {
	labelSize: FormFieldLabelSizes.Base,
}
