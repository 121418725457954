import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';

export const DatePickerComponent = ({
    defaultValue = new Date(),
    className = 'common-input',
    dateFormat = 'dd/MM/yyyy',
    isDisabled = false,
    onChange,
    placeholderText,
    dataTestId
}) => {
    const [startDate, setStartDate] = useState();

    useEffect(() => {
        if (defaultValue) {
            setStartDate(defaultValue);
        }
    }, [defaultValue]);

    const handlePickerChange = (date) => {
        setStartDate(date);
        onChange && onChange(date);
    };

    return  (
        <div data-testid={dataTestId} className='w-full'>
            <DatePicker
                selected={startDate}
                className={className}
                placeholderText={placeholderText}
                onChange={handlePickerChange}
                disabled={isDisabled}
                dateFormat={dateFormat}
                calendarStartDay={1}
            />
        </div>
    );
}

DatePickerComponent.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    className: PropTypes.string,
    dateFormat: PropTypes.string,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholderText: PropTypes.string,
    dataTestId: PropTypes.string
};