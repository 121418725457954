import React from 'react';
import { TableViewRow } from 'components/global/Table2/TableRow/TableViewRow/TableViewRow';
import PropTypes from 'prop-types';
import {TableEditRow} from "components/global/Table2/TableRow/TableEditRow/TableEditRow";

export const TableRowContainer = ({
    isEdit,
    rowData,
    newRowFields,
    onChange,
    onSubmit,
    onCancel,
    row,
    columns,
    menuItems,
    isCollapsed,
    errors,
    dataTestId
}) => {
    const preparedColumns = columns.map(item => item.name);
    const preparedRows = preparedColumns.map(item => row[item]);

    if (isEdit) {
        return (
            <TableEditRow
                rowData={rowData}
                newRowFields={newRowFields}
                dataTestId={dataTestId}
                onChange={onChange}
                onSubmit={onSubmit}
                onCancel={onCancel}
                errors={errors}
            />
        )
    }

    return <TableViewRow
        rowData={preparedRows}
        rowId={row?.id}
        dataTestId={dataTestId}
        isCollapsed={isCollapsed}
        menuItems={menuItems}
    />;
}

TableRowContainer.propTypes = {
    row: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired
    })),
    isEdit: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        onClick: PropTypes.func
    })),
    rowData: PropTypes.arrayOf(PropTypes.object),
    newRowFields: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    errors: PropTypes.object,
    dataTestId: PropTypes.string
}