import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import './ContentText.css';

export const ContentText = ({ content, dataTestId }) => {
    return content ? (
        <div
            className='content-text'
            data-testid={`${dataTestId}-content-text`}
        >
            {parse(content.toString())}
        </div>
    ) : null;
};

ContentText.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    dataTestId: PropTypes.string
}