import { ButtonLabels, ModalWidthSizes } from 'utils/constants'

const initialState = {
    data: null
}

export default {
    state: initialState,
    reducers: {
        data(state, { data }) {
            return {
                ...state,
                data
            }
        },
        reset() {
			return initialState
		},
    },
    effects: () => ({
        showModal({ data }) {
            const initialValue = {
                title: undefined,
                body: undefined,
                width: ModalWidthSizes.Default,
                secondaryButtonLabel: ButtonLabels.Cancel,
                primaryButtonLabel: ButtonLabels.Save,
                optionalButtonLabel: undefined,
                disableDefaultBehavior: false,
                onClose: () => {},
                onPrimary: () => {},
				onSecondary: () => {},
                onOptional: () => {},
                withFooterButtons: true
            }

            this.data({ data: {
                ...initialValue,
                ...data
            } })
        },

        hideModal() {
            this.data({ data: null })
        }
    })
}