import React from 'react'
import Loadable from 'react-loadable'

const LoadableEditor = Loadable({
	loader: () => import('./TextEditor'),
	loading() {
		return <div>Loading...</div>
	},
})
export default ({ defaultValue, onChange, placeholder, fieldErrors, dataTestId }) => (
	<LoadableEditor
		defaultValue={defaultValue}
		onChange={onChange}
		placeholder={placeholder}
		fieldErrors={fieldErrors}
		dataTestId={dataTestId}
	/>
)
