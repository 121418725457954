import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/commonFields/Checkbox/Checkbox';
import { FormField } from 'components/formFields/FormField/FormField'

export const FormCheckbox = ({
	label,
	defaultValue,
	fieldName,
	isRequired,
	dataTestId,
	buttons,
	onChange,
	fieldErrors
}) => {
	const [checkbox, setCheckbox] = useState({
		id: fieldName,
		label,
		selected: defaultValue ? Boolean(defaultValue.selected) : false
	})

	const handleOnChange = (checkbox) => {
		onChange(checkbox)
	}

	return (
		<FormField
			isRequired={isRequired}
			dataTestId={dataTestId}
			buttons={buttons}
			fieldErrors={fieldErrors}
		>
			<Checkbox
				checkboxData={checkbox}
				isRequired={isRequired}
				onCheckboxChanged={handleOnChange}
			/>
		</FormField>
	)
}

FormCheckbox.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	isRequired: PropTypes.bool,
	fieldName: PropTypes.string.isRequired,
	dataTestId: PropTypes.string,
	buttons: PropTypes.array,
	onChange: PropTypes.func
}
