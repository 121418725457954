const defaultActionsSet = {
    view: 0,
    create: 0,
    update: 0,
    delete: 0
};

const defaultState = {
    actions: {
        benefits: defaultActionsSet,
        clients: defaultActionsSet,
        users: defaultActionsSet,
        roles: defaultActionsSet
    },
    errors: {},
    newUsers: [],
    options: [],
    checkboxes: [],
    loadOptionsCallback: undefined
};

export default {
    state: defaultState,
    reducers: {
        editRoleData(state, payload) {
            return {
                ...state,
                ...payload,
                oldName: payload.name
            }
        },

        options(state, { options }) {
            return {
                ...state,
                options
            }
        },

        checkboxes(state, { checkboxes }) {
            return {
                ...state,
                checkboxes
            }
        },
        editRoleUserType(state, payload) {
            return {
                ...state,
                user_type_id: payload
            }
        },
        clearStore() {
            return defaultState;
        },
        editedRoleData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        errors(state, payload) {
            return {
                ...state,
                errors: {
                    ...payload
                }
            }
        },
        rolesActions(state, payload) {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    roles: {
                        ...state.actions.roles,
                        ...payload
                    }
                }
            }
        },
        usersActions(state, payload) {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    users: {
                        ...state.actions.users,
                        ...payload
                    }
                }
            }
        },
        benefitsActions(state, payload) {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    benefits: {
                        ...state.actions.users,
                        ...payload
                    }
                }
            }
        }
    },
    effects: () => ({
        async setOptions({ options }) {
            this.options({ options })
        },

        async setCheckboxes({ checkboxes }) {
            this.checkboxes({ checkboxes })
        },

        setEditRoleData(payload) {
            this.editRoleData(payload)
        },
        setEditRoleUserType(payload) {
            this.editRoleUserType(payload)
        },
        setClearStore() {
            this.clearStore()
        },
        setEditedRoleData(payload) {
            this.editedRoleData(payload)
        },
        setErrors(payload) {
            this.errors(payload)
        },
        setRolesActions(payload) {
            this.rolesActions(payload)
        },
        setUsersActions(payload) {
            this.usersActions(payload)
        },
        setBenefitsActions(payload) {
            this.benefitsActions(payload)
        }
    })
}