import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	BenefitBuilderLabels,
	getButtonComponents,
	getTableId,
	getTableName,
	prepareFieldsData
} from 'utils/helpers/benefitBuilder.helper'
import {BENEFIT_LOOKUP_TABLE, CREATE_BENEFIT_ROW} from 'common/endpoints'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import {useDispatch, useSelector} from "react-redux";
import {benefitBuilder} from "store/selectors/benefitBuilder";
import {FormColumnSizes} from "utils/constants";
import { FILE_UPLOAD_WARNING } from 'utils/messages'

export const LookupTable = ({ data, onChange }) => {
	const benefitID = useSelector(benefitBuilder.benefit)?.id
	const [state, setState] = useState()
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)
	const dispatch = useDispatch()

	const { register, control } = useForm()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_LOOKUP_TABLE(benefitBuilderContextAPI))
				const [{ content, label, header_buttons, description }] = response.data.steps;

				const columns = prepareFieldsData(content)

				setState({ columns, header_buttons, label, description })

				onChange(label, {
					label,
					columns,
					header_buttons,
					description,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.LookupTable, {
					columns: [],
					header_buttons: [],
					isLoaded: true
				})
			}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleAddNewRow = async (endpoint) => {
		const tableName = getTableName(state?.columns)
		const tableId = getTableId({ columns: state?.columns, tableName })

		dispatch.tables.setAddRowData({
			tableName,
			endpoint,
			saveEndpoint: CREATE_BENEFIT_ROW(benefitID, tableId)
		});
	}

	const headerButtons = getButtonComponents(state?.header_buttons, (options) =>
		handleAddNewRow(options.endpoint)
	)

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			headerButtons={headerButtons}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
			columnSize={FormColumnSizes.Single}
			fileUploadMessage={FILE_UPLOAD_WARNING}
			errors={benefitBuilderErrors}
			description={state?.description}
			errorAsHint
		/>
	)
}

LookupTable.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
