import React from 'react';
import PropTypes from 'prop-types';
import { FieldTypes } from 'utils/constants';
import { TableInput } from 'components/global/Table2/TableEditItem/TableInput';
import { TableDropdown } from 'components/global/Table2/TableEditItem/TableDropdown';
import {getRowDataWithNewValue} from "utils/helpers/tables.helper";

export const TableEditItem = ({
    data,
    newValue,
    onChange,
    dataTestId,
    errors
}) => {
    const isDropdown = data?.type === FieldTypes.Dropdown;

    return isDropdown ?
        <TableDropdown
            data={getRowDataWithNewValue({ newValue, data })}
            onChange={onChange}
            errors={errors}
            dataTestId={dataTestId}
        />
        : <TableInput
            data={getRowDataWithNewValue({ newValue, data })}
            onChange={onChange}
            errors={errors}
            dataTestId={dataTestId}
        />
}

TableEditItem.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    errors: PropTypes.array
}
