import React from 'react';
import './TableFilter.css';
import PropTypes from 'prop-types';
import { AsyncDropdown } from 'components/commonFields/AsyncDropdown/AsyncDropdown';
import { buildDropdownOption } from 'utils/helpers/dropdown.helper';
import {FieldTypes} from 'utils/constants';
import {DatePickerComponent} from 'components/commonFields/DatePicker/DatePicker';
import {prepareDate} from 'utils/helpers/format.helper';

export const TableFilter = ({ filters, params, onFilter, dataTestId, customFilterStyles }) => {
    if (!filters || !filters.length) {
        return null;
    }

    const handleDropdownChange = (item, filterName) => {
       onFilter({
            ...item,
            filterName
        });
    }

    const handleDatePickerChange = (item, filterName) => {
        onFilter({
            date: prepareDate(item),
            filterName
        })
    }

    return (
        <div className='filters-container'>
            {
                filters.map(item => {
                    const defaultDropdownValue = item?.value ? { label: item?.value?.name, value: item?.value?.id } : ''

                    let defaultDateValue;
                    if (item.type === FieldTypes.Date) {
                        const date = moment(item?.value, 'DD/MM/YYYY');
                        defaultDateValue = new Date(moment(date).format('MM/DD/YYYY'))
                    }

                    return (
                        <div
                            key={item.name}
                            className={item.type === FieldTypes.Date ?
                                'filter-item-date' : 'filter-item'}
                        >
                            {
                                item.type === FieldTypes.Date ?
                                    <DatePickerComponent
                                        defaultValue={defaultDateValue || params?.[item.name]}
                                        placeholderText={item?.placeholder}
                                        onChange={(date) => handleDatePickerChange(date, item.name)}
                                        dataTestId={dataTestId}
                                    />
                                    : <AsyncDropdown
                                        loadOptions={() => {}}
                                        placeholder={item.placeholder}
                                        handleDropdownChange={(e) => handleDropdownChange(e, item.name)}
                                        defaultOptions={item.options.map(item => buildDropdownOption(item.name, item.id))}
                                        value={defaultDropdownValue || params?.[item.name]}
                                        filterStyle
                                        isSearchable={false}
                                        dataTestId={`${dataTestId}-filter-${item.placeholder}`}
                                        customFilterStyles={customFilterStyles}
                                    />
                            }
                        </div>
                    )
                })
            }
        </div>
    )
};

TableFilter.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object),
    params: PropTypes.object,
    onFilter: PropTypes.func,
    dataTestId: PropTypes.string
}