import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BenefitBuilderLabels, prepareFieldsData } from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_SCHEME } from 'common/endpoints'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { benefitBuilder } from 'store/selectors/benefitBuilder'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import { useSelector } from "react-redux";

export const Scheme = ({ data, onChange }) => {
	const [state, setState] = useState()
	const [loaded, setLoaded] = useState(false)
	const { register, control } = useForm()
	const benefitID = useSelector(benefitBuilder.benefit)?.id
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_SCHEME(benefitID))
				const [{ content, label }] = response.data.steps
				const columns = prepareFieldsData(content)

				setState({ columns, label })

				onChange(label, {
					label,
					columns,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.Scheme, {
                    columns: [],
                    isLoaded: true
                })
			}
			finally {
				setLoaded(true)
			}
		}

		if (!loaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (loaded && data) {
			setState(data)
		}
	}, [data, loaded])

	const handleStepChange = (label, data) => onChange(label, data)

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			errors={benefitBuilderErrors}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
		/>
	)
}

Scheme.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
