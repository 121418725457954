// login.js
export const LOGIN_FOOTER_COPYRIGHT =
	'© 2022 Cloud8, Inc. - All Rights Reserved.'
export const LOGIN_PRIVACY = 'Privacy & Cookies policy'
export const LOGIN_TERMS = 'Terms'

// LoginForm.js
export const LOGIN_HEADER = 'Log in'
export const LOGIN_LOGO_ALT = 'Beam at work by Cloud8'
export const LOGIN_EMAIL = 'Email'
export const LOGIN_EMAIL_PLACEHOLDER = 'name@address.com'
export const LOGIN_PASSWORD = 'Password'
export const LOGIN_BUTTON = 'Log in'
export const FORGOT_PASSWORD = 'Forgot password?'

// ActivationForm.js
export const ACTIVATION_HEADER = 'Account activation'
export const RESET_PASSWORD = 'Reset password'
export const ACTIVATION_TEXT = 'Please enter your date of birth:'
export const ACTIVATION_DATE_OF_BIRTH = 'Date of birth'
export const INVALID_BIRTH_DATE =
	'The date of birth entered does not match our records, please try again'
export const INVALID_PASSWORD =
	'The password entered does not meet the security requirements, please review the checkboxes below and choose another password.'

// SetupPasswordForm.js
export const SETUP_PASSWORD_HEADER = 'Setup password'
export const SETUP_PASSWORD_TEXT =
	'In order to protect your account, please meet all criteria below:'
export const NEW_PASSWORD = 'New password'
export const OLD_PASSWORD = 'Old password'


// SetNewPasswordForm.js
export const SET_NEW_PASSWORD_LABEL = 'New password';
export const SET_NEW_PASSWORD_DATE_OF_BIRTH = 'Date of birth';
export const SET_NEW_PASSWORD_EMAIL = 'Email';
export const SET_NEW_PASSWORD_EMAIL_PLACEHOLDER = 'name@address.com'
export const SET_NEW_PASSWORD_HEADER = 'Reset Password'

// ResetPasswordForm.js
export const RESET_PASSWORD_HEADER = 'Reset password'
export const RESET_PASSWORD_TEXT = 'Don’t worry, it happens to the best of us.'
export const RESET_PASSWORD_ERROR =
	'Please enter your email and fill the captcha'
export const RESET_PASSWORD_SUCCESS_TEXT =
	'An email with instructions on how to reset your password will shortly be sent to the email address supplied \n' +
	'if the email address is registered with us. \n' +
	'Please check your email account.'
export const RESET_PASSWORD_SUCCESS_CONTACT =
	'If you do not receive the email within the next 15 minutes, please '
export const RESET_PASSWORD_SUCCESS_CONTACT_LINK = 'contact us.'

// Footer.js
export const COPYRIGHT = '©Cloud8 - All rights reserved'
export const CONTACT_US = 'Contact Cloud8'
export const HELP_LINK_LABEL = 'Help'
export const HELP_LINK = 'https://teamcloud8.freshdesk.com/'
export const CLOUD8_EMAIL = 'support@teamcloud8.co.uk'

// PaginationButton.js
export const PAGINATION_BUTTON_ALT = (payload) =>
	`go to the ${payload.toLowerCase()} page`

// Logo.js
export const LOGO_ALT = 'BEAM at Work by Cloud8'

// Notifications.js
export const NOTIFICATIONS_ALT = 'Notifications'

// Navigation.js
export const NAVIGATION_TO_ROLES = 'Roles'
export const NAVIGATION_TO_USERS = 'Users'
export const NAVIGATION_TO_BENEFITS = 'Benefits'
export const NAVIGATION_TO_DASHBOARD = 'Dashboard'
export const NAVIGATION_USER_MANAGEMENT = 'User management'

// PageStatistics.js
export const PAGE_STATISTICS = (payload) =>
	`Showing ${payload.fromEntry || ''} to ${payload.toEntry || ''} of ${
		payload.totalEntries || ''
	} entries`

// Table.js
export const NO_RESULTS_ALT = 'No results found'

// TableHeader.js
export const SORTING_ALT = 'Sorting'

// 404.js
export const NOT_FOUND_ALT = 'Page is not found, please check the link'

//notAuthorized.js
export const LINK_HAS_EXPIRED = 'Link has expired'
export const CONTACT_MESSAGE = `Contact ${process.env.GATSBY_SUPPORT_EMAIL}`

// index.js
export const LOGIN_SUCCESS = 'Successfully logged in'

// roles.js
export const FIND_ROLE = 'Find a role'
export const ROLES_MENU_EDIT = 'Edit'
export const ROLES_MENU_DELETE = 'Delete'
export const DELETE_ROLE_MODAL_HEADER = 'Confirmation'
export const DELETE_ROLE_MODAL_BODY = 'Do you really want to delete the role?'
export const DELETE_ROLE_MODAL_APPLY = 'Yes'
export const DELETE_ROLE_MODAL_CANCEL = 'No'

// users.js
export const FIND_USER = 'Find user'
export const USERS_MENU_EDIT = 'Edit'
export const USERS_MENU_DELETE = 'Delete'
export const DELETE_USER_MODAL_HEADER = 'Confirmation'
export const DELETE_USER_MODAL_BODY = 'Do you really want to delete the user?'
export const DELETE_USER_MODAL_APPLY = 'Yes'
export const DELETE_USER_MODAL_CANCEL = 'No'

// AddRoleButton.js
export const ADD_ROLE = '+ Add role'

// FormButton.js
export const NEXT_STEP = 'Next'
export const PREVIOUS_STEP = 'Back'
export const BUTTON_ICON_ALT = 'Button icon'

// AddRoleInfo.js
export const NEW_ROLE_NAME = 'Name'
export const NEW_ROLE_USER_TYPE = 'User type'
export const NEW_ROLE_USER_TYPE_PLACEHOLDER = 'Choose user type'
export const NEW_ROLE_DESCRIPTION = 'Description'

// StepCircle.js
export const COMPLETED_STEP_ALT = 'Completed step'

// StepItem.js
export const EDIT_STEP = 'Edit step'
export const EDIT = 'Edit'

// StepLabel.js
export const STEP_LABEL = (payload) => `STEP ${payload}`

// addRole.js
export const NEW_ROLE_STEP_1 = 'Add info'
export const NEW_ROLE_STEP_2 = 'Choose features & data access'
export const NEW_ROLE_STEP_3 = 'Add users'
export const NEW_ROLE_BACK = 'Back to roles'
export const NEW_ROLE_HEADER = 'Add role'

// Users.js
export const USER_FIRSTNAME = 'First name'
export const USER_LASTNAME = 'Last name'
export const USER_USER_TYPE = 'User type'
export const USER_EMAIL = 'Email'

// AddFeature.js
export const BENEFIT_CONFIGURATION = 'Benefit configuration'
export const CLIENT_MANAGEMENT = 'Client management'
export const USER_MANAGEMENT = 'User management'
export const ADD_CHECKBOX = 'Create'
export const EDIT_CHECKBOX = 'Update'
export const READ_CHECKBOX = 'View'
export const DELETE_CHECKBOX = 'Delete'
export const USERS_GROUP = 'Users'
export const ROLES_GROUP = 'Roles'
export const BENEFITS_GROUP = 'Benefits'

// SeeAllButton.js
export const SEE_ALL_BUTTON = 'See all'

// CollapseButton.js
export const COLLAPSE_BUTTON = 'Collapse'

// AddUsers.js
export const ADD_USERS_DROPDOWN_LABEL = 'Users'
export const ADD_USERS_DROPDOWN_PLACEHOLDER = 'Choose users'
export const ADD_USERS_CHECKBOX_LIST_HEADER = 'Your selections:'
export const ADD_USERS_SUBMIT_BUTTON = 'Add role'

// EditRoleInfo.js
export const EDIT_ROLE_NAME = 'Name'
export const EDIT_ROLE_USER_TYPE = 'User type'
export const EDIT_ROLE_USER_TYPE_PLACEHOLDER = 'Choose user type'
export const EDIT_ROLE_DESCRIPTION = 'Description'
export const EDIT_ROLE_INFO = 'Info'

// EditFeatures.js
export const EDIT_FEATURES = 'Features & data access'

// AuthenticationSectionForm.js
export const EDIT_2FA = 'Edit two factor authentication'
export const LABEL_2FA = 'Two factor authentication'
export const EMPTY_REASON_MESSAGE = 'Reason is required'

// EditUserFeatures.js
export const EDIT_USER_FEATURES = 'Features & data access'

// EditUsers.js
export const EDIT_USERS = 'Users'

// editRole.js
export const EDIT_ROLE_CANCEL = 'Cancel'
export const EDIT_ROLE_SAVE = 'Save'

// FeaturesDataAccess.js
export const ROLE_USER_MANAGEMENT = 'Company management'

// AddUserButton.js
export const ADD_USER = '+ Add user'

// addUser.js
export const NEW_USER_STEP_1 = 'Info'
export const NEW_USER_STEP_2 = 'Choose features & data access'
export const NEW_USER_BACK = 'Back to users'
export const NEW_USER_HEADER = 'Add user'

// AddUserInfo.js
export const NEW_USER_FNAME = 'First name'
export const NEW_USER_SNAME = 'Last name'
export const NEW_USER_BIRTHDAY = 'Date of birth'
export const NEW_USER_EMAIL = 'Email'
export const NEW_USER_USER_TYPE = 'User type'
export const NEW_USER_USER_ROLE = 'User role'
export const NEW_USER_USER_TYPE_PLACEHOLDER = 'Choose user type'
export const NEW_USER_USER_ROLE_PLACEHOLDER = 'Choose user role'

// FeaturesDataAccess.js
export const RIGHT_PANEL_FEATURES_DATA_ACCESS = 'Features & data access'
export const RIGHT_PANEL_ABOUT_THIS_ROLE = 'About this role'
export const RIGHT_PANEL_ABOUT_THIS_USER = 'About this user'
export const RIGHT_PANEL_USERS = 'Users'
export const RIGHT_PANEL_EMPTY_LABEL = 'No users are assigned to this role'

// EditUserInfo.js
export const EDIT_USER_INFO = 'Info'
export const EDIT_USER_FNAME = 'First name'
export const EDIT_USER_SNAME = 'Last name'
export const EDIT_USER_BIRTHDAY = 'Date of birth'
export const EDIT_USER_EMAIL = 'Email'
export const EDIT_USER_USER_TYPE = 'User type'
export const EDIT_USER_USER_ROLE = 'User role'
export const EDIT_USER_STATUS = 'Status'
export const EDIT_USER_USER_TYPE_PLACEHOLDER = 'Choose user type'
export const EDIT_USER_USER_ROLE_PLACEHOLDER = 'Choose user role'
export const EDIT_USER_USER_STATUS_PLACEHOLDER = 'Choose status'

// editUser.js
export const EDIT_USER_CANCEL = 'Cancel'
export const EDIT_USER_SAVE = 'Save'

// view user
export const STATUS = 'Status'

// benefits.js
export const BENEFIT_ID = 'ID'
export const BENEFIT_INTERNAL_NAME = 'Internal name'
export const BENEFIT_COMPANY_NAME = 'Company name'
export const BENEFIT_PROVIDER = 'Provider'
export const BENEFIT_PRICING_MODEL = 'Pricing model'
export const BENEFIT_STATUS = 'Status'
export const FIND_BENEFIT = 'Find benefit ID or Internal name'
export const ADD_BENEFIT = '+ Add benefit'
export const EDIT_BENEFIT = 'Edit'
export const COPY_BENEFIT = 'Copy'
export const COPY_BENEFIT_MODAL_BODY = 'Use this feature to copy a benefit to another Company.  Please select the target \n' +
	'company and the scheme year you would like to add the benefit to.'
export const COPY_BENEFIT_MODAL_HEADER = (name) => `Copy "${name}" benefit`
export const COPY_BENEFIT_MODAL_APPLY = 'Copy'
export const COPY_BENEFIT_MODAL_CANCEL = 'Back'
export const COPY_BENEFIT_MODAL_COMPANY_FIELD = 'Company'
export const COPY_BENEFIT_MODAL_COMPANY_FIELD_PLACEHOLDER = 'Choose client'
export const COPY_BENEFIT_MODAL_SCHEME_FIELD = 'Scheme year'
export const COPY_BENEFIT_MODAL_SCHEME_FIELD_PLACEHOLDER = 'Choose scheme year'

// ContentHyperlink.js
export const COPY_HYPERLINK = 'Copy'

// ContentImage.js
export const CONTENT_IMAGE_ALT = 'Attached image'

// benefitBuilder.js
export const BENEFIT_BUILDER_BACK = 'Back to benefits'

// BenefitBuilderTable.js
export const ROW_MENU_EDIT = 'Edit'
export const ROW_MENU_DUPLICATE = 'Duplicate'
export const ROW_MENU_DELETE = 'Delete'
export const DELETE_ROW_MODAL_HEADER = 'Confirmation'
export const DELETE_ROW_MODAL_BODY = 'Do you really want to delete the row?'
export const DELETE_ROW_MODAL_APPLY = 'Yes'
export const DELETE_ROW_MODAL_CANCEL = 'No'

// MyProfileSection.js
export const AUTHENTICATION_TITLE = 'Enable two factor authentication'
export const AUTHENTICATION_MODAL_APPLY = 'Enter the code'
export const AUTHENTICATION_MODAL_CANCEL = 'Cancel'
export const AUTHENTICATION_MESSAGE =
	'To register your device for two-factor authentication, please scan the QR code below with Google Authenticator or a similar authentication app.'
export const SECRET_KEY = (key) => `Secret key: ${key}`
export const AUTHENTICATION_CODE_MESSAGE = (order) =>
	`To complete device registration, please enter the ${order} authentication code displayed in your app.`

export const AUTHENTICATION_CODE_MODAL_APPLY = 'Verify'
export const AUTHENTICATION_CODE_MODAL_CANCEL = 'Back'
export const AUTHENTICATION_DISABLE_TITLE = 'Disable two factor authentication'
export const AUTHENTICATION_DISABLE_MODAL_APPLY = 'Disable'
export const AUTHENTICATION_DISABLE_MODAL_CANCEL = 'Cancel'
export const AUTHENTICATION_DISABLE_MESSAGE = 'Please enter a reason two factor authentication has been disabled.'
export const AUTHENTICATION_DISABLE_REASON = 'Reason *'
export const AUTHENTICATION_CODE_MODAL_NEXT = 'Next'
export const AUTHENTICATION_SECTION_LABEL = (isEnabled) => `Two factor authentication is ${isEnabled ? 'enabled' : 'disabled'}`
export const AUTHENTICATION_TIMEOUT_MODAL_MESSAGE = 'Your session is about to expire'
export const AUTHENTICATION_TIMEOUT_MODAL_INFO_CONTINUE = 'Please click “Continue” to keep working'
export const AUTHENTICATION_TIMEOUT_MODAL_INFO_LOGOUT = 'or click “Log out” to end your session'
export const MODAL_WARNING = 'Warning'

// Menu.js
export const NO_ITEMS = 'No components are left'

export const DASHBOARD_ITEM_DESCRIPTION =
	'Please continue to use the old admin interface to Manage Clients, Manage and upload benefit selections, Manage employees, Upload employees, View reports'
export const DASHBOARD_ITEM_LABEL = 'Old admin interface'

// UserInfo.js
export const MY_PROFILE = 'My Profile'
export const LOGOUT = 'Log Out'

// FormFileUpload.js
export const BROWSE_FILE = 'Browse'
export const FILE_TO_ATTACH = ' file to attach'
export const FILES_SUPPORTED = 'File types supported:'

// Value limits
export const NO_MORE_OPTIONS = 'No more value limits to add'

// Lookup table
export const FILE_UPLOAD_WARNING = 'Any existing data in the lookup table will be deleted do you want to continue?'
export const FILE_UPLOAD_WARNING_HEADER = 'Warning message'
export const FILE_UPLOAD_ERROR_MESSAGE = 'The errors have been identified. The data upload cannot proceed. Please amend your file and upload again'
export const FILE_UPLOAD_SUCCESS_MESSAGE = 'The file has been validated successfully';

// viewBenefit.js
export const BACK_TO_BENEFITS = 'Back to benefits'

// auditLogs.js
export const AUDIT_LOG_PAGE_HEADER = 'Audit log'
export const GO_BACK_TO_BENEFIT_VIEW = 'Back to view benefit list'
export const GO_BACK_TO_ROLE_VIEW = 'Back to view role list'
export const GO_BACK_TO_USER_VIEW = 'Back to view user list'
export const AUDIT_LOG_TOOLTIP = 'Audit log'

//EditUserAuthentication.js
export const DISABLE_2FA_SUCCESS = '2FA for the user is disabled'

//interceptors
export const ERROR_MESSAGE_403 = 'Your role does not allow you access to this feature'

//TwoFAForm.js
export const ENTER_CODE_LABEL = 'Enter code'
export const ENTER_CODE_MESSAGE = 'Please enter the authentification code displayed in your app'
export const ENTER_CODE_ERROR_MESSAGE = 'The code doesn’t look right. Please try again.'