import React from 'react';
import { Tooltip as Tip } from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import PropTypes from 'prop-types';

export const Tooltip = ({ label, position, Component }) => (
        <Tip
            trigger="mouseenter"
            position={position}
            arrow
            title={label}
        >
            {Component}
        </Tip>
)

Tooltip.propTypes = {
    label: PropTypes.string,
    position: PropTypes.string,
    Component: PropTypes.node
}

Tooltip.defaltProps = {
    position: "top"
}