import { CREATE_BENEFIT, EDIT_BENEFIT } from 'common/endpoints'
import { ContextTypes } from 'utils/constants'

const ContextEndpoints = {
	[ContextTypes.AddBenefit]: () => CREATE_BENEFIT,
	[ContextTypes.EditBenefit]: (benefitId) => EDIT_BENEFIT(benefitId)
} 

export const benefitBuilder = {
    leftMenuData: ({ benefitBuilder }) => benefitBuilder.leftMenuData,
    data: ({ benefitBuilder }) => benefitBuilder.data,
    steps: ({ benefitBuilder }) => benefitBuilder.steps,
    events: ({ benefitBuilder }) => benefitBuilder.events,
    defaultSteps: ({ benefitBuilder }) => benefitBuilder.defaultSteps,
    successMessage: ({ benefitBuilder }) => benefitBuilder.successMessage,
    errorMessage: ({ benefitBuilder }) => benefitBuilder.errorMessage,
    isLoading: ({ benefitBuilder }) => benefitBuilder.isLoading,
    benefit: ({ benefitBuilder }) => benefitBuilder.benefit,
    activeStep: ({ benefitBuilder }) => benefitBuilder.activeStep,
    isSending: ({ benefitBuilder }) => benefitBuilder.isSending,
    isError: ({ benefitBuilder }) => benefitBuilder.isError,
    rowData: ({ benefitBuilder }) => benefitBuilder.rowData,
    badges: ({ benefitBuilder }) => benefitBuilder.badges,
    errors: ({ benefitBuilder }) => benefitBuilder.errors,
    context: ({ benefitBuilder }) => benefitBuilder.context,
    contextAPI: ({ benefitBuilder }) => {
        if (!benefitBuilder.context) {
            return
        }

        return ContextEndpoints[benefitBuilder.context](benefitBuilder.rowData?.id)
    }
}