const initialState = {
	data: {},
	steps: [],
	activeStep: undefined,
}

export default {
	state: initialState,
	reducers: {
		data(state, { data }) {
			return {
				...state,
				data: {
					...state.data,
					[data[0]]: {
						...state.data[data[0]],
						...data[1],
					},
				},
			}
		},

		steps(state, { steps }) {
			return {
				...state,
				steps: [...steps],
			}
		},

		activeStep(state, { activeStep }) {
			return {
				...state,
				activeStep,
			}
		},

        isSending(state, { isSending }) {
            return {
                ...state,
                isSending
            }
        },

		clearStepState(state, { stepId }) {
			const {
				[stepId]: {},
				...newData
			} = state.data

			return {
				...state,
				data: {
					...newData,
				},
			}
		},

		clearStore() {
			return initialState
		},
	},
	effects: () => ({
		setData({ data }) {
			this.data({ data })
		},

		setSteps({ steps }) {
			this.steps({ steps })
		},

		setActiveStep({ activeStep }) {
			this.activeStep({ activeStep })
		},

		setIsSending({ isSending }) {
			this.isSending({ isSending })
		},

		setClearStore() {
			this.clearStore()
		},
	}),
}
