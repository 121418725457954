import React from 'react';
import './PaginationButtons.css';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons/Button/Button';
import { ButtonLabels, ButtonTypes } from 'utils/constants';
import {
    BsChevronBarLeft,
    BsChevronBarRight,
    BsChevronCompactLeft,
    BsChevronCompactRight
} from 'react-icons/bs';

export const PaginationButtons = ({
   onPagination,
   currentPage,
   lastPage,
   dataTestId
}) => {

    const handleClick = (event) => {
        onPagination && onPagination({
            goToPage: event?.currentTarget?.id,
            currentPage,
            lastPage
        });
    };

    return (
        <div className='pagination-container'>
            <Button
                icon={BsChevronBarLeft}
                alt={ButtonLabels.FirstPage}
                type={ButtonTypes.Pagination}
                disabled={currentPage === 1}
                handleOnClick={handleClick}
                id={ButtonLabels.FirstPage}
                dataTestId={`${dataTestId}-pagination-first`}
            />
            <Button
                icon={BsChevronCompactLeft}
                alt={ButtonLabels.PreviousPage}
                type={ButtonTypes.Pagination}
                disabled={currentPage === 1}
                handleOnClick={handleClick}
                id={ButtonLabels.PreviousPage}
                dataTestId={`${dataTestId}-pagination-previous`}
            />
            <Button
                icon={BsChevronCompactRight}
                alt={ButtonLabels.NextPage}
                type={ButtonTypes.Pagination}
                disabled={currentPage === lastPage}
                handleOnClick={handleClick}
                id={ButtonLabels.NextPage}
                dataTestId={`${dataTestId}-pagination-next`}
            />
            <Button
                icon={BsChevronBarRight}
                alt={ButtonLabels.LastPage}
                type={ButtonTypes.Pagination}
                disabled={currentPage === lastPage}
                handleOnClick={handleClick}
                id={ButtonLabels.LastPage}
                dataTestId={`${dataTestId}-pagination-last`}
            />
        </div>
    );

};

PaginationButtons.propTypes = {
    currentPage: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    onPagination: PropTypes.func,
    dataTestId: PropTypes.string
}