import { sortingDirections } from 'utils/constants';
import fetchApi from 'common/fetchApi'
import { GET_BENEFITS } from 'common/endpoints';

const getBenefits = async (options) => {
    try {
        const response = await fetchApi(GET_BENEFITS, options)
        const { data } = response

        return data
    } catch (error) {}
}

const getBenefitViewPage = async (viewId, options) => {
    try {
        const response = await fetchApi(`${GET_BENEFITS}/${viewId}`, options)
        const { data } = response

        return data
    } catch (error) {}
}

const defaultState = {
    rowsPerPage: '25',
    sortingData: {
        direction: sortingDirections.DOWN,
        column: 'id'
    },
    searchValue: '',
    searchCompany: '',
    data: {
        current_page: 1
    },
    categories: [],
    viewId: undefined,
    headerLabel: undefined,
    company: undefined,
    scheme: undefined,
    companiesList: [],
    schemesList: []
};

export default {
    state: defaultState,
    reducers: {
        rowsPerPage(state, payload) {
            return {
                ...state,
                rowsPerPage: payload,
                data: {
                    ...state.data,
                    current_page: 1
                }
            }
        },
        sortingData(state, payload) {
            return {
                ...state,
                sortingData: {
                    direction: payload.direction,
                    column: payload.column
                }
            }
        },
        currentPage(state, payload) {
            return {
                ...state,
                data: {
                    ...state.data,
                    current_page: payload
                }
            }
        },
        searchPage(state, payload) {
            return {
                ...state,
                searchValue: payload
            }
        },
        searchCompany(state, payload) {
            return {
                ...state,
                searchCompany: payload
            }
        },
        tableData(state, payload) {
            return {
                ...state,
                data: payload
            }
        },
        categories(state, { categories }) {
            return {
                ...state,
                categories
            }
        },
        viewId(state, { viewId }) {
            return {
                ...state,
                viewId
            }
        },
        headerLabel(state, { headerLabel }) {
            return {
                ...state,
                headerLabel
            }
        },
        isLoading(state, { isLoading }) {
            return {
                ...state,
                isLoading,
            }
        },
        company(state, {company}) {
            return {
                ...state,
                company
            }
        },
        companiesList(state, {companiesList}) {
            return {
                ...state,
                companiesList
            }
        },
        schemesList(state, {schemesList}) {
            return {
                ...state,
                schemesList
            }
        },
        scheme(state, {scheme}) {
            return {
                ...state,
                scheme
            }
        },
        clearStore() {
            return defaultState;
        }
    },
    effects: () => ({
        async setViewPage({ viewId }) {
            this.setIsLoading({ isLoading: true })

            const data = await getBenefitViewPage(viewId)

            if (data) {
                this.setCategories({ categories: data.data })
            }
            this.setIsLoading({ isLoading: false })
        },
        setRowsPerPage(payload) {
            this.rowsPerPage(payload)
        },
        setViewData({ viewId, headerLabel }) {
            this.setViewId({ viewId })
            this.setHeaderLabel({ headerLabel })
        },
        setViewId({ viewId }) {
            this.viewId({ viewId })
        },
        setHeaderLabel({ headerLabel }) {
            this.headerLabel({ headerLabel })
        },
        setCategories({ categories }) {
            this.categories({ categories })
        },
        setSortingData(payload) {
            this.sortingData(payload)
        },
        setCurrentPage(payload) {
            this.currentPage(payload)
        },
        setSearchPage(payload) {
            this.searchPage(payload)
        },
        setSearchCompany(payload) {
            this.searchCompany(payload)
        },
        setTableData(payload) {
            this.tableData(payload);
        },
        setIsLoading({ isLoading }) {
            this.isLoading({ isLoading })
        },
        async setBenefitsData(params) {
            this.setIsLoading({ isLoading: true })
            const data = await getBenefits({
                params
            });
            if (data) {
                this.setTableData(data);
            }
            this.setIsLoading({ isLoading: false })
        },
        setClearStore() {
            this.clearStore()
        },
        setCompany({ company }) {
            this.company({ company })
        },
        setCompaniesList({ companiesList }) {
            this.companiesList({ companiesList })
        },
        setSchemesList({ schemesList }) {
            this.schemesList({ schemesList })
        },
        setScheme({ scheme }) {
            this.scheme({ scheme })
        },
    })
}