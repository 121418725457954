import React from 'react';
import PropTypes from 'prop-types';
import './ContentImage.css';
import { CONTENT_IMAGE_ALT } from '../../../utils/messages';

export const ContentImage = ({ content, onClick, dataTestId }) => {
    return (
        <div className='content-image-container'>
            <img
                alt={CONTENT_IMAGE_ALT}
                src={content.src}
                className={content.description ? 'content-image-with-description' : 'content-image'}
                onClick={onClick}
                data-testid={`${dataTestId}-content-image`}
            />
            {content.description &&
                <div
                    className='content-image-description'
                    data-testid={`${dataTestId}-content-image-description`}
                >
                    {content.description}
                </div>
            }
        </div>
    )
};

ContentImage.propTypes = {
    content: PropTypes.object,
    dataTestId: PropTypes.string,
    onClick: PropTypes.func
}