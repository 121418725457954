import './Checkbox.css'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getLocatorId } from 'utils/locator'

export const Checkbox = ({ checkboxData, onCheckboxChanged, isRequired }) => {
	const [checkbox, setCurrentCheckbox] = useState(checkboxData)

	useEffect(() => {
		onCheckboxChanged(checkbox)
	}, [checkbox])

	useEffect(() => {
		setCurrentCheckbox(checkboxData)
	}, [checkboxData])

	const onChange = () => {
		const newCheckbox = { ...checkbox }
		newCheckbox.selected = !newCheckbox.selected
		setCurrentCheckbox(newCheckbox)
	}

	return (
		<div
			className={`checkbox-base-container ${checkbox?.disabled ? 'disabled' : ''}`}
			data-testid={getLocatorId('checkbox', checkbox.label)}
		>
			<div className='checkbox-shadow-container'>
				<input
					className='checkbox-base-input'
					type="checkbox"
					checked={checkbox.selected}
					disabled={checkbox.disabled}
					name={checkbox.label}
					id={checkbox.id}
					onChange={onChange}
					data-testid={getLocatorId('checkbox-input', checkbox.label)}
				/>
			</div>
			<div
				className='checkbox-base-label'
				htmlFor={checkbox.id}
				data-testid={getLocatorId('checkbox-label', checkbox.label)}
			>
				{checkbox.label}
				{isRequired && <sup>*</sup>}
			</div>
			{checkbox.additional && (
				<div
					className="checkbox-additional"
					htmlFor={checkbox.id}
					data-testid={getLocatorId(
						'additional-label',
						checkbox.additional
					)}
				>
					{checkbox.additional}
				</div>
			)}
		</div>
	)
}

Checkbox.propTypes = {
	checkboxData: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		label: PropTypes.string.isRequired,
		additional: PropTypes.string,
		selected: PropTypes.bool,
		disabled: PropTypes.bool
	}),
	onCheckboxChanged: PropTypes.func.isRequired,
	isRequired: PropTypes.bool
}
