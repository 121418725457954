import fetchApi from 'common/fetchApi'
import { GET_ROLES, GET_USER } from 'common/endpoints'
import { toast } from 'react-toastify'

const getDataByPageType = async (type, rowData) => {
	const urls = new Map([
		['roles', `${GET_ROLES}/${rowData.id}`],
		['users', `${GET_USER}/${rowData.user_id}`],
	])

	try {
		const response = await fetchApi(urls.get(type))
		const { data } = response

		return data
	} catch (error) {
		toast.error('Connection error')
	}
}

const defaultState = {
	contextType: '',
	template: undefined,
	data: undefined,
	isOpen: false,
	isLoading: true,
	rowData: undefined,
}

export default {
	state: defaultState,
	reducers: {
		reset() {
			return defaultState
		},

		contextType(state, { contextType }) {
			return {
				...state,
				contextType,
			}
		},

		rowData(state, rowData) {
			return {
				...state,
				rowData,
			}
		},

		headerLabel(state, { headerLabel }) {
			return {
				...state,
				headerLabel,
			}
		},

		template(state, { template }) {
			return {
				...state,
				template,
			}
		},

		isOpen(state, { isOpen }) {
			return {
				...state,
				isOpen,
			}
		},

		isLoading(state, { isLoading }) {
			return {
				...state,
				isLoading,
			}
		},

		id(state, { id }) {
			return {
				...state,
				id,
			}
		},

		data(state, { data }) {
			return {
				...state,
				data,
			}
		},
	},

	effects: ({ rightPanel }) => ({
		async setRightPanel({ contextType, rowData, id }) {
			this.isLoading({ isLoading: true })
			this.isOpen({ isOpen: true })
			this.id({ id })
			this.setRowData({ rowData })
			this.contextType({ contextType })

			const data = await getDataByPageType(contextType, rowData)

			rightPanel.setData({ data })
			rightPanel.setIsLoading({ isLoading: false })
		},

		setData({ data }) {
			this.data({ data })
		},

		setIsLoading({ isLoading }) {
			this.isLoading({ isLoading })
		},

		setCloseRightPanel() {
			this.reset()
		},

		setRowData({ rowData }) {
			this.rowData(rowData)
		},
	}),
}
