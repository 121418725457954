import React from 'react';
import './TableEditRow.css';
import PropTypes from 'prop-types';
import { TableEditItem } from 'components/global/Table2/TableEditItem/TableEditItem';
import { Button } from 'components/buttons/Button/Button';
import { ButtonTypes, ButtonLabels } from 'utils/constants';
import { AiOutlineCheck } from 'react-icons/ai';

export const TableEditRow = ({
    rowData,
    newRowFields,
    onSubmit,
    onChange,
    onCancel,
    dataTestId,
    errors
}) => {
    return (
        <div
            className='table-edit-form'
            data-testid={`${dataTestId}-row-edit`}
        >
            <div className='table-edit-fields'>
                {
                    rowData?.map((item, index) => {
                        return (
                            <TableEditItem
                                key={index}
                                data={item}
                                newValue={newRowFields?.[item?.field_id]}
                                onChange={onChange}
                                dataTestId={dataTestId}
                                errors={errors?.[item?.field_id]}
                            />
                        )
                    })
                }
            </div>
            <div className='table-edit-buttons'>
                <Button
                    label={ButtonLabels.Cancel}
                    handleOnClick={onCancel}
                    type={ButtonTypes.Secondary}
                    dataTestId={dataTestId}
                />
                <Button
                    label={ButtonLabels.Save}
                    handleOnClick={onSubmit}
                    icon={AiOutlineCheck}
                    type={ButtonTypes.Primary}
                    dataTestId={dataTestId}
                />
            </div>
        </div>
    );
};

TableEditRow.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.object),
    newRowFields: PropTypes.object,
    isCollapsed: PropTypes.bool,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        onClick: PropTypes.func
    })),
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    errors: PropTypes.object
}
