exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-not-authorized-js": () => import("./../../../src/pages/notAuthorized.js" /* webpackChunkName: "component---src-pages-not-authorized-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-reset-password-success-js": () => import("./../../../src/pages/resetPasswordSuccess.js" /* webpackChunkName: "component---src-pages-reset-password-success-js" */),
  "component---src-pages-setup-password-js": () => import("./../../../src/pages/setupPassword.js" /* webpackChunkName: "component---src-pages-setup-password-js" */),
  "component---src-pages-two-fa-js": () => import("./../../../src/pages/twoFA.js" /* webpackChunkName: "component---src-pages-two-fa-js" */)
}

