import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
	BenefitBuilderLabels,
	getButtonComponents, getTableId,
	getTableName,
	prepareFieldsData
} from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_OPTIONS_STEP, CREATE_BENEFIT_ROW } from 'common/endpoints'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import { FormColumnSizes } from "utils/constants";
import {benefitBuilder} from "store/selectors/benefitBuilder";

export const Options = ({ data, onChange }) => {
	const benefitID = useSelector(benefitBuilder.benefit)?.id
	const dispatch = useDispatch();
	const [state, setState] = useState()
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)

	const { register, control } = useForm()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_OPTIONS_STEP(benefitBuilderContextAPI))
				const [{ content, label, header_buttons }] = response.data.steps;
				const columns = prepareFieldsData(content)

				setState({ columns, header_buttons, label })

				onChange(label, {
					label,
					columns,
					header_buttons,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.Options, {
					columns: [],
					header_buttons: [],
					isLoaded: true
				})
			}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleAddNewRow = async (endpoint) => {
		const tableName = getTableName(state?.columns)
		const tableId = getTableId({ columns: state?.columns, tableName })

		dispatch.benefitBuilder.setErrors({
			errors: {
				option: {}
			}
		});

		dispatch.tables.setAddRowData({
			tableName,
			endpoint,
			saveEndpoint: CREATE_BENEFIT_ROW(benefitID, tableId)
		});
	}

	const headerButtons = getButtonComponents(state?.header_buttons, (options) =>
		handleAddNewRow(options.endpoint)
	)

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			headerButtons={headerButtons}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
			errors={benefitBuilderErrors}
			columnSize={FormColumnSizes.Single}
		/>
	)
}

Options.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
