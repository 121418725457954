import React from 'react';
import './TableViewRow.css';
import PropTypes from 'prop-types';
import { TableViewItem } from 'components/global/Table2/TableViewItem/TableViewItem';
import { TableRowMenu } from 'components/global/Table2/TableRowMenu/TableRowMenu';

export const TableViewRow = ({
     rowData,
     isCollapsed,
     menuItems,
     rowId,
     dataTestId
}) => {
    return (
        <div
            className='table-view-row'
            data-testid={`${dataTestId}-row`}
        >
            {
                rowData.map((item, index) => {
                    return (
                        <TableViewItem
                            key={index}
                            value={item}
                            dataTestId={dataTestId}
                        />
                    )
                })
            }
            {
                menuItems && menuItems.length ?
                    <TableRowMenu
                        items={menuItems}
                        isCollapsed={isCollapsed}
                        rowData={rowData}
                        rowId={rowId}
                        dataTestId={dataTestId}
                    />
                    : null
            }
        </div>
    );
};

TableViewRow.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.string),
    isCollapsed: PropTypes.bool,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        onClick: PropTypes.func
    })),
    rowId: PropTypes.number,
    dataTestId: PropTypes.string
}
