import React from 'react'
import PropTypes from 'prop-types'
import './BenefitRow.css'
import { BenefitRowContent } from 'components/benefit/BenefitRowContent/BenefitRowContent'

export const BenefitRow = ({
	label,
	content,
	fieldId,
	onChange,
	isHighlighted,
	dataTestId,
}) => {
	return (
		<div className={isHighlighted ? 'benefit-row-highlighted' : 'benefit-row'}>
			<div className="benefit-row-label" data-testid={`${label}-label`}>
				{label}
			</div>
			<div className="benefit-row-item">
				<BenefitRowContent
					content={content}
					fieldId={fieldId}
					onChange={onChange}
					dataTestId={`${dataTestId}-${label}`}
				/>
			</div>
		</div>
	)
}

BenefitRow.propTypes = {
	label: PropTypes.string.isRequired,
	content: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			elements: PropTypes.arrayOf(
				PropTypes.shape({
					text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
					link: PropTypes.string,
					img: PropTypes.arrayOf(PropTypes.object),
				})
			),
		})
	).isRequired,
	isHighlighted: PropTypes.bool.isRequired,
}
