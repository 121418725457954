const defaultActionsSet = {
    view: 0,
    create: 0,
    update: 0,
    delete: 0
};

const defaultState = {
    steps: {
        0: {
            isExpanded: true
        },
        1: {
            isExpanded: false
        },
        2: {
            isExpanded: false
        },
        3: {
            isExpanded: false
        }
    },
    actions: {
        benefits: defaultActionsSet,
        clients: defaultActionsSet,
        users: defaultActionsSet,
        roles: defaultActionsSet
    },
    errors: {},
    options: [],
    checkboxes: []
};

export default {
    state: defaultState,
    reducers: {
        stepInfo(state, payload) {
            return {
                ...state,
                steps: {
                    ...state.steps,
                    [payload.step-1]: {
                        isExpanded: payload.isExpanded,
                        isCompleted: payload.isCompleted
                    }
                }
            }
        },
        stepData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },

        options(state, { options }) {
            return {
                ...state,
                options
            }
        },

        checkboxes(state, { checkboxes }) {
            return {
                ...state,
                checkboxes
            }
        },

        newRoleUserType(state, payload) {
            return {
                ...state,
                user_type_id: payload
            }
        },
        collapseAll(state, payload) {
            const collapsedSteps = Object.values(state.steps).map((item, index) => {
                if (index !== payload.step-1) return {...item, isExpanded: false};
                return {...item, isExpanded: true};
            });

            return {
                ...state,
                steps: {
                    ...collapsedSteps
                }
            }
        },
        rolesActions(state, payload) {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    roles: {
                        ...state.actions.roles,
                        ...payload
                    }
                }
            }
        },
 
        usersActions(state, payload) {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    users: {
                        ...state.actions.users,
                        ...payload
                    }
                }
            }
        },

        benefitsActions(state, payload) {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    benefits: {
                        ...state.actions.users,
                        ...payload
                    }
                }
            }
        },

        clearStore() {
            return defaultState;
        },
        errors(state, payload) {
            return {
                ...state,
                errors: {
                    ...payload
                }
            }
        },
    },
    effects: () => ({
        setStepInfo(payload) {
            this.stepInfo(payload)
        },

        async setOptions({ options }) {
            this.options({ options })
        },

        async setCheckboxes({ checkboxes }) {
            this.checkboxes({ checkboxes })
        },

        setStepData(payload) {
            this.stepData(payload)
        },
        setNewRoleUserType(payload) {
            this.newRoleUserType(payload)
        },
        setCollapseAll(payload) {
            this.collapseAll(payload)
        },
        setRolesActions(payload) {
            this.rolesActions(payload)
        },
        setUsersActions(payload) {
            this.usersActions(payload)
        },
        setBenefitsActions(payload) {
            this.benefitsActions(payload)
        },
        setErrors(payload) {
            this.errors(payload)
        },
        setClearStore() {
            this.clearStore()
        }
    })
}