import fetchApi from "common/fetchApi";
import {LOGOUT_OLD_ADMIN, SESSION_EXPIRY} from "common/endpoints";

export default {
    state: {
        isLoggedIn: false,
        errors: {},
        birthDate: '',
        isSending: false,
        location: undefined,
        sessionTimerId: undefined
    },
    reducers: {
        auth(state, payload) {
            return payload;
        },
        logIn(state, payload) {
            return {
                ...state,
                ...payload,
                isLoggedIn: true
            }
        },
        logOut(state) {
            return {
                ...state,
                isLoggedIn: false
            }
        },
        userInfo(state, payload) {
            return {
                ...state,
                ...payload,
                userInfo: payload
            }
        },
        birthDate(state, { birthDate }) {
            return {
                ...state,
                birthDate
            }
        },
        sessionTimeoutTimer(state, { sessionTimeoutTimer }) {
            return {
                ...state,
                sessionTimeoutTimer
            }
        },
        sessionLogoutTimer(state, { sessionLogoutTimer }) {
            return {
                ...state,
                sessionLogoutTimer
            }
        },
        isSending(state, { isSending }) {
            return {
                ...state,
                isSending
            }
        },
        location(state, { location }) {
            return {
                ...state,
                location
            }
        },
        errors(state, { errors }) {
            return {
                ...state,
                errors
            }
        }
    },
    effects: (dispatch) => ({
        async setAuthState(payload) {
            const cachedAuthState = JSON.parse(payload)
            this.auth(cachedAuthState)
            
            await fetchApi(SESSION_EXPIRY)
        },
        setLoggedIn(payload) {
            window.localStorage.setItem('userTypeId', payload.userTypeIds);
            this.logIn(payload)
        },
        async setLogOut(payload) {
            if (payload !== 401) {
                try {
                    await fetchApi(LOGOUT_OLD_ADMIN, { method: 'DELETE' })
                } catch (error) {
                    console.log(error)
                }
            }
            window.localStorage.clear();
            window.sessionStorage.clear()
            this.logOut();
            dispatch({ type: 'RESET_APP' });
        },
        setUserInfo(payload) {
            this.userInfo(payload)
        },
        setSessionTimeoutTimer({ sessionTimeoutTimer }){
            this.sessionTimeoutTimer({ sessionTimeoutTimer })
        },
        setSessionLogoutTimer({ sessionLogoutTimer }){
            this.sessionLogoutTimer({ sessionLogoutTimer })
        },
        setBirthDate({ birthDate }) {
            this.birthDate({ birthDate });
        },
        setLocation({ location }) {
            this.location({ location });
        },
        setIsSending({ isSending }) {
            this.isSending({ isSending })
        },
        setErrors({ errors }) {
            this.errors({ errors })
        }
    })
}