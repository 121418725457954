import React from 'react';
import PropTypes from 'prop-types';
import './TableField.css';
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const Suffix = styled.div`
    ${tw`flex items-center pr-3 pl-2`}
`

const InputContainer = styled.div`
    ${tw`flex flex-row`}
`

export const TableInput  = ({ data, onChange, errors, dataTestId }) => {
    const {
        placeholder,
        field_id: fieldId,
        required,
        suffix,
        type,
        value,
        disabled
    } = data;

    const handleChange = (value) => {
        onChange({
            value,
            fieldId
        });
    }

    return (
        <div className='table-field-container'>
            <InputContainer>
                <input
                    className={`${disabled ? 'input-read-only' : 'form-input'} ${errors?.length ? 'input-error' : ''}`}
                    type={type}
                    name={fieldId}
                    defaultValue={value}
                    placeholder={placeholder}
                    required={required}
                    onChange={(e) => handleChange(e.target.value)}
                    disabled={disabled}
                    data-testid={`${dataTestId}-input-${fieldId}`}
                />
                {suffix && <Suffix>{suffix}</Suffix>}
            </InputContainer>
            <div className='table-field-errors-wrapper'>
                {errors?.map((error) => <p>{error}</p>)}
            </div>
        </div>
    )
}

TableInput.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    errors: PropTypes.array
}