import React from 'react'
import './FormInput2.css'
import PropTypes from 'prop-types'
import { FormField } from 'components/formFields/FormField/FormField'
import { Button } from 'components/buttons/Button/Button'
import { ButtonTypes } from 'utils/constants'
import { Icons, IconsNames } from 'utils/icons'

export const FormInput2 = ({
	type,
	label,
	min,
	max,
	suffix,
	prefix,
	fieldSize,
	fieldErrors,
	isError,
	isRequired,
	isValidationDisable,
	isReadonly,
	isDisabled,
	value,
	buttons,
	defaultValue,
	placeholder,
	fieldName,
	onChange,
	onRemove,
	hasDuplicatedRows,
	isLastColumn,
	error,
	register,
	dataTestId,
}) => {

	const registerFieldName = register ? {...register(fieldName)} : {}

	return (
		<FormField
			label={label}
			fieldErrors={fieldErrors}
			isRequired={isRequired}
			dataTestId={dataTestId}
			buttons={buttons}
			suffix={suffix}
			fieldSize={fieldSize}
			prefix={prefix}
			isLastColumn={isLastColumn}
		>
			<div className="form-field-column-container">
				<div className="form-input-row-container">
					<input
						className={`form-input ${isError ? 'input-error' : ''}`}
						type={type}
						name={label}
						min={min}
						max={max}
						value={value}
						readOnly={isReadonly}
						defaultValue={defaultValue}
						disabled={isDisabled}
						placeholder={placeholder}
						required={isRequired && !isValidationDisable}
						onChange={(e) => onChange && onChange(e.target.value)}
						data-testid={dataTestId}
						{...registerFieldName}
					/>
				</div>

				{hasDuplicatedRows && isLastColumn && (
					<Button
						type={ButtonTypes.DeleteFile}
						icon={Icons[IconsNames.Delete]}
						dataTestId={dataTestId}
						handleOnClick={() => onRemove(fieldName)}
					/>
				)}
			</div>
		</FormField>
	)
}

FormInput2.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	prefix: PropTypes.any,
	suffix: PropTypes.any,
	buttons: PropTypes.array,
	isRequired: PropTypes.bool,
	isReadonly: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isValidationDisable: PropTypes.bool,
	value: PropTypes.any,
	defaultValue: PropTypes.any,
	placeholder: PropTypes.string,
	fieldName: PropTypes.string,
	onChange: PropTypes.func,
	register: PropTypes.func,
	dataTestId: PropTypes.string,
}

FormInput2.defaultProps = {
	isRequired: false,
	defaultValue: undefined,
	isValidationDisable: false
}
