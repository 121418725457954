import { GoPlus } from 'react-icons/go'
import { TbLayoutDashboard } from 'react-icons/tb'
import { GiTrophyCup } from 'react-icons/gi'
import {
    AiFillInfoCircle,
    AiOutlineMore,
    AiFillCaretDown,
    AiOutlineDownload,
    AiOutlineCheck,
    AiOutlineExclamation
} from 'react-icons/ai'
import {
    MdTimer,
    MdLogout,
    MdOutlineAccountBox,
    MdImageSearch,
    MdOutlineDeleteOutline,
    MdOutlineEdit,
    MdOutlineDashboardCustomize,
    MdKeyboardArrowUp,
    MdOutlineEventNote
} from 'react-icons/md'
import { IoEyeOff, IoEye } from "react-icons/io5";
import { VscChromeClose } from 'react-icons/vsc'
import { BiCopy } from "react-icons/bi";
import { BsExclamationCircleFill, BsFillCheckCircleFill } from 'react-icons/all'

export const IconsNames = {
    Plus: 'plus',
    Dashboard: 'dashboard',
    Benefits: 'benefits',
    ThreeDots: 'threeDots',
    Profile: 'profile',
    Logout: 'logout',
    EyeOff: 'eyeOff',
    Eye: 'eye',
    Info: 'info',
    UploadImage: 'uploadImage',
    Delete: 'delete',
    Edit: 'edit',
    Close: 'close',
    ArrowDown: 'arrowDown',
    Download: 'download',
    Template: 'template',
    Collapse: 'collapse',
    Exclamation: 'exclamation',
    Check: 'check',
    Copy: 'copy',
    Timer: 'timer',
    ExclamationMark: 'exclamation-mark',
    CircleCheck: 'circle-check',
    AuditLog: 'audit'
}

export const Icons = {
    [IconsNames.Plus]: GoPlus,
    [IconsNames.Dashboard]: TbLayoutDashboard,
    [IconsNames.Benefits]: GiTrophyCup,
    [IconsNames.ThreeDots]: AiOutlineMore,
    [IconsNames.Profile]: MdOutlineAccountBox,
    [IconsNames.Logout]: MdLogout,
    [IconsNames.EyeOff]: IoEyeOff,
    [IconsNames.Info]: AiFillInfoCircle,
    [IconsNames.Eye]: IoEye,
    [IconsNames.UploadImage]: MdImageSearch,
    [IconsNames.Delete]: MdOutlineDeleteOutline,
    [IconsNames.Edit]: MdOutlineEdit,
    [IconsNames.ArrowDown]: AiFillCaretDown,
    [IconsNames.Close]: VscChromeClose,
    [IconsNames.Download]: AiOutlineDownload,
    [IconsNames.Template]: MdOutlineDashboardCustomize,
    [IconsNames.Collapse]: MdKeyboardArrowUp,
    [IconsNames.Check]: AiOutlineCheck,
    [IconsNames.Exclamation]: AiOutlineExclamation,
    [IconsNames.Copy]: BiCopy,
    [IconsNames.Timer]: MdTimer,
    [IconsNames.ExclamationMark]: BsExclamationCircleFill,
    [IconsNames.CircleCheck]: BsFillCheckCircleFill,
    [IconsNames.AuditLog]: MdOutlineEventNote
}