import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import { Table } from 'components/global/Table2/Table/Table';
import Loader from "components/global/Loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import {
    DELETE_ROW_MODAL_APPLY,
    DELETE_ROW_MODAL_BODY, DELETE_ROW_MODAL_CANCEL,
    DELETE_ROW_MODAL_HEADER,
    ROW_MENU_DELETE,
    ROW_MENU_EDIT
} from "utils/messages";
import {Icons, IconsNames} from "utils/icons";
import {benefitBuilder} from "store/selectors/benefitBuilder";
import {DELETE_BENEFIT_ROW, EDIT_BENEFIT_ROW, GET_BENEFIT_ROW_FIELDS, VIEW_BENEFIT_TABLE} from "common/endpoints";
import fetchApi from "common/fetchApi";
import {Sorting, sortingDirections} from 'utils/constants';
import {PaginationEnum} from "utils/helpers/tables.helper";
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const TableContainer = styled.div`
	${tw`flex flex-col`}
`

const ErrorContainer = styled.div`
    ${tw `w-full h-6 flex flex-col text-12-16 text-bright-orange items-start`}
`

const ModalMessage = <div>{DELETE_ROW_MODAL_BODY}</div>

export const BenefitBuilderTable = ({ data, tableName, fieldErrors }) => {
    const dispatch = useDispatch();
    const [resultTable, setResultTable] = useState(data);
    const [tableLoading, setTableLoading] = useState(false);
    const [params, setParams] = useState({});
    const [tableParams, setTableParams] = useState({});

    const benefitID = useSelector(benefitBuilder.benefit)?.id;

    const isLoading = useSelector((state) => state.tables[tableName]?.isLoading);
    const isTableUpdated = useSelector((state) => state.tables[tableName]?.isUpdated);
    const tableData = useSelector((state) => state.tables[tableName]);

    useEffect(() => {
        const fetchTable = async () => {
            setTableLoading(true);
            try {
                const response = await fetchApi(VIEW_BENEFIT_TABLE(benefitID, data?.table_id), {
                    params
                });
                response?.data?.rows?.length ?
                    setResultTable({ ...data, ...response?.data })
                    : setResultTable({ ...data, rows: [] })
                dispatch.tables.setIsUpdated({ tableName, value: false })
                setTableLoading(false)
            } catch(error) {
                console.log(error)
                setTableLoading(false)
            }
        }

        if (!tableData?.rows || isTableUpdated) {
            fetchTable()
        }
    }, [tableData?.rows, isTableUpdated])

    useEffect(() => {
        return () => dispatch.tables.setClearTableStore({ tableName })
    }, []);

   const handleEditRow = (rowId) => {
       dispatch.tables.setEditRowData({
           tableName,
           rowId,
           rowFieldsEndpoint: GET_BENEFIT_ROW_FIELDS(benefitID, data?.table_id, rowId),
           editEndpoint: EDIT_BENEFIT_ROW(benefitID, data?.table_id)
       });
   }

   const handleDeleteRow = (rowId) => {
       const onApply = () => {
           dispatch.tables.setDeleteRow({
               tableName,
               rowId,
               deleteEndpoint: DELETE_BENEFIT_ROW(benefitID, data?.table_id)
           });
           dispatch.modal.hideModal()
       }

       dispatch.modal.showModal({
           data: {
               title: DELETE_ROW_MODAL_HEADER,
               body: ModalMessage,
               primaryButtonLabel: DELETE_ROW_MODAL_APPLY,
               onPrimary: onApply,
               secondaryButtonLabel: DELETE_ROW_MODAL_CANCEL
           }
       });
   }

    const menuItems = [
        {
            label: ROW_MENU_EDIT,
            icon: Icons[IconsNames.Edit],
            onClick: handleEditRow
        },
        {
            label: ROW_MENU_DELETE,
            icon: Icons[IconsNames.Delete],
            onClick: handleDeleteRow
        }
    ]

    const onSort = (item) => {
        setTableParams({
            ...tableParams,
            sortingColumn: item?.field,
            sortingDirection: item?.direction
        })
        setParams({
            ...params,
            sort_by:`${item?.field} ${item?.direction === sortingDirections.UP ? Sorting.Desc : Sorting.Asc}`
        })
        dispatch.tables.setIsUpdated({ tableName, value: true })
    };

    const onPagination = (item) => {
        setTableParams({
            ...tableParams,
            pagination: item
        })
        setParams({
            ...params,
            page: PaginationEnum[item.goToPage](item)
        });
        dispatch.tables.setIsUpdated({ tableName, value: true })
    }

    const onFilter = (item) => {
        setTableParams({
            ...tableParams,
            filters: {
                ...tableParams.filters,
                [item.filterName]: {
                    label: item.label,
                    value: item.value
                }
            }
        });
        setParams({
            ...params,
            [`filter[${item.filterName}]`]: item.value
        })
        dispatch.tables.setIsUpdated({ tableName, value: true })
    }

    if (isLoading || tableLoading) {
        return (
            <div className='loader-container'>
                <Loader />
            </div>
        );
    }

    return (
        <TableContainer>
            <Table
                data={resultTable}
                tableName={tableName}
                isRowMenuCollapsed
                menuItems={
                    tableName === 'pricing_lookup' ? [] : menuItems
                }
                onSort={onSort}
                onPagination={onPagination}
                onFilter={onFilter}
                params={tableParams}
                dataTestId={tableName}
            />
            <ErrorContainer>
                {fieldErrors?.map((error, index) => <div key={index}>{error}</div>)}
            </ErrorContainer>
        </TableContainer>
    )
};

BenefitBuilderTable.propTypes = {
    data: PropTypes.shape({
        rows: PropTypes.array,
        columns: PropTypes.array.isRequired
    }),
    tableName: PropTypes.string,
    fieldErrors: PropTypes.array
}

BenefitBuilderTable.defaultProps = {
    data: {
        rows: [],
        columns: []
    }
}
