import React from 'react'
import './BenefitFormStep.css'
import PropTypes from 'prop-types'
import Loader from 'components/global/Loader/Loader'
import { BenefitFormStepHeaderButtons } from 'components/benefit/BenefitFormStep/BenefitFormStepHeaderButtons/BenefitFormStepHeaderButtons'
import { BenefitFormStepContent } from 'components/benefit/BenefitFormStep/BenefitFormStepContent/BenefitFormStepContent'
import { BenefitFormStepDescription } from 'components/benefit/BenefitFormStep/BenefitFormStepDescription/BenefitFormStepDescription'
import { BenefitFormStepFooterButtons } from 'components/benefit/BenefitFormStep/BenefitFormStepFooterButtons/BenefitFormStepFooterButtons'
import { BenefitBuilderKeys } from 'utils/helpers/benefitBuilder.helper'

export const BenefitFormStep = ({
	label,
	columns,
	description,
	footerButtons,
	errors,
	isMultipleUnique,
	headerButtons,
	limitChecker,
	control,
	register,
	onInnerButtonClick,
	fileUploadMessage,
	onStepChanged,
	columnSize,
	errorAsHint
}) => {
	const stepErrors = errors ? errors[BenefitBuilderKeys[label]] : {}

	return (
		<div className="benefit-form-step-container">
			{headerButtons && limitChecker && (
				<BenefitFormStepHeaderButtons headerButtons={headerButtons} />
			)}
			<BenefitFormStepDescription
				description={description}
				columnSize={columnSize}
			/>
			{columns ? (
				<>
					<BenefitFormStepContent
						label={label}
						columns={columns}
						errors={stepErrors}
						columnSize={columnSize}
						fileUploadMessage={fileUploadMessage}
						isMultipleUnique={isMultipleUnique}
						control={control}
						register={register}
						onInnerButtonClick={onInnerButtonClick}
						onStepChanged={onStepChanged}
						errorAsHint={errorAsHint}
					/>
					{footerButtons && (
						<BenefitFormStepFooterButtons footerButtons={footerButtons} />
					)}
				</>
			) : (
				<Loader />
			)}
		</div>
	)
}

BenefitFormStep.propTypes = {
	label: PropTypes.string,
	buttons: PropTypes.array,
	columns: PropTypes.object,
	footerButtons: PropTypes.array,
	limitChecker: PropTypes.bool,
	isMultipleUnique: PropTypes.bool,
	control: PropTypes.object,
	register: PropTypes.func,
	fileUploadMessage: PropTypes.string,
	columnSize: PropTypes.string,
	onStepChanged: PropTypes.func.isRequired,
	onInnerButtonClick: PropTypes.func,
	errorAsHint: PropTypes.bool,
}

BenefitFormStep.defaultProps = {
	label: '',
	limitChecker: true
}
