import { sortingDirections } from 'utils/constants';

const defaultState = {
    userRowsPerPage: '25',
    searchValue: '',
    filterValue: '',
    sortingData: {
        direction: sortingDirections.DOWN,
        column: 'name'
    },
    data: {
        current_page: 1
    },
    isUpdatedData: false
};

export default {
    state: defaultState,
    reducers: {
        rowsPerPage(state, payload) {
            return {
                ...state,
                userRowsPerPage: payload,
                data: {
                    ...state.data,
                    current_page: 1
                }
            }
        },
        sortingData(state, payload) {
            return {
                ...state,
                sortingData: {
                    direction: payload.direction,
                    column: payload.column
                }
            }
        },
        currentPage(state, payload) {
            return {
                ...state,
                data: {
                    ...state.data,
                    current_page: payload
                }
            }
        },
        tableData(state, payload) {
            return {
                ...state,
                data: payload
            }
        },
        searchPage(state, payload) {
            return {
                ...state,
                searchValue: payload
            }
        },
        filterPage(state, payload) {
            return {
                ...state,
                filterValue: payload
            }
        },
        isUpdatedData(state, payload) {
            return {
                ...state,
                isUpdatedData: payload
            }
        },
        clearStore() {
            return defaultState;
        }
    },
    effects: () => ({
        setRowsPerPage(payload) {
            this.rowsPerPage(payload)
        },
        setSortingData(payload) {
            this.sortingData(payload)
        },
        setCurrentPage(payload) {
            this.currentPage(payload)
        },
        setTableData(payload) {
            this.tableData(payload);
        },
        setSearchPage(payload) {
            this.searchPage(payload)
        },
        setFilterPage(payload) {
            this.filterPage(payload)
        },
        setClearStore() {
            this.clearStore()
        },
        setIsUpdatedData(payload) {
            this.isUpdatedData(payload)
        }
    })
}