import {sortingDirections} from 'utils/constants';

const defaultState = {
    rowsPerPage: '25',
    searchValue: '',
    sortingData: {
        direction: sortingDirections.DOWN,
        column: 'person_sname'
    },
    data: {
        current_page: 1
    },
    isUpdatedData: false
};

export default {
    state: defaultState,
    reducers: {
        rowsPerPage(state, payload) {
            return {
                ...state,
                rowsPerPage: payload,
                data: {
                    ...state.data,
                    current_page: 1
                }
            }
        },
        sortingData(state, payload) {
            return {
                ...state,
                sortingData: {
                    direction: payload.direction,
                    column: payload.column
                }
            }
        },
        currentPage(state, payload) {
            return {
                ...state,
                data: {
                    ...state.data,
                    current_page: payload
                }
            }
        },
        searchPage(state, payload) {
            return {
                ...state,
                searchValue: payload
            }
        },
        tableData(state, payload) {
            return {
                ...state,
                data: payload
            }
        },
        isUpdatedData(state, payload) {
            return {
                ...state,
                isUpdatedData: payload
            }
        },
        clearStore() {
            return defaultState;
        }
    },
    effects: () => ({
        setRowsPerPage(payload) {
            this.rowsPerPage(payload)
        },
        setSortingData(payload) {
            this.sortingData(payload)
        },
        setCurrentPage(payload) {
            this.currentPage(payload)
        },
        setSearchPage(payload) {
            this.searchPage(payload)
        },
        setTableData(payload) {
            this.tableData(payload);
        },
        setClearStore() {
            this.clearStore()
        },
        setIsUpdatedData(payload) {
            this.isUpdatedData(payload)
        }
    })
}