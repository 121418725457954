export const prepareUsersList = (array) => {
    return array.map((item) => {
        return {
            label: `${item.user_first_name} ${item.user_sur_name}`,
            additional: item.user_email,
            value: item.user_id
        }
    })
}

export const convertOptionToCheckbox = (option) => {
    return {
        id: option?.value,
        label: option?.label,
        additional: option?.additional,
        selected: true
    }
}

export const convertUserToCheckbox = (option) => {
    return {
        id: option?.person_id,
        label: `${option?.person_fname} ${option?.person_sname}`,
        additional: option?.person_email,
        selected: true
    }
}

export const convertCheckoxesToOptions = (checkboxex) => {
    return checkboxex.map((checkbox) => convertCheckboxToOption(checkbox))
}

export const convertCheckboxToOption = (checkbox) => {
    return {
        additional: checkbox.additional,
        label: checkbox.label,
        value: checkbox.id
    }
}

export const prepareDropdownList = ({ array, valueField = 'id', defaultValues = [] }) => {
    return array?.reduce((acc, item) => {
        acc.push({
            label: item.name,
            value: item[valueField]
        })
        return acc;
    }, defaultValues);
};