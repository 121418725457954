export const reverseFirstLetterCapitalize = (word) => {
    if (!word) {
		return ''
	}

    const firstLatter = word.charAt(0);
    const capitalizedLatter = word.charAt(0).toUpperCase();

    const result = firstLatter === capitalizedLatter
        ? firstLatter.toLowerCase()
        : capitalizedLatter

    return result + word.slice(1);
}

export const prepareDate = (date) => {
	if (!date) {
		return ''
	}

	if (date instanceof Date) {
        return new Date(date).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
    }

	return date;
}