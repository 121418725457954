export const directions = {
    BACK: 'BACK',
    NEXT: 'NEXT'
};

export const paginationDirections = {
    FIRST: 'FIRST',
    PREVIOUS: 'PREVIOUS',
    NEXT: 'NEXT',
    LAST: 'LAST'
};

export const Status = {
    Disabled: 'Disabled',
    Active: 'Active'
}

export const sortingDirections = {
    DOWN: 'DOWN',
    UP: 'UP',
    NONE: 'NONE'
}

export const Sorting = {
    Asc: 'asc',
    Desc: 'desc',
}

export const buttonSize = {
    SMALL: 'common-button-small',
    MEDIUM: 'common-button-medium',
    LARGE: 'common-button-large'
}

export const ButtonTypes = {
    Primary: 'primary',
    Secondary: 'secondary',
    Edit: 'edit',
    Remove: 'remove',
    SeeAll: 'seeAll',
    Pagination: 'pagination',
    Menu: 'menu',
    DeleteFile: 'deleteFile',
    Eye: 'eye',
    Dropdown: 'dropdown',
    Audit: 'audit'
}

export const ButtonLabels = {
    Delete: 'Delete',
    Edit: 'Edit',
    Remove: 'Remove',
    Next: 'Next',
    Back: 'Back',
    BackToLogin: 'Back to Login',
    SeeAll: 'See all',
    Ok: 'Ok',
    Collapse: 'Collapse',
    SaveAsDraft: 'Save as draft',
    Publish: 'Publish',
    FirstPage: 'First page',
    PreviousPage: 'Previous page',
    NextPage: 'Next page',
    LastPage: 'Last page',
    Cancel: 'Cancel',
    Save: 'Save',
    AddValueLimits: 'Add value limit',
    AddLink: 'Add link',
    AddContractTerm: 'Add contract term',
    Upload: 'Upload file',
    RecoveryLink: 'Send a recovery link',
    LogIn: 'Log in',
    LogOut: 'Log out',
    Continue: 'Continue',
    ResetPassword: 'Reset password'
}

export const ButtonSizes = {
    Small: 'small',
    Medium: 'medium',
    Large: 'large',
}

export const ContextTypes = {
    AddBenefit: 'Add',
    EditBenefit: 'Edit'
}

export const FormColumnSizes = {
    Multi: 'form-column-size-multi',
    Single: 'form-column-size-single',
    Duplicate: 'form-column-size-duplicate'
}

export const ModalWidthSizes = {
    Default: 'modal-window-default-width-size'
}

export const FormInputFieldSizes = {
	Default: 'form-input-field-size-default',
	Middle: 'form-input-field-size-middle',
}

export const PageTitles = {
    BuildBenefit: 'Build benefit',
    EditBenefit: 'Edit benefit',
    Benefits: 'Benefits',
    Dashboard: 'Dashboard',
    MyProfile: 'My profile'
}

export const Paths = {
    AddBenefit: '/benefits/addBenefit',
    EditBenefit: '/benefits/edit/:id',
    ViewBenefit: '/benefits/viewBenefit',
    Benefits: '/app/benefits',
    Dashboard: '/app/dashboard',
    Activation: '/activate/:id',
    ResetPassword: '/reset-password',
    AuditLog: '/app/audit',
    TwoFA: '/twoFA',
}

export const FetchMethods = {
    Delete: 'DELETE',
    Patch: 'PATCH',
    Post: 'POST',
    Get: 'GET',
}

export const FieldTypes = {
    Dropdown: 'dropdown',
    Date: 'date',
    Number: 'number',
    Text: 'text',
    DropdownCheckboxList: 'dropdown-checkbox-list',
    Editor: 'wysiwyg',
    Checkbox: 'checkbox',
    Table: 'table',
    File: 'file',
    RadioButtonsList: 'radio_buttons_list',
    RadioButtons: 'radio_buttons',
}

export const Order = {
    First: 'first',
    Second: 'second'
}

export const ProfileInfoKeys = {
    Firstname: 'first_name',
    Lastname: 'surname',
    DateOfBirth: 'date_of_birth',
    Email: 'email',
}

export const ProfileInfoLabels = {
    [ProfileInfoKeys.Firstname]: 'First name',
    [ProfileInfoKeys.Lastname]: 'Last name',
    [ProfileInfoKeys.DateOfBirth]: 'Date of birth',
    [ProfileInfoKeys.Email]: 'Email',
}
