import fetchApi from "common/fetchApi";
import {UPLOAD_FILE} from "common/endpoints";
import { FetchMethods } from 'utils/constants';

const getRowData = async (endpoint, params) => {
    try {
        const response = await fetchApi(endpoint, {
            params
        })
        const { fields } = response.data

        return fields
    } catch (error) {
        console.log(error)
    }
}

const initialState = {
    successMessage: undefined,
    errorMessage: undefined,
    isUpdated: undefined
};

export default {
    state: initialState,
    reducers: {
        table(state, { tableName, data, params }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    ...data,
                    params: {
                        ...params
                    }
                }
            }
        },
        clearTableStore(state, { tableName }) {
            return {
                ...state,
                [tableName]: {}
            };
        },
        cancelAddRow(state, { tableName }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    rowData: [],
                    rowFields: {},
                    editRowId: undefined,
                    addNewRow: false,
                    templateFile: {},
                    errors: {}
                }
            }
        },
        addRow(state, { tableName, rowData, saveEndpoint }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    rowData: [...rowData],
                    saveEndpoint: saveEndpoint,
                    addNewRow: true,
                    editRowId: undefined
                }
            }
        },
        editRow(state, { tableName, rowData, editEndpoint }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    rowData: [...rowData],
                    editEndpoint: editEndpoint,
                    addNewRow: false
                }
            }
        },
        rowFields(state, { tableName, field }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    rowFields: {
                        ...state[tableName].rowFields,
                        [field.fieldId]: field.value
                    }
                }
            }
        },
        addNewRows(state, { tableName, rows }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    newRows: [...rows]
                }
            }
        },
        editRowId(state, { tableName, rowId }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    editRowId: rowId
                }
            }
        },
        successMessage(state, { successMessage }) {
            return {
                ...state,
                successMessage,
            }
        },
        errorMessage(state, { errorMessage }) {
            return {
                ...state,
                errorMessage,
            }
        },
        isUpdated(state, { tableName, value }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    isUpdated: value
                }
            }
        },
        templateFile(state, { tableName, file }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    templateFile: file
                }
            }
        },
        isLoading(state, { tableName, isLoading }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    isLoading
                }
            }
        },
        errors(state, { tableName, errors }) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    errors: {
                        ...errors
                    }
                }
            }
        }
    },
    effects: () => ({
        setTable({ tableName, data, params }) {
            this.table({
                tableName,
                data,
                params
            })
        },
        setClearTableStore({ tableName }) {
            this.clearTableStore({ tableName })
        },
        setCancelAddRow({ tableName }) {
            this.cancelAddRow({ tableName });
        },
        setSuccessMessage({ successMessage }) {
            this.successMessage({ successMessage })
        },
        setIsUpdated({ tableName, value }) {
            this.isUpdated({ tableName, value })
        },
        setErrorMessage({ errorMessage }) {
            this.errorMessage({ errorMessage })
        },
        setTemplateFile({ tableName, file }) {
            this.templateFile({ tableName, file })
        },
        setErrors({ tableName, errors }) {
            this.errors({ tableName, errors })
        },
        setClearMessages() {
            this.setSuccessMessage({ successMessage: undefined })
            this.setErrorMessage({ errorMessage: undefined })
        },
        setIsLoading({ tableName, isLoading }) {
            this.isLoading({ tableName, isLoading })
        },
        async setAddRowData({ tableName, endpoint, saveEndpoint, params }) {
            this.setIsLoading({ tableName, isLoading: true })
            this.setErrors({ tableName, errors: {} })
            if (endpoint) {
                const fields = await getRowData(endpoint, params)
                this.addRow({ tableName, rowData: fields, saveEndpoint })
                this.setIsLoading({ tableName, isLoading: false })
            } else {
                this.setIsLoading({ tableName, isLoading: false })
            }
        },
        async setEditRowData({ tableName, rowFieldsEndpoint, rowId, editEndpoint  }) {
            this.setIsLoading({ tableName, isLoading: true })
            this.setErrors({ tableName, errors: {} })
            this.editRowId({ tableName, rowId })
            if (rowFieldsEndpoint) {
                const fields = await getRowData(rowFieldsEndpoint)
                this.editRow({ tableName, rowData: fields, editEndpoint })
                this.setIsLoading({ tableName, isLoading: false })
            } else {
                this.setIsLoading({ tableName, isLoading: false })
            }
        },
        setRowFields({ tableName, field }) {
            this.rowFields({ tableName, field });
        },
        async setSubmitRow({ tableName, saveEndpoint, newRowFields }) {
            try {
                this.setIsLoading({ tableName, isLoading: true })

                const response = await fetchApi(saveEndpoint, {
                    method: FetchMethods.Post,
                    data: {...newRowFields}
                })
                this.setIsUpdated({ tableName, value: true })
                this.setSuccessMessage({ successMessage: response.data.message })
                this.cancelAddRow( { tableName });
                this.setIsLoading({ tableName, isLoading: false })
            } catch (error) {
                this.setErrorMessage({ errorMessage: error.response.data.message })
                this.setErrors({ tableName, errors: error.response.data.errors })
                this.setIsLoading({ tableName, isLoading: false })
            }
        },
        async setSubmitEditRow({ tableName, editEndpoint, editedRow }) {
            try {
                this.setIsLoading({ tableName, isLoading: true })

                const response = await fetchApi(editEndpoint, {
                    method: FetchMethods.Patch,
                    data: {...editedRow}
                });
                this.setIsUpdated({ tableName, value: true })
                this.setSuccessMessage({ successMessage: response.data.message })
                this.cancelAddRow( { tableName });
                this.setIsLoading({ tableName, isLoading: false })
            } catch (error) {
                this.setErrorMessage({ errorMessage: error.response.data.message })
                this.setErrors({ tableName, errors: error.response.data.errors })
                this.setIsLoading({ tableName, isLoading: false })
            }
        },
        async setDeleteRow({ tableName, rowId, deleteEndpoint }) {
            try {
                this.setIsLoading({ tableName, isLoading: true })

                const response = await fetchApi(deleteEndpoint, {
                    method: 'DELETE',
                    data: {
                        row_id: rowId
                    }
                });
                this.setIsUpdated({ tableName, value: true })
                this.setSuccessMessage({ successMessage: response.data.message })
                this.setIsLoading({ tableName, isLoading: false })
            } catch (error) {
                this.setErrorMessage({ errorMessage: error.response.data.message })
                this.setIsLoading({ tableName, isLoading: false })
            }
        },
        async setUploadTable({ tableName, tableKey, benefitID, tableData }) {
            try {
                this.setIsLoading({ tableName, isLoading: true })

                const response = await fetchApi(`${UPLOAD_FILE}?type=${tableKey}&benefitID=${benefitID}`, {
                    method: FetchMethods.Post,
                    data: tableData
                })
                this.setTemplateFile({ tableName, file: response.data.files[0] })
                this.setIsUpdated({ tableName, value: true })
                this.setSuccessMessage({ successMessage: response.data.message })
                this.setIsLoading({ tableName, isLoading: false })
            } catch (error) {
                this.setErrorMessage({ errorMessage: error.response.data.message })
                this.setErrors({ tableName, errors: Object.values(error.response.data.errors)[0] })
                this.setIsLoading({ tableName, isLoading: false })
            }
        }
    })
}