import React from 'react'
import './Button.css'
import PropTypes from 'prop-types';

const IconComponent = ({ icon, alt }) => {
	return typeof icon === 'function' ?
		<span className="button-icon">{icon()}</span>
		: <img src={icon} alt={alt} className="button-icon" />
};

export const Button = ({
	label,
	alt,
	icon,
	type,
	size,
	disabled,
	handleOnClick,
	id,
	dataTestId,
}) => {

	const onClick = (event) => {
		if (disabled) {
			return
		}

		handleOnClick(event)
	}

	return (
		<button
			className="button"
			disabled={disabled}
			type={type}
			onClick={onClick}
			id={id}
			data-size={size}
			data-testid={`${dataTestId}-${label}-button`}
		>
			<div className="button-content-container">
				{icon && <IconComponent icon={icon} alt={alt} />}
				{label && <span className="button-label">{label}</span>}
			</div>
		</button>
	)
}

Button.propTypes = {
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	size: PropTypes.string,
	disabled: PropTypes.bool,
	alt: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	handleOnClick: PropTypes.func.isRequired,
	id: PropTypes.string,
	dataTestId: PropTypes.string,
}

Button.defaultProps = {
	label: ''
}