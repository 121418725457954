import React from 'react'
import './FormColumn.css'
import PropTypes from 'prop-types'
import { FormColumnSizes } from 'utils/constants'

export const FormColumn = ({
    size,
    children 
}) => {
    const classes = `form-column ${size}`

    return (
        <div className={classes}>
            {children}
        </div>
    )
}

FormColumn.propTypes = {
    size: PropTypes.string
}

FormColumn.defaultProps = {
    size: FormColumnSizes.Multi
}