import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import fetchApi from 'common/fetchApi'
import { benefitBuilder } from 'store/selectors/benefitBuilder'
import { BenefitBuilderLabels, getButtonComponents, getDuplicatedColumns, hasAvailableOptions, prepareFieldsData } from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_PARTNER_VALUE_LIMITS_STEP } from 'common/endpoints'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import { toast } from 'react-toastify'
import { NO_MORE_OPTIONS } from 'utils/messages'

export const PartnerValueLimits = ({ data, onChange }) => {
	const benefitID = useSelector(benefitBuilder.benefit)?.id
	const [state, setState] = useState()
	const { register, control } = useForm()
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_PARTNER_VALUE_LIMITS_STEP(benefitBuilderContextAPI, benefitID))
				const [
					{
						content,
						label,
						description,
						footer_buttons,
						multiple_unique: isMultipleUnique,
					},
				] = response.data.steps
				const columns = prepareFieldsData(content)

				setState({ columns, label, description, footer_buttons, isMultipleUnique })

				onChange(label, {
					label,
					columns,
					description,
					footer_buttons,
					isMultipleUnique,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.ValueLimits, {
					columns: [],
					footer_buttons: [],
					isLoaded: true
				})
			}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleDuplicateButtonClick = (label, columns) => {
		const isDuplicatingAvailable = hasAvailableOptions(columns)

		if (!isDuplicatingAvailable) {
			toast.error(NO_MORE_OPTIONS)
			return
		}

		onChange(label, {
			columns: getDuplicatedColumns(columns),
		})
	}

	const footerButtons = getButtonComponents(state?.footer_buttons, (options) =>
		handleDuplicateButtonClick(state?.label, state?.columns)
	)

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			errors={benefitBuilderErrors}
			description={state?.description}
			isMultipleUnique={state?.isMultipleUnique}
			footerButtons={footerButtons}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
		/>
	)
}

PartnerValueLimits.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
