import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BenefitBuilderLabels, getButtonComponents, getDuplicatedColumns, prepareFieldsData } from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_FAMILY_ELIGIBILITY_STEP } from 'common/endpoints'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { useSelector } from 'react-redux'
import { benefitBuilder } from 'store/selectors/benefitBuilder'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'

export const FamilyEligibility = ({ data, onChange }) => {
	const [state, setState] = useState()
	const { register, control } = useForm()
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)
	let limitChecker = true;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_FAMILY_ELIGIBILITY_STEP(benefitBuilderContextAPI))
				const [{ content, label, header_buttons, multiple_unique: isMultipleUnique, multiple_limit: multipleLimit }] = response.data.steps
				const columns = prepareFieldsData(content)

				setState({ columns, label, header_buttons, isMultipleUnique, multipleLimit })

				onChange(label, {
					label,
					columns,
					header_buttons,
					isMultipleUnique,
					multipleLimit,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.FamilyEligibility, {
                    columns: [],
					header_buttons: [],
                    isLoaded: true
                })
			}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleDuplicateButtonClick = (label, columns) => {
		onChange(label, {
			columns: getDuplicatedColumns(columns),
		})
	}

	const headerButtons = getButtonComponents(state?.header_buttons, (options) =>
		handleDuplicateButtonClick(state?.label, state?.columns)
	)

	if (state?.columns) {
		if (state?.columns[0]?.fields && state?.multipleLimit) {
			limitChecker = Object.keys(state?.columns[0]?.fields).length < state?.multipleLimit;
		}
	}

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			errors={benefitBuilderErrors}
			isMultipleUnique={state?.isMultipleUnique}
			headerButtons={headerButtons}
			limitChecker={limitChecker}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
		/>
	)
}

FamilyEligibility.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
