import React from 'react'
import PropTypes from 'prop-types'
import { FormField } from 'components/formFields/FormField/FormField'
import { DropdownCheckboxList } from 'components/commonFields/DropdownCheckboxList/DropdownCheckboxList'

export const FormDropdownCheckboxList = ({
    checkboxes,
    isRequired,
    label,
    placeholder,
    dropdownLabel,
    checkboxListHeader,
    excludedOptions,
    loadCallback,
    isSearchable,
    defaultOptions,
    fieldName,
    onChange,
    dataTestId
}) => {
    return (
        <FormField isRequired={isRequired} label={label} dataTestId={dataTestId}>
			<DropdownCheckboxList 
                checkboxes={checkboxes}
                placeholder={placeholder}
                dropdownLabel={dropdownLabel}
                checkboxListHeader={checkboxListHeader}
                excludedOptions={excludedOptions}
                isSearchable={isSearchable}
                loadCallback={loadCallback}
                defaultOptions={defaultOptions}
                onChange={onChange}
                dataTestId={dataTestId}
            />
		</FormField>
    )
}

FormDropdownCheckboxList.propsTypes = {
    checkboxes: PropTypes.array,
	placeholder: PropTypes.string,
	dropdownLabel: PropTypes.string,
	checkboxListHeader: PropTypes.string,
	excludedOptions: PropTypes.array,
	onCheckboxListChange: PropTypes.func,
	isSearchable: PropTypes.bool,
	loadCallback: PropTypes.func,
    fieldName: PropTypes.string,
	handleDropdownChange: PropTypes.func,
	defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
}

FormDropdownCheckboxList.defaultProps = {
    label: '',
    checkboxListHeader: 'Your selections'
}