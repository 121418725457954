export const isEmpty = (array) => {
	if (!array) {
		return true
	}

	return array.length === 0
}

export const isNotEmpty = (array) => {
	return !isEmpty(array)
}

export const getLastItem = (array) => {
	if (isEmpty(array)) {
		return null;
	}

	return array[array.length - 1]
}

export const getArrayWithoutDuplcatesByKey = (array, key) => {
	return array.filter((value, index, array) => {
		return (
			array.findIndex((value2) => {
				return value2[key] === value[key]
			}) === index
		)
	})
}
