import React from 'react'
import { BenefitInfo } from 'components/benefitSteps/BenefitInfo'
import { TelephoneNumber } from 'components/benefitSteps/TelephoneNumber'
import { BenefitSetupSuccessMessage } from 'components/benefitSteps/BenefitSetupSuccessMessage'
import { Provider } from 'components/benefitSteps/Provider'
import { AgeCalculation } from 'components/benefitSteps/AgeCalculation'
import { PeopleCovered } from 'components/benefitSteps/PeopleCovered'
import { CTANote } from 'components/benefitSteps/CTANote'
import { LifeEvents } from 'components/benefitSteps/LifeEvents'
import { ContractTerm } from 'components/benefitSteps/ContractTerm'
import { BenefitFund } from 'components/benefitSteps/BenefitFund'
import { TermsAndConditions } from 'components/benefitSteps/TermsAndConditions'
import { NIUplift } from 'components/benefitSteps/NIUplift'
import { CoverAmountAdjustment } from 'components/benefitSteps/CoverAmountAdjustment'
import { ValueLimits } from 'components/benefitSteps/ValueLimits'
import { BenefitDescription } from 'components/benefitSteps/BenefitDescription'
import { TermAndEnrolment } from 'components/benefitSteps/TermAndEnrolment'
import { prepareDate } from 'utils/helpers/format.helper'
import { TaxFlags } from 'components/benefitSteps/TaxFlags'
import { OptOutMessage } from 'components/benefitSteps/OptOutMessage';
import { Scheme } from 'components/benefitSteps/Scheme';
import { Button } from 'components/buttons/Button/Button'
import { ButtonSizes, ButtonTypes, FieldTypes } from 'utils/constants'
import { Icons } from 'utils/icons'
import { Links } from 'components/benefitSteps/Links'
import { isEmpty } from 'utils/helpers/array.helper'
import { isObjectEmpty } from 'utils/helpers/common.helper'
import { Documents } from "components/benefitSteps/Documents";
import { EmployerMatch } from 'components/benefitSteps/EmployerMatch'
import { BenefitIcon} from "components/benefitSteps/BenefitIcon";
import { FamilyEligibility } from 'components/benefitSteps/FamilyEligibility'
import { AllowedActions } from "components/benefitSteps/AllowedActions";
import { LookupTable } from "components/benefitSteps/LookupTable";
import { BenefitIntegration } from "components/benefitSteps/BenefitIntegration";
import { SelectionAdditionalData } from "components/benefitSteps/SelectionAdditionalData";
import { Options } from "components/benefitSteps/Options";
import { OptionEligibility } from "components/benefitSteps/OptionEligibility";
import { BenefitEligibility } from "components/benefitSteps/BenefitEligibility";
import parse from "html-react-parser";
import { buildDropdownOption } from 'utils/helpers/dropdown.helper'
import { MapFields } from 'components/benefitSteps/MapFields'
import { PartnerValueLimits } from 'components/benefitSteps/PartnerValueLimits'

export const SaveStatus = {
	Draft: 'draft',
}

export const BenefitBuilderLabels = {
	BenefitInfo: 'Benefit info',
	Provider: 'Provider',
	BenefitDescription: 'Benefit description',
	CTANote: 'CTA note',
	Links: 'Link(s) to external sites',
	FamilyEligibility: 'Family eligibility rule(s)',
	TelephoneNumber: 'Telephone number',
	LifeEvents: 'Life events',
	PeopleCovered: 'People covered',
	AgeCalculation: 'Age calculation',
	TermsAndConditions: 'Terms & conditions',
	BenefitSetupSuccessMessage: 'Benefit setup success message',
	BenefitFund: 'Benefit fund',
	ContractTerm: 'Contract term(s)',
	NIUplift: 'NI uplift',
	CoverAmountAdjustment: 'Cover amount adjustment',
	ValueLimits: 'Value limits',
	PartnerValueLimits: 'Partner value limits',
	TermAndEnrolment: 'Benefit term and enrolment',
	TaxFlags: 'Tax flags settings',
	OptOutMessage: 'Opt out message',
	Documents: 'Document(s)',
	EmployerMatch: 'Pension employer contribution match rules',
	Icon: 'Benefit icon',
	Scheme: 'Scheme year',
	LookupTable: 'Lookup table',
	AllowedActions: 'Allowed actions',
	BenefitIntegration: 'Benefit integration',
	SelectionAdditionalData: 'Selection additional data',
	Options: 'Options',
	OptionEligibility: 'Option eligibility rule(s)',
	BenefitEligibility: 'Benefit eligibility rule(s)',
	MapFields: 'Map fields',
}

export const BenefitBuilderKeys = {
	[BenefitBuilderLabels.BenefitInfo]: 'benefit_info',
	[BenefitBuilderLabels.Provider]: 'provider',
	[BenefitBuilderLabels.BenefitDescription]: 'description',
	[BenefitBuilderLabels.PeopleCovered]: 'people_covered',
	[BenefitBuilderLabels.ContractTerm]: 'contract_terms',
	[BenefitBuilderLabels.OptOutMessage]: 'opt_out_message',
	[BenefitBuilderLabels.FamilyEligibility]: 'family_eligibility',
	[BenefitBuilderLabels.Links]: 'link',
	[BenefitBuilderLabels.CTANote]: 'cta_note',
	[BenefitBuilderLabels.ValueLimits]: 'value_limit',
	[BenefitBuilderLabels.PartnerValueLimits]: 'partner_value_limit',
	[BenefitBuilderLabels.AgeCalculation]: 'age_calculation',
	[BenefitBuilderLabels.TermsAndConditions]: 'terms_and_conditions',
	[BenefitBuilderLabels.LifeEvents]: 'life_event',
	[BenefitBuilderLabels.TermAndEnrolment]: 'term_enrolment',
	[BenefitBuilderLabels.BenefitSetupSuccessMessage]: 'setup_success_message',
	[BenefitBuilderLabels.TelephoneNumber]: 'telephone_number',
	[BenefitBuilderLabels.Scheme]: 'scheme',
	[BenefitBuilderLabels.Documents]: 'documents',
	[BenefitBuilderLabels.Icon]: 'icon',
	[BenefitBuilderLabels.LookupTable]: 'pricing_lookup',
	[BenefitBuilderLabels.AllowedActions]: 'allowed_actions',
	[BenefitBuilderLabels.BenefitIntegration]: 'sso_integration',
	[BenefitBuilderLabels.SelectionAdditionalData]: 'saml_selection',
	[BenefitBuilderLabels.Options]: 'option',
	[BenefitBuilderLabels.OptionEligibility]: 'option_eligibility',
	[BenefitBuilderLabels.BenefitEligibility]: 'benefit_eligibility',
    [BenefitBuilderLabels.BenefitFund]: 'fund',
	[BenefitBuilderLabels.AllowedActions]: 'simple_action',
  	[BenefitBuilderLabels.TaxFlags]: 'tax_flag',
	[BenefitBuilderLabels.CoverAmountAdjustment]: 'pricing_esa_allowance',
	[BenefitBuilderLabels.NIUplift]: 'pricing_ni_uplift',
	[BenefitBuilderLabels.EmployerMatch]: 'employer_match',
	[BenefitBuilderLabels.MapFields]: 'saml_selection_field',
}

export const TableKeys = {
	[BenefitBuilderLabels.PeopleCovered]: 'people_covered',
	[BenefitBuilderLabels.LookupTable]: 'pricing_lookup',
	[BenefitBuilderLabels.BenefitEligibility]: 'benefit_eligibility',
	[BenefitBuilderLabels.OptionEligibility]: 'option_eligibility',
	[BenefitBuilderLabels.EmployerMatch]: 'employer_match',
	[BenefitBuilderLabels.MapFields]: 'saml_selection_field'
}

export const BenefitStepKeys = {
	[BenefitBuilderLabels.TelephoneNumber]: 'telephone_number',
	[BenefitBuilderLabels.BenefitSetupSuccessMessage]: 'setup_success_message',
	[BenefitBuilderLabels.BenefitFund]: 'other.fund',
	[BenefitBuilderLabels.Links]: 'link',
	[BenefitBuilderLabels.LifeEvents]: 'selection_rules.life_event',
	[BenefitBuilderLabels.BenefitDescription]: 'description',
	[BenefitBuilderLabels.Provider]: 'provider',
	[BenefitBuilderLabels.FamilyEligibility]: 'eligibility_rules.family_eligibility',
	[BenefitBuilderLabels.TermsAndConditions]: 'terms_and_conditions',
	[BenefitBuilderLabels.PeopleCovered]: 'selection_choices.people_covered',
	[BenefitBuilderLabels.CTANote]: 'cta_note',
	[BenefitBuilderLabels.AgeCalculation]: 'costs.age_calculation',
	[BenefitBuilderLabels.ContractTerm]: 'selection_choices.contract_terms',
	[BenefitBuilderLabels.NIUplift]: 'costs.pricing_ni_uplift',
	[BenefitBuilderLabels.CoverAmountAdjustment]: 'costs.pricing_esa_allowance',
	[BenefitBuilderLabels.ValueLimits]: 'selection_choices.value_limit',
	[BenefitBuilderLabels.PartnerValueLimits]: 'selection_choices.partner_value_limit',
	[BenefitBuilderLabels.TermAndEnrolment]: 'other.term_enrolment',
	[BenefitBuilderLabels.OptOutMessage]: 'opt_out_message',
	[BenefitBuilderLabels.TaxFlags]: 'other.tax_flag',
	[BenefitBuilderLabels.Scheme]: 'scheme',
	[BenefitBuilderLabels.Documents]: 'documents',
	[BenefitBuilderLabels.MapFields]: 'sso.saml_selection_field',
	[BenefitBuilderLabels.Icon]: 'icon',
	[BenefitBuilderLabels.EmployerMatch]: 'costs.employer_match',
	[BenefitBuilderLabels.LookupTable]: 'costs.pricing_lookup',
	[BenefitBuilderLabels.AllowedActions]: 'selection_rules.simple_action',
	[BenefitBuilderLabels.BenefitIntegration]: 'sso.sso_integration',
	[BenefitBuilderLabels.SelectionAdditionalData]: 'sso.saml_selection',
	[BenefitBuilderLabels.Options]: 'selection_choices.option',
	[BenefitBuilderLabels.OptionEligibility]: 'eligibility_rules.option_eligibility',
	[BenefitBuilderLabels.BenefitEligibility]: 'eligibility_rules.benefit_eligibility',
}

export const BenefitSteps = {
	[BenefitBuilderLabels.BenefitInfo]: <BenefitInfo />,
	[BenefitBuilderLabels.TelephoneNumber]: <TelephoneNumber />,
	[BenefitBuilderLabels.BenefitSetupSuccessMessage]: (
		<BenefitSetupSuccessMessage />
	),
	[BenefitBuilderLabels.BenefitFund]: <BenefitFund />,
	[BenefitBuilderLabels.Links]: <Links />,
	[BenefitBuilderLabels.LifeEvents]: <LifeEvents />,
	[BenefitBuilderLabels.BenefitDescription]: <BenefitDescription />,
	[BenefitBuilderLabels.Provider]: <Provider />,
	[BenefitBuilderLabels.FamilyEligibility]: <FamilyEligibility />,
	[BenefitBuilderLabels.TermsAndConditions]: <TermsAndConditions />,
	[BenefitBuilderLabels.PeopleCovered]: <PeopleCovered />,
	[BenefitBuilderLabels.CTANote]: <CTANote />,
	[BenefitBuilderLabels.AgeCalculation]: <AgeCalculation />,
	[BenefitBuilderLabels.ContractTerm]: <ContractTerm />,
	[BenefitBuilderLabels.NIUplift]: <NIUplift />,
	[BenefitBuilderLabels.CoverAmountAdjustment]: <CoverAmountAdjustment />,
	[BenefitBuilderLabels.ValueLimits]: <ValueLimits />,
	[BenefitBuilderLabels.PartnerValueLimits]: <PartnerValueLimits />,
	[BenefitBuilderLabels.TermAndEnrolment]: <TermAndEnrolment />,
	[BenefitBuilderLabels.OptOutMessage]: <OptOutMessage />,
	[BenefitBuilderLabels.TaxFlags]: <TaxFlags />,
	[BenefitBuilderLabels.Scheme]: <Scheme />,
	[BenefitBuilderLabels.Documents]: <Documents />,
	[BenefitBuilderLabels.MapFields]: <MapFields />,
	[BenefitBuilderLabels.Icon]: <BenefitIcon />,
	[BenefitBuilderLabels.EmployerMatch]: <EmployerMatch />,
	[BenefitBuilderLabels.LookupTable]: <LookupTable />,
	[BenefitBuilderLabels.AllowedActions]: <AllowedActions />,
	[BenefitBuilderLabels.BenefitIntegration]: <BenefitIntegration />,
	[BenefitBuilderLabels.SelectionAdditionalData]: <SelectionAdditionalData />,
	[BenefitBuilderLabels.Options]: <Options />,
	[BenefitBuilderLabels.OptionEligibility]: <OptionEligibility />,
	[BenefitBuilderLabels.BenefitEligibility]: <BenefitEligibility />,
}

const FieldValues = {
	[FieldTypes.RadioButtonsList]: (defaultValues) => {
		return {
			...getRadioButtonsFromOptions(defaultValues.options),
		}
	},
	[FieldTypes.Dropdown]: (defaultValues) => {
		const value = defaultValues.value
			? defaultValues.value
			: defaultValues.options.find((option) => option.default)

		return value ? buildDropdownOption(value.name, value.id) : null
	},
	[FieldTypes.Text]: (defaultValues) => {
		const value = defaultValues.value

		return value ? value : ''
	},
	[FieldTypes.Number]: (defaultValues) => {
		return defaultValues.value
	},
	[FieldTypes.Date]: (defaultValues) => {
		return defaultValues.value
	},
	[FieldTypes.Checkbox]: (defaultValues) => {
		return {
			id: defaultValues?.field_id,
			label: defaultValues?.label,
			selected: Boolean(defaultValues.value)
		}
	},
	[FieldTypes.Editor]: (defaultValues) => {
		const value = defaultValues.value

		return value ? value : ''
	},
	[FieldTypes.File]: (defaultValues) => {
		const value = defaultValues.value

		return value ? value : []
	},
}

export const findBenefitComponentInSection = (sections, selectedLabel) => {
	return sections.find((sectionItem) => {
		return Boolean(findBenefitComponent(sectionItem.content, selectedLabel))
	})
}

export const findBenefitComponent = (benefitComponents, selectedLabel) => {
	return benefitComponents.find((benefitComponent) => {
		return benefitComponent.label === selectedLabel
	})
}

export const getFieldsMap = (fieldsArray) => {
	return fieldsArray.map((field) => {
		return {
			[field.field_id]: { ...field },
		}
	})
}

export const prepareFieldsData = (content) => {
	return content.reduce((columns, column, index) => {
		const fieldsMap = getFieldsMap(column.fields).reduce((fields, field) => {
			const [key] = Object.keys(field)

			return {
				...fields,
				[key]: field[key],
			}
		}, {})

		const fieldValues = Object.keys(fieldsMap).reduce((values, fieldKey) => {
			const fieldMapValues = fieldsMap[fieldKey]
			const type = fieldMapValues.type

			if (!FieldValues.hasOwnProperty(type)) {
				return {
					...values
				}
			}

			return {
				...values,
				[fieldKey]: FieldValues[type](fieldMapValues)
			}
		}, {})

		return {
			...columns,
			[index]: {
				fields: fieldsMap,
				values: fieldValues
			}
		}
	}, {})
}

export const getDefaultValue = (field, values) => {
	if (field?.defaultValue) {
		return field.defaultValue
	}

	if (values) {
		return values[field.field_id]
	}
}

export const getUpdatedSteps = (steps) => {
	return steps.map((step, index) => {
		return {
			...step,
			index: index + 1,
			component: BenefitSteps[step.label],
		}
	})
}

export const getSteps = (data) => {
	return data.flatMap((item) => {
		return item.content.filter(({ isMenu }) => !isMenu)
	})
}

export const getLeftMenuItems = (data) => {
	return data.map(({ label, content, meta }) => {
		return {
			label,
			content: content.filter(({ isMenu }) => isMenu),
			meta
		}
	})
}

export const getSelectedStep = (data, item) => {
	return data.flatMap(({ content }) => {
		return content.filter(({ label }) => label === item.label)
	})[0]
}

export const getUpdatedData = (data, item, isAdding) => {
	return data.map((sectionItem) => {
		return {
			label: sectionItem.label,
			content: sectionItem.content.map((contentItem) => {
				if (contentItem.label === item.label) {
					return {
						...contentItem,
						isMenu: isAdding,
					}
				}

				return contentItem
			}),
			meta: sectionItem.meta
		}
	})
}

export const getValidatedData = (data, isDisabled) => {
	return data.map((sectionItem) => {
		return {
			label: sectionItem.label,
			content: sectionItem.content.map((contentItem) => {
				return {
					...contentItem,
					isDisabled
				}
			}),
			meta: sectionItem.meta
		}
	})
}

export const getPreparedData = (data) => {
	const preparedFields = Object.keys(data).reduce((steps, stepKey) => {
		return {
			...steps,
			[BenefitBuilderKeys[stepKey]]: getPreparedFields(data[stepKey].columns),
		}
	}, {})

	const steps = Object.keys(preparedFields)

	return steps.reduce((newSteps, stepKey) => {
		const step = preparedFields[stepKey]
		const stepFieldKeys = Object.keys(step)

		if (!hasStepArrayField(stepFieldKeys)) {
			return {
				...newSteps,
				[stepKey]: preparedFields[stepKey],
			}
		}

		const transformArrayToObject = (fieldKey) => {
			if (!fieldKey) {
				return {}
			}

			return {
				[getClearedFieldKey(fieldKey)]: step[fieldKey],
			}
		}

		const transformedArray = stepFieldKeys.reduce((duplicates, _, index) => {
			const arrayFieldKey = `[${index}]`
			const duplicateFields = stepFieldKeys
				.filter((fieldKey) => fieldKey.includes(arrayFieldKey))
				.reduce((duplicateField, fieldKey) => {
					return {
						...duplicateField,
						...transformArrayToObject(fieldKey),
					}
				}, {})

			return  [
				...duplicates,
				duplicateFields
			].filter((object) => !isObjectEmpty(object))
		}, [])

		return {
			...newSteps,
			[stepKey]: transformedArray,
		}
	}, {})
}

export const getTableData = (tables) => {
	return Object.entries(tables).reduce((rows, [key, value]) => {
		return {
			...rows,
			[key]: value?.newRows
		}
	}, {})
}

export const getButton = ({
	label,
	icon,
	handleOnClick,
	options,
	dataTestId
}) => {
	return (
		<Button
			key={label}
			label={label}
			icon={Icons[icon]}
			size={ButtonSizes.Small}
			type={ButtonTypes.Edit}
			handleOnClick={() => handleOnClick(options)}
			dataTestId={dataTestId}
		/>
	)
}

export const getRadioButtonsFromOptions = (options) => {
	return options.reduce((fields, field) => {
		return {
			...fields,
			[field.field_id]: field.options.find((btn) => btn.selected).field_id
		}
	}, {})
}

const getUpdatedCurrentOption = (rowOption, value, { field_id}) => {
	return rowOption.options.map((updatedRowOption) => {
		if (!value) {
			return {
				...updatedRowOption,
			}
		}

		return {
			...updatedRowOption,
			selected: value[rowOption.field_id] === field_id
		}
	}).find((option) => option.field_id === field_id)
}

export const getPreparedRadioButtons = (rowOption, value) => {
	return rowOption.options.map((currentOption) => {
		return {
			...currentOption,
			...getUpdatedCurrentOption(rowOption, value, currentOption)
		}
	})
}

export const getRadioButtonsList = (options, value) => {
	return  options.reduce((newOptions, rowOption) => {
		return [
			...newOptions,
			{
				name: rowOption.label,
				fieldId: rowOption.field_id,
				elements: [
					{
						radioButtons: getPreparedRadioButtons(rowOption, value)
					}
				],
			},
		]
	}, [])
}

const getPreparedFields = (columns) => {
	return Object.values(columns).reduce((fields, column) => {
		const columnValues = column.values || {}

		return {
			...fields,
			...Object.keys(column.fields).reduce((values, fieldKey) => {
				return {
					...values,
					[fieldKey]: getPreparedField(
						columnValues[fieldKey]
					),
				}
			}, {}),
		}
	}, {})
}

const getPreparedField = (fieldValue) => {
	if (fieldValue instanceof Date) {
		return prepareDate(fieldValue)
	}

	if (Boolean(fieldValue?.label) && Boolean(fieldValue?.value)) {
		return fieldValue.value
	}

	if (Boolean(fieldValue) && fieldValue.hasOwnProperty('selected')) {
		return Boolean(fieldValue.selected)
	}

	if (!fieldValue) {
		return null
	}

	return fieldValue
}

export const getDuplicatedColumns = (columns) => {
	return {
		...Object.keys(columns).reduce((newColumns, columnKey, index) => {
			const { fields } = columns[columnKey]
			const { length } = Object.keys(fields)
			const [keyToDuplicate] = Object.keys(fields)
			const [valueToDuplicate] = Object.values(fields)
			const newFieldId = getUniqKey(keyToDuplicate, length)

			const newColumn = {
				...columns[columnKey],
				fields: {
					...fields,
					[newFieldId]: {
						...valueToDuplicate,
						field_id: newFieldId,
					},
				},
			}

			return {
				...newColumns,
				[index]: {
					...newColumn,
				},
			}
		}, {}),
	}
}

export const getButtonComponents = (buttons, handleOnClick) => {
	if (isEmpty(buttons)) {
		return []
	}

	return buttons.map((button) => {
		const options = {
			...button
		}

		return (
			<div className="benefit-form-duplicate-button" key={button.label}>
				{getButton({
					label: button.label,
					icon: button.icon,
					handleOnClick,
					options,
					dataTestId: button.label
				})}
			</div>
		)
	})
}

const hasStepArrayField = (stepFieldKeys) => {
	return stepFieldKeys.some((key) => key.includes('['))
}

const getClearedFieldKey = (key) => {
	if (!key) {
		return null
	}

	return key.split('[')[0]
}

const getUniqKey = (keyToDuplicate, index) => {
	const [firstPart] = keyToDuplicate.split('[')
	const lastPart = `[${index}]`

	return [...firstPart, ...lastPart].toString().replace(/[,]/g, '')
}

export const getTableName = (columns) => {
	if (columns) {
		return Object.keys(columns).reduce((acc, columnIndex) => {
			const columnFields = Object.keys(columns[columnIndex].fields);
			return columnFields.find(item => {
				return columns[columnIndex].fields[item].type === FieldTypes.Table
			})
		}, "")
	}
};

export const getTableId = ({ columns, tableName }) => {
	if (columns) {
		return Object.keys(columns).reduce((acc, columnIndex) => {
			return columns[columnIndex].fields?.[tableName]?.table?.table_id
		}, "")
	}
};

export const getPreparedAdditionalTitle = (rowData) => {
	if (!rowData || isObjectEmpty(rowData)) {
		return;
	}

	return [
		`ID: ${rowData.id}`,
		`Internal name: ${rowData.internal_name}`,
		`Company: ${rowData.company_name}`,
		`Status: ${rowData.status}`
	];
}

export const getPreparedBadges = (badgesData) => {
	if (!badgesData || isObjectEmpty(badgesData)) {
		return;
	}

	return Object.values(badgesData).map(item => parse(item.toString()));
}

export const getPreparedEditedRow = ({ tableData, newRowFields, editRowData }) => {
	const editedRow = tableData.rows?.find(row => row.id === tableData.editRowId)

	return Object.keys(editedRow).reduce((row, fieldIndex) => {
		if (newRowFields && Object.keys(newRowFields).find(item => item === fieldIndex)) {
			row[fieldIndex] = newRowFields[fieldIndex]
		} else {
			const rowFieldId = Object.keys(editRowData).find(item => editRowData[item]?.field_id === fieldIndex);
			row[fieldIndex] = editRowData[rowFieldId]?.value?.id ?
				JSON.parse(editRowData[rowFieldId]?.value?.id) : editRowData[rowFieldId]?.value;
		}
		return row;
	}, {})
}

export const getIndexFromDuplicatedField = (fieldKey) => {
	const [_, lastPart] = fieldKey.split('[')
	const [index] = lastPart.split(']')

	return Number(index)
}

export const getBuiltDuplicatedProperty = (
	fieldKeys,
	propertyData,
	removedIndex,
	duplicatesCount,
	isFieldProperty
) => {
	return fieldKeys.reduce((keys, fieldKey) => {
		const fieldIndex = getIndexFromDuplicatedField(fieldKey)

		const [fieldName] = fieldKey.split('[')

		if (fieldIndex < removedIndex) {
			return {
				...keys,
				[fieldKey]: propertyData[fieldKey],
			}
		}

		if (fieldIndex === duplicatesCount - 1) {
			return {
				...keys,
			}
		}

		const propertyValue = propertyData[`${fieldName}[${fieldIndex + 1}]`]
		const updatedValue = isFieldProperty
			? {
				...propertyValue,
				field_id: `${fieldName}[${fieldIndex}]`,
			  }
			: propertyValue

		return {
			...keys,
			[fieldKey]: updatedValue
		}
	}, {})
}

export const getExcludedDropdownOptions = (fields, values) => {
	const dropdownFieldIds = Object.keys(fields).filter((key) => fields[key].type === FieldTypes.Dropdown)

	return Object.keys(values).reduce((options, valueKey) => {
		if (dropdownFieldIds.some((id) => id === valueKey) && Boolean(values[valueKey])) {
			return [...options, values[valueKey]]
		}

		return [...options]
	}, [])
}

export const hasAvailableOptions = (columns) => {
	return Object.values(columns).reduce((hasAvailableOptions, { fields, values }) => {
		if (hasAvailableOptions) {
			const existingFieldsOptions = getExcludedDropdownOptions(fields, fields)

			if (existingFieldsOptions.length === 0) {
				return true
			}

			const excludedOptions = getExcludedDropdownOptions(fields, values)
			const [{ options: availableOptions }] = existingFieldsOptions

			if (availableOptions.length === excludedOptions.length && excludedOptions.length !== 0) {
				return false
			}
		}

		return hasAvailableOptions
	}, true)
}
