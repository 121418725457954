import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './CollapsedMenu.css';
import { Button } from 'components/buttons/Button/Button';
import { ButtonTypes } from 'utils/constants';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

const Item = ({ item, onClick, dataTestId, rowData, rowId}) => {
    const handleClick = () => {
        onClick()
        item?.onClick(rowId)
    }

    return (
        <div
            key={item?.label}
            className='collapsed-menu-item'
            onClick={handleClick}
            data-testid={`${dataTestId}-menu-item-${item?.label}`}
        >
            {
                item?.icon &&
                <span className='collapsed-menu-icon'>{item?.icon()}</span>
            }
            {item?.label}
        </div>
    )
}

export const CollapsedMenu = ({ items, type, buttonIcon, dataTestId, rowData, rowId }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [target, setTarget] = useState();
    const ref = useRef();

    const handleMenuVisibility = (event) => {
        if (event?.target === target) {
            setTarget(undefined);
           return;
        }
        setIsVisible(!isVisible);
    };

    const handleClick = () => {
        setIsVisible(false)
    };

    useOnClickOutside(ref, (event) => {
        setTarget(event.target);
        setIsVisible(false)
    });

    return (
        <>
            <Button
                handleOnClick={handleMenuVisibility}
                type={type}
                icon={buttonIcon}
                dataTestId={`${dataTestId}-menu`}
            />
            {
                isVisible ?
                    <div ref={ref} className='collapsed-menu-items-container'>
                        {
                            items?.map(item => {
                                return (
                                    <Item
                                        key={item?.label}
                                        item={item}
                                        onClick={handleClick}
                                        rowData={rowData}
                                        rowId={rowId}
                                        dataTestId={dataTestId}
                                    />
                                )
                            })
                        }
                    </div>
                    : null
            }
        </>
    )
};

CollapsedMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
        onClick: PropTypes.func
    })),
    dataTestId: PropTypes.string,
    buttonIcon: PropTypes.func.isRequired,
    rowData: PropTypes.object,
    rowId: PropTypes.number,
    type: PropTypes.string
}

CollapsedMenu.defaultProps = {
    type: ButtonTypes.Menu
}