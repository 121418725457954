export const buildDropdownOption = (label, value, isRequired) => {
  label = isRequired ? `${label} *` : label;

  return {
    label,
    value
  }
}

export const isStringIncludes = (str1, str2, isLowerCase) => {
  if (isLowerCase) {
    return str1?.toLowerCase().includes(str2?.toLowerCase())
  }

  if (str1 === str2) {
    return true
  }

  return str1?.includes(str2)
}

export const isOptionEquals = (option1, option2) => {
  return isStringIncludes(option1.label, option2.label)
    && isStringIncludes(option1.additional, option2.additional)
}

export const isOptionContains = (option, value) => {
  if (!value) {
    return true;
  }

  const {label, additional} = option;

  return isStringIncludes(label, value, true)
    || isStringIncludes(additional, value, true)
}

export const isOptionExcluded = (excludedOptions, option) => {
  return Boolean(excludedOptions?.length)
    && excludedOptions.some((excludedOption) => excludedOption && isOptionEquals(excludedOption, option))
}
