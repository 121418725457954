import React from 'react'
import { Provider } from 'react-redux'
import { init } from '@rematch/core'
import * as models from '../store'
import {injectStore} from 'common/interceptors';

const store = init({
    models,
    redux: {
        devtoolOptions: {},
        rootReducers: { RESET_APP: () => undefined }
    }
})

injectStore(store);

// eslint-disable-next-line react/prop-types
export default ({ element }) => <Provider store={store}>{element}</Provider>
