import React from 'react'
import './BenefitFormStepContent.css'
import PropTypes from 'prop-types'
import { FormColumn } from 'components/formFields/FormColumn/FormColumn'
import { BenefitFormColumn } from 'components/benefit/BenefitFormColumn/BenefitFormColumn'
import { FormColumnSizes } from 'utils/constants'
import { getBuiltDuplicatedProperty, getIndexFromDuplicatedField } from 'utils/helpers/benefitBuilder.helper'
import { useDispatch, useSelector } from 'react-redux';
import { benefitBuilder } from 'store/selectors/benefitBuilder';

export const BenefitFormStepContent = ({
	label,
	columns,
	errors,
	columnSize,
	fileUploadMessage,
	isMultipleUnique,
	control,
	register,
	onInnerButtonClick,
	onStepChanged,
	errorAsHint
}) => {
	const dispatch = useDispatch();
	const isError = useSelector(benefitBuilder.isError)
	const columnValues = Object.values(columns)

	const handleColumnChange = (index, column) => {
		onStepChanged(label, {
			columns: {
				...columns,
				[index]: {
					...columns[index],
					...column[index],
				},
			},
		})
	}

	const handleFieldRemove = async ({ field }) => {
		const removedIndex = getIndexFromDuplicatedField(field)

		await dispatch.benefitBuilder.deleteDuplicatedField({ stepLabel: label, removedIndex })

		if (!isError) {
			const updatedColumns = columnValues.reduce((newColumns, column, index) => {
				const fieldsKeys = Object.keys(column.fields)
				const duplicatesCount = fieldsKeys.length

				const newFields = getBuiltDuplicatedProperty(
					fieldsKeys,
					column.fields,
					removedIndex,
					duplicatesCount,
					true
				)
				const newValues = getBuiltDuplicatedProperty(
					Object.keys(newFields),
					column.values,
					removedIndex,
					duplicatesCount,
					false
				)

				return {
					...newColumns,
					[index]: {
						fields: newFields,
						values: newValues,
					},
				}
			}, {})

			onStepChanged(label, {
				columns: updatedColumns
			})
		}
	}

	return (
		<div className="benefit-form-step-content">
			{columnValues.map((column, index) => {
				const countDuplicatedRows = Object.keys(column.fields).reduce((count, fieldKey) => {
					return fieldKey.includes(`[`) ? count + 1 : count
				}, 0)
	
				const hasDuplicatedRows = countDuplicatedRows > 1
				const isLastColumn = index === columnValues.length - 1;
				const updatedColumnSize = hasDuplicatedRows ? FormColumnSizes.Duplicate : columnSize

				return (
					<FormColumn key={index} size={updatedColumnSize} >
						<BenefitFormColumn
							index={index}
							fieldSize={updatedColumnSize}
							errors={errors}
							fileUploadMessage={fileUploadMessage}
							isMultipleUnique={isMultipleUnique}
							fields={column.fields}
							values={column?.values}
							control={control}
							register={register}
							hasDuplicatedRows={hasDuplicatedRows}
							isLastColumn={isLastColumn}
							handleFieldRemove={handleFieldRemove}
							onInnerButtonClick={onInnerButtonClick}
							onColumnChanged={handleColumnChange}
							dataTestId={`${label}-column-${index}`}
							errorAsHint={errorAsHint}
						/>
					</FormColumn>
				)
			})}
		</div>
	)
}

BenefitFormStepContent.propTypes = {
	label: PropTypes.string,
	columns: PropTypes.object,
	control: PropTypes.object,
	register: PropTypes.func,
	fileUploadMessage: PropTypes.string,
	columnSize: PropTypes.string,
	onStepChanged: PropTypes.func.isRequired,
	onInnerButtonClick: PropTypes.func,
	errorAsHint: PropTypes.bool,
}

BenefitFormStepContent.defaultProps = {
	columnSize: FormColumnSizes.Multi
}