import React from 'react'
import PropTypes from 'prop-types'
import { getDefaultValue, getExcludedDropdownOptions } from 'utils/helpers/benefitBuilder.helper'
import { BenefitFormField } from 'components/benefit/BenefitFormField/BenefitFormField'

export const BenefitFormColumn = ({
	index,
	fields,
	errors,
	values,
	control,
	register,
	fieldSize,
	fileUploadMessage,
	isMultipleUnique,
	hasDuplicatedRows,
	isLastColumn,
	onInnerButtonClick,
	onColumnChanged,
	handleFieldRemove,
    dataTestId,
	errorAsHint
}) => {

	const handleFieldChange = (field) => {
		onColumnChanged(index, {
			[index]: {
				fields: { ...fields },
				values: {
					...values,
					...field
				}
			}
		})
	}

	const excludedOptions = isMultipleUnique
		? getExcludedDropdownOptions(fields, values)
		: []

	return (
		Object.values(fields).map((field) => {
			const fieldId = field.field_id
			const fieldErrors = errors ? errors[fieldId] : undefined

			return (
				<BenefitFormField
					key={`${fieldId}`}
					field={field}
					fieldErrors={fieldErrors}
					fieldSize={fieldSize}
					fileUploadMessage={fileUploadMessage}
					value={getDefaultValue(field, values)}
					excludedOptions={excludedOptions}
					control={control}
					register={register}
					hasDuplicatedRows={hasDuplicatedRows}
					isLastColumn={isLastColumn}
					onInnerButtonClick={onInnerButtonClick}
					onFieldChanged={handleFieldChange}
					onFieldRemove={handleFieldRemove}
					dataTestId={dataTestId}
					errorAsHint={errorAsHint}
				/>
			)
		})
	)
}

BenefitFormColumn.propTypes = {
	index: PropTypes.number.isRequired,
	fields: PropTypes.objectOf(PropTypes.shape({
		label: PropTypes.string,
		placeholder: PropTypes.string,
		field_id: PropTypes.string,
		options: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			name: PropTypes.string
		})),
		required: PropTypes.bool,
		type: PropTypes.string,
		max: PropTypes.number,
		min: PropTypes.number
	})),
	values: PropTypes.object,
	fileUploadMessage: PropTypes.string,
	control: PropTypes.object.isRequired,
	register: PropTypes.func.isRequired,
	onColumnChanged: PropTypes.func.isRequired,
	dataTestId: PropTypes.string,
	onInnerButtonClick: PropTypes.func,
	errorAsHint: PropTypes.bool,
}