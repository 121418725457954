import React, {useEffect, useState} from 'react';
import './TableHeaderItem.css';
import PropTypes from 'prop-types';
import { SORTING_ALT } from 'utils/messages';
import sortingNeutral from 'icons/sorting_neutral.svg';
import sortingDescending from 'icons/sorting_descending.svg';
import sortingAscending from 'icons/sorting_ascending.svg';
import { sortingDirections } from 'utils/constants';

const sortingIcons = {
    [sortingDirections.NONE]: sortingNeutral,
    [sortingDirections.UP]: sortingAscending,
    [sortingDirections.DOWN]: sortingDescending
};

export const TableHeaderItem = ({
    value,
    name,
    sorting,
    isRequired,
    onSort,
    params,
    dataTestId
}) => {
    const [direction, setDirection] = useState(sortingDirections.NONE);

    useEffect(() => {
        setDirection(params?.sortingColumn === name ? params?.sortingDirection : sortingDirections.NONE)
    }, [params?.sortingColumn]);

    const handleClick = () => {
        if (sorting && onSort) {
            onSort({
                field: name,
                direction: direction !== sortingDirections.DOWN ? sortingDirections.DOWN : sortingDirections.UP
            });
        }
    };

    return (
        <div
            className='table-header-item'
            onClick={handleClick}
            data-testid={`${dataTestId}-header-${value}`}
        >
            {`${value}${isRequired ? '*' : ''}`}
            {
                sorting ?
                    <img
                        alt={SORTING_ALT}
                        src={sortingIcons[direction]}
                        data-testid={`${dataTestId}-sorting-icon-${direction}`}
                    />
                    : null
            }
        </div>
    )
}

TableHeaderItem.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sorting: PropTypes.bool,
    isRequired: PropTypes.bool,
    params: PropTypes.object,
    onClick: PropTypes.func,
    dataTestId: PropTypes.string
}
