import {ButtonLabels, FieldTypes} from "utils/constants";
import {isObjectEmpty} from "utils/helpers/common.helper";

export const camelize = (string) => {
    return string
        .split(' ')
        .map(
            (word, index) => index === 0 ?
                word[0].toLowerCase() + word.slice(1)
                : word[0].toUpperCase() + word.slice(1)
        )
        .join('');
}

export const removeSpec = (string) => {
    return string.replace(/(?!\w|\s)./g, '')
        .replace(/\s+/g, ' ')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
}

export const getErrors = ({ errors }) => {
    if (!errors || isObjectEmpty(errors)) {
        return {}
    }
    return Object.keys(errors).reduce((acc, objectIndex) => {
            return {...acc, ...errors[objectIndex]}
        }, {})
}

export const getRowDataWithNewValue = ({ newValue, data }) => {
    if ((newValue || typeof newValue === 'boolean') && data?.type === FieldTypes.Dropdown) {
        return {
            ...data,
            value: data?.options.find(item => JSON.parse(item?.id) === JSON.parse(newValue))
        }
    }
    if (newValue) {
        return {
            ...data,
            value: newValue
        }
    }
    return data;
}

export const PaginationEnum = {
    [ButtonLabels.FirstPage]: () => 1,
    [ButtonLabels.PreviousPage]: (item) => item.currentPage - 1,
    [ButtonLabels.NextPage]: (item) => item.currentPage + 1,
    [ButtonLabels.LastPage]: (item) => item.lastPage
};