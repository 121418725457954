import React from 'react';
import './TableRowMenu.css';
import PropTypes from 'prop-types';
import { CollapsedRowMenu } from 'components/global/Table2/TableRowMenu/CollapsedRowMenu';
import { ExpandedRowMenu } from 'components/global/Table2/TableRowMenu/ExpandedRowMenu';

export const TableRowMenu = ({ items, isCollapsed, dataTestId, rowData, rowId }) => {
    return isCollapsed ?
        <CollapsedRowMenu items={items} dataTestId={dataTestId} rowData={rowData} rowId={rowId} />
        : <ExpandedRowMenu items={items} dataTestId={dataTestId} rowData={rowData} />
}

TableRowMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.func,
        onClick: PropTypes.func
    })),
    isCollapsed: PropTypes.bool,
    dataTestId: PropTypes.string,
    rowId: PropTypes.number,
    rowData: PropTypes.object
}