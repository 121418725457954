import React from 'react'
import { FormField } from './FormField/FormField'
import { BenefitRowsList } from 'components/benefit/BenefitRowsList/BenefitRowsList'
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const Container = styled.div`
    ${tw`w-full flex flex-col justify-between`}
`

export const FormRadioButtonsList = ({
	options,
	value,
	isRequired,
	fieldName,
	onChange,
	dataTestId
}) => {
	return (
		<FormField isRequired={isRequired}>
			<Container>
				<BenefitRowsList
					content={options}
					visibleEntriesAmount={options.length}
					value={value}
					onChange={onChange}
					dataTestId={dataTestId}
				/>
			</Container>
		</FormField>
	)
}
