const defaultState = {
	backLink: '',
	endpoint: '',
	viewId: undefined,
	header: undefined,
	contextType: undefined
}

export default {
	state: defaultState,
	reducers: {
		clearStore() {
			return defaultState
		},

		auditData(state, { data }) {
			return {
				...state,
				...data,
			}
		},
	},

	effects: () => ({
		setAuditData({ data }) {
			this.auditData({ data })
		},

		setClearStore() {
			this.clearStore()
		}
	}),
}
