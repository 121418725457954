import React from 'react';
import './TableHeader.css';
import { TableHeaderItem } from 'components/global/Table2/TableHeaderItem/TableHeaderItem';
import PropTypes from 'prop-types';

export const TableHeader = ({ columns, params, onSort, dataTestId }) => {
  return (
        <div className='table-header' data-testid={`${dataTestId}-header`}>
            {
                columns?.map(item => {
                    return (
                        <TableHeaderItem
                            key={item.name}
                            value={item.value}
                            name={item.name}
                            sorting={item.sorting}
                            isRequired={item.required}
                            onSort={onSort}
                            params={params}
                            dataTestId={dataTestId}
                        />
                    )
                })
            }
        </div>
  )
};

TableHeader.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        sorting: PropTypes.bool,
        required: PropTypes.bool
    })),
    params: PropTypes.object,
    onClick: PropTypes.func,
    dataTestId: PropTypes.string
}
