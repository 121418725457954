export default {
	state: {
		isLoading: false,
		isUserInfoSending: false,
		isMFASending: false,
		isUserPasswordSending: false
	},
	reducers: {
		isLoading(state, { isLoading }) {
			return {
				...state,
				isLoading,
			}
		},
		isUserInfoSending(state, { isSending }) {
			return {
				...state,
				isUserInfoSending: isSending,
			}
		},
		isMFASending(state, { isSending }) {
			return {
				...state,
				isMFASending: isSending,
			}
		},
		isUserPasswordSending(state, { isSending }) {
			return {
				...state,
				isUserPasswordSending: isSending,
			}
		}
	},
	effects: () => ({
		setIsLoading({ isLoading }) {
			this.isLoading({ isLoading })
		},
		setIsUserInfoSending({ isSending }) {
			this.isUserInfoSending({ isSending })
		},
		setIsMFASending({ isSending }) {
			this.isMFASending({ isSending })
		},
		setIsUserPasswordSending({ isSending }) {
			this.isUserPasswordSending({ isSending })
		}
	}),
}
