import React from 'react';
import PropTypes from 'prop-types';
import './ContentHyperlink.css';

export const ContentHyperlink = ({ content, dataTestId, renderAdditionalIcon }) => {
    return content ? (
        <div className='content-hyperlink-container'>
            <a
                className='content-hyperlink'
                href={content?.value}
                target='_blank'
                rel='noopener noreferrer'
                data-testid={`${dataTestId}-content-hyperlink`}
            >
                {content?.label ? content?.label : content?.value}
            </a>
            {renderAdditionalIcon && renderAdditionalIcon(content?.value)}
        </div>
    ) : null;
};

ContentHyperlink.propTypes = {
    content: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string
    }),
    dataTestId: PropTypes.string,
    renderAdditionalIcon: PropTypes.func,
}