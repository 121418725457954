import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	BenefitBuilderLabels,
	getButtonComponents,
	getTableId,
	getTableName,
	prepareFieldsData
} from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_MAP_FIELDS, CREATE_BENEFIT_ROW } from 'common/endpoints'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import {useDispatch, useSelector} from "react-redux";
import {benefitBuilder} from "store/selectors/benefitBuilder";
import {FormColumnSizes} from "utils/constants";

export const MapFields = ({ data, onChange }) => {
	const dispatch = useDispatch()
	const [state, setState] = useState()
	const { register, control } = useForm()
	const benefitID = useSelector(benefitBuilder.benefit)?.id
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_MAP_FIELDS(benefitBuilderContextAPI))
				const [{ content, label, description, header_buttons }] = response.data.steps;

				const columns = prepareFieldsData(content)

				setState({ columns, label, description, header_buttons })

				onChange(label, {
					label,
					columns,
					description,
                    header_buttons,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.LookupTable, {
					columns: [],
					header_buttons: [],
					isLoaded: true
				})
			}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleAddNewRow = async (endpoint) => {
		const tableName = getTableName(state?.columns)
		const tableId = getTableId({ columns: state?.columns, tableName })

		dispatch.tables.setAddRowData({
			tableName,
			endpoint,
			saveEndpoint: CREATE_BENEFIT_ROW(benefitID, tableId),
            params: {
                benefitID: benefitID
            }
		});
	}

	const headerButtons = getButtonComponents(state?.header_buttons, (options) =>
		handleAddNewRow(options.endpoint)
	)

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			headerButtons={headerButtons}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
			columnSize={FormColumnSizes.Single}
			errors={benefitBuilderErrors}
			description={state?.description}
		/>
	)
}

MapFields.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
