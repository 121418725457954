import React from 'react';
import { PAGE_STATISTICS } from '../../../utils/messages';
import PropTypes from 'prop-types';

const PageStatistics = ({ totalEntries, fromEntry, toEntry }) => {
    return (
        <div className="text-gray" data-testid="page-statistics">
            {PAGE_STATISTICS({totalEntries, fromEntry, toEntry})}
        </div>
    );
};

export default PageStatistics;

PageStatistics.propTypes = {
    totalEntries: PropTypes.number,
    fromEntry: PropTypes.number,
    toEntry: PropTypes.number,
}