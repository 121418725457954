import React from 'react';
import PropTypes from 'prop-types';
import './TableRowMenu.css';

const RowItem = ({ item, index, dataTestId, rowData }) => {
    return (
        <div
            className='table-row-menu-item-expanded'
            onClick={() => item?.onClick(rowData)}
            data-testid={`${dataTestId}-row-menu-item-${index}`}
        >
        {
            typeof item?.icon === 'function'
                ? <span className={`table-row-menu-icon ${item?.color}`}>{item?.icon()}</span>
                : <img src={item?.icon} alt='menu item' className={`table-row-menu-icon ${item?.color}`} />
        }
        </div>
    )
}

export const ExpandedRowMenu = ({ items, dataTestId, rowData }) => {
    return (
        <div className='table-row-menu-container-expanded'>
            {
                items?.map((item, index) => {
                    return (
                        <RowItem
                            key={index}
                            index={index}
                            item={item}
                            rowData={rowData}
                            dataTestId={dataTestId}
                        />
                    )
                })
            }
        </div>
    )
};

ExpandedRowMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        onClick: PropTypes.func
    })),
    dataTestId: PropTypes.string,
    rowData: PropTypes.object
}