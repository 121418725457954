import React from 'react'
import './BenefitFormStepDescription.css'
import PropTypes from 'prop-types'
import { FormColumn } from 'components/formFields/FormColumn/FormColumn'
import {FormColumnSizes} from "utils/constants";
import parse from "html-react-parser";

export const BenefitFormStepDescription = ({ description, columnSize }) => {
	if (!description) {
		return null
	}

	return (
		<div className="benefit-form-step-description">
			<FormColumn size={columnSize}>
        <span>{parse(description)}</span>
			</FormColumn>
		</div>
	)
}

BenefitFormStepDescription.propTypes = {
	description: PropTypes.string,
	columnSize: PropTypes.string
}

BenefitFormStepDescription.defaultProps = {
	columnSize: FormColumnSizes.Single
}
