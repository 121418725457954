import React from 'react'
import './BenefitFormStepHeaderButtons.css'
import PropTypes from 'prop-types'

export const BenefitFormStepHeaderButtons = ({ headerButtons }) => {
	return (
		<div className="benefit-form-step-buttons">
			{headerButtons.map((button) => button)}
		</div>
	)
}

BenefitFormStepHeaderButtons.propTypes = {
	buttons: PropTypes.array,
}
