import React, {useEffect, useState} from 'react'
import { FormField } from './FormField/FormField'
import {isNotEmpty} from "utils/helpers/array.helper";

export const FormRadioButtons = ({
	label,
	options,
	isRequired,
	fieldName,
	onChange,
	dataTestId
}) => {
	const [buttons, setButtons] = useState()

	useEffect(() => {
		if (options) {
			setButtons(options)
		}
	}, [])

	const handleOnChange = (label) => {
		const newRadioButtons = buttons.map((radioButton) => {
			return {
				...radioButton,
				selected: radioButton.label === label,
			}
		})

		setButtons(newRadioButtons)
		onChange(newRadioButtons.find((button) => button.selected).field_id)
	}

	return (
		<FormField isRequired={isRequired} label={label}>
			{
				isNotEmpty(buttons) &&
				buttons.map((radioButton) => {
					const label = radioButton.label

					return (
						<div key={label} className='radio-button-container'>
							<input
								id={fieldName}
								className="dashboard-item-radio-input"
								type="radio"
								checked={radioButton.selected}
								onChange={() => handleOnChange(label)}
								data-testid={`${dataTestId}-input`}
							/>
							<label
								htmlFor={fieldName}
								className='radio-button-label'
								data-testid={`${dataTestId}-label`}
							>
								{label}
							</label>
						</div>
					)
				})
			}
		</FormField>
	)
}
