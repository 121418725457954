import React from 'react'
import './Hint.css'
import PropTypes from 'prop-types'

export const Hint = ({ config }) => {
	let { message, type, icon, withSeparator = false } = config;

	const className = {
		error: { container: 'error-hint-container', icon: 'error-hint-icon' },
		success: { container: 'success-hint-container', icon: 'success-hint-icon' },
		info:  { container: 'info-hint-container',  icon: 'info-hint-icon' },
	}

	if (withSeparator) {
		message = message.split('.')
	}

	return (
		<div className={`hint-wrapper ${className[type]?.container}`}>
			{icon && <span className={className[type]?.icon}>{icon()}</span>
			}
			<div className="message-container">
				{typeof message === 'object'
					? message.map((line, index) => {
						return (
							<>
								{line}
								{index !== message.length - 1 && <br />}
							</>
						)
					})
					: message}
			</div>
		</div>
	)
}

Hint.propTypes = {
	config: PropTypes.shape({
		message: PropTypes.string.isRequired,
		type: PropTypes.oneOf(['error', 'success', 'info']),
		icon: PropTypes.func,
		withSeparator: PropTypes.string,
	}),
}
