export const BEAM_LOGIN = "api/v2/login";
export const GET_USER_TYPES = "api/users/types";
export const GET_USER_PASSWORD = "api/v2/user/current/password";
export const GET_USER_INFO = 'api/v2/user/current/details';
export const RESET_PASSWORD = 'api/v2/forgot-password';
export const CHECK_THE_CURRENT_USER_BIRTH_DATE =
    'api/v2/user/current/verify-date-of-birth';
export const CHECK_THE_PASSWORD_CRITERIA = 'api/v2/password/validate';
export const SET_NEW_PASSWORD = 'api/v2/user/current/set-password';
export const RESET_NEW_PASSWORD = 'api/v2/reset-password';
export const MAGIC_LINK_LOGIN = 'api/v2/login/sso';
export const SET_MFA = (userID) => `api/v2/user/${userID}/mfa`;
export const VALIDATE_MFA = (userID) => `api/v2/user/${userID}/mfa/validate`;

export const SESSION_EXPIRY = 'api/v2/user/current/session/expiry'
export const SESSION_EXTEND = 'api/v2/user/current/session/extend'
export const CHANGE_PASSWORD = 'api/v2/user/current/change-password'

export const GET_ROLES = "api/role";
export const ADD_NEW_ROLE = "api/role";
export const GET_ROLE = "api/role";
export const EDIT_ROLE = "api/role";
export const DELETE_ROLE = (id) => `api/role/${id}`

export const GET_USERS = "api/user/list";
export const ADD_USER = "api/user/create";
export const GET_USER = "api/user";
export const EDIT_USER = "api/user";
export const DELETE_USER = (id) => `api/v2/user/${id}`

export const GET_BENEFITS = "api/v2/benefits";
export const CREATE_BENEFIT = `${GET_BENEFITS}/create`
export const EDIT_BENEFIT = (benefitId) => `${GET_BENEFITS}/${benefitId}/edit`
export const COPY_BENEFIT = (benefitId) => `${GET_BENEFITS}/${benefitId}/copy`
export const PUBLISH_BENEFIT = (benefitId) => `${GET_BENEFITS}/${benefitId}/status `

export const BENEFIT_INFO_STEP = (context) => `${context}?step=benefit_info`
export const TELEPHONE_NUMBER_STEP = (context) => `${context}?step=telephone_number`
export const BENEFIT_TERMS_AND_CONDITIONS_STEP = (context) => `${context}?step=terms_and_conditions`
export const BENEFIT_PROVIDER_STEP = (benefitID) => `api/v2/benefits/${benefitID}/edit?step=provider`
export const BENEFIT_PROVIDER_STEP_NEW = (benefitID) => `api/v2/benefits/${benefitID}/edit?step=provider&type=new`
export const BENEFIT_CREATE_PROVIDER_STEP = (context) => `${context}?step=provider&type=new`
export const BENEFIT_DESCRIPTION_STEP = (context) => `${context}?step=description`
export const BENEFIT_LINKS_STEP = (context) => `${context}?step=link`
export const BENEFIT_CTA_NOTE_STEP = (context) => `${context}?step=cta_note`
export const BENEFIT_FUND_STEP = (context) => `${context}?step=other.fund`
export const BENEFIT_TERM_ENROLMENT_STEP = (context) => `${context}?step=other.term_enrolment`
export const BENEFIT_TAX_FLAGS_SETTINGS_STEP =  (context) => `${context}?step=other.tax_flag`
export const BENEFIT_COVER_AMOUNT_ADJUSTMENT_STEP =  (context) => `${context}?step=costs.pricing_esa_allowance`
export const BENEFIT_NI_UPLIFT_STEP =  (context) => `${context}?step=costs.pricing_ni_uplift`
export const BENEFIT_ALLOWED_ACTIONS =  (context) => `${context}?step=selection_rules.simple_action`
export const BENEFIT_LIFE_EVENTS_STEP =  (context) => `${context}?step=selection_rules.life_event`
export const BENEFIT_PEOPLE_COVERED_STEP = (context) => `${context}?step=selection_choices.people_covered`
export const BENEFIT_LOOKUP_TABLE = (context) => `${context}?step=costs.pricing_lookup`
export const BENEFIT_MAP_FIELDS = (context) => `${context}?step=sso.saml_selection_field`
export const BENEFIT_FAMILY_ELIGIBILITY_STEP = (context) => `${context}?step=eligibility_rules.family_eligibility`
export const BENEFIT_VALUE_LIMITS_STEP = (context, benefitID) => `${context}?step=selection_choices.value_limit&benefitID=${benefitID}`
export const BENEFIT_PARTNER_VALUE_LIMITS_STEP = (context, benefitID) => `${context}?step=selection_choices.partner_value_limit&benefitID=${benefitID}`
export const BENEFIT_SETUP_SUCCESS_MESSAGE_STEP = (context) => `${context}?step=setup_success_message`
export const BENEFIT_CONTRACT_TERM_STEP = (context) => `${context}?step=selection_choices.contract_terms`
export const BENEFIT_AGE_CALCULATION_STEP = (context) => `${context}?step=costs.age_calculation`
export const BENEFIT_DOCUMENTS = (context) => `${context}?step=documents`
export const BENEFIT_OPT_OUT_MESSAGE = (context) => `${context}?step=opt_out_message`
export const EMPLOYER_MATCH = (context) => `${context}?step=costs.employer_match`
export const BENEFIT_ICON = (context) => `${context}?step=icon`
export const BENEFIT_SCHEME = (benefitID) => `api/v2/benefits/${benefitID}/edit?step=scheme`
export const BENEFIT_ADDITIONAL_DATA = (benefitID) => `api/v2/benefits/${benefitID}/edit?step=sso.saml_selection`
export const BENEFIT_INTEGRATION = (context) => `${context}?step=sso.sso_integration`
export const BENEFIT_ELIGIBILITY = (context) => `${context}?step=eligibility_rules.benefit_eligibility`
export const OPTION_ELIGIBILITY = (context) => `${context}?step=eligibility_rules.option_eligibility`
export const BENEFIT_OPTIONS_STEP = (context) => `${context}?step=selection_choices.option`

export const UPLOAD_FILE = 'api/v2/files/upload'
export const REMOVE_FILE = 'api/v2/files/remove'
export const GET_BENEFIT_MENU_ITEMS = `api/v2/benefits/menu`
export const DELETE_DUPLICATED_FIELD = (benefitID, step) => `api/v2/benefits/${benefitID}/delete-step-row?step=${step}`
export const DELETE_BUILDER_STEP = (benefitID, step) => `api/v2/benefits/${benefitID}/delete-step?step=${step}`

export const GET_BENEFIT_BADGES_INFO = (id) => `api/v2/benefits/${id}/edit-info`
export const GET_COMPANIES_LIST = 'api/v2/company/dropdown-list';
export const GET_SCHEME_YEARS = 'api/v2/benefits/schemes/dropdown-list';

export const GET_BENEFIT_ROW = (benefitID, tableId) => `api/v2/benefits/table/row/create?table=${tableId}&benefitID=${benefitID}`
export const CREATE_BENEFIT_ROW = (benefitID, tableId) => `api/v2/benefits/${benefitID}/table/row/create?table=${tableId}`
export const EDIT_BENEFIT_ROW = (benefitID, tableId) => `api/v2/benefits/${benefitID}/table/row/update?table=${tableId}`
export const VIEW_BENEFIT_TABLE = (benefitID, tableId) => `api/v2/benefits/${benefitID}/table?table=${tableId}`
export const GET_BENEFIT_ROW_FIELDS = (benefitID, tableId, rowId) => `api/v2/benefits/${benefitID}/table/row/edit?table=${tableId}&rowId=${rowId}`
export const DELETE_BENEFIT_ROW = (benefitID, tableId) => `api/v2/benefits/${benefitID}/table/row/delete?table=${tableId}`

export const GET_FROALA_TEMPLATES = 'api/v2/placeholders'

export const LOGOUT_OLD_ADMIN = 'api/spirit/logout'
