import React, { useEffect, useState } from 'react'
import './FormDatePicker2.css'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Controller } from 'react-hook-form'
import { FormField } from 'components/formFields/FormField/FormField'
import { DatePickerComponent } from 'components/commonFields/DatePicker/DatePicker'

export const FormDatePicker2 = ({
	label,
	isRequired,
	content,
	control,
	fieldErrors,
	isError,
	fieldName,
	fieldSize,
	placeholder,
	onChange,
	errors = [],
	dataTestId,
}) => {
	const [defaultDate, setDefaultDate] = useState('')

	useEffect(() => {
		if (content) {
			const date = moment(content, 'DD/MM/YYYY')
			const formattedDate = new Date(moment(date).format('MM/DD/YYYY'))
			setDefaultDate(formattedDate)
		}
	}, [content])

	const handleOnChange = (date, onFormFieldChange) => {
		if (onChange) {
			onChange(date)
		}

		if (onFormFieldChange(date)) {
			onFormFieldChange(date)
		}
	}

	return (
		<FormField
			label={label}
			isRequired={isRequired}
			fieldErrors={fieldErrors}
			dataTestId={dataTestId}
			fieldSize={fieldSize}
		>
			<Controller
				control={control}
				name={fieldName}
				defaultValue={content}
				render={({ field: { onChange: onFormFieldChange } }) => (
					<DatePickerComponent
						className={`form-date-picker ${isError ? 'input-error' : ''}`}
						defaultValue={defaultDate || ''}
						placeholderText={placeholder}
						onChange={(date) => handleOnChange(date, onFormFieldChange)}
						dataTestId={dataTestId}
					/>
				)}
			/>
		</FormField>
	)
}

FormDatePicker2.propTypes = {
	control: PropTypes.object,
	placeholder: PropTypes.string,
	fieldName: PropTypes.string.isRequired,
	fieldSize: PropTypes.string,
	label: PropTypes.string.isRequired,
	isRequired: PropTypes.bool,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	errors: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
}

FormDatePicker2.defaultProps = {
	placeholder: 'dd/mm/yyyy',
}
