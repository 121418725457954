import React from 'react';
import {AsyncDropdown} from "components/commonFields/AsyncDropdown/AsyncDropdown";
import PropTypes from "prop-types";

export const PaginationDropdown = ({ onChange, defaultValue }) => {
    const dropdownValues = [
        { label: 5, value: 5 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
    ];

    const preparedDefaultValue = dropdownValues.find(item => {
        return +item.value === +defaultValue
    }) || dropdownValues[0]

    return (
        <div className='w-20'>
            <AsyncDropdown
                loadOptions={() => {}}
                handleDropdownChange={(value) => onChange(value)}
                defaultOptions={dropdownValues}
                defaultValue={preparedDefaultValue}
                width='67px'
                height='32px'
                dataTestId='pagination-dropdown'
            />
        </div>
    )
}

PaginationDropdown.propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.object
}