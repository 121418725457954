import React, { useEffect } from 'react';
import './Table.css';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TableRowContainer } from 'components/global/Table2/TableRow/TableRowContainer';
import { TableHeader } from 'components/global/Table2/TableHeader/TableHeader';
import { TableFilter } from 'components/global/Table2/TableFilter/TableFilter';
import PageStatistics from 'components/global/Table/PageStatistics';
import { PaginationButtons } from 'components/global/Table2/TablePagination/PaginationButtons';
import { TableEditRow } from 'components/global/Table2/TableRow/TableEditRow/TableEditRow';
import {getPreparedEditedRow} from "utils/helpers/benefitBuilder.helper";
import {getErrors} from "utils/helpers/tables.helper";
import {PaginationDropdown} from "components/global/Table/PaginationDropdown";

export const Table = ({
	data,
    params,
    tableName,
    menuItems,
    onSort,
    onFilter,
    onPagination,
    isRowMenuCollapsed,
    onPaginationDropdown,
    dataTestId
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch.tables.setTable({
            tableName,
            data,
            params
        });
    }, [
        data?.rows,
        data?.columns,
        data?.filters,
        data?.from,
        data?.to,
        data?.current_page,
        data?.last_page,
        params
    ]);

    const tableData = useSelector((state) => state.tables[tableName]);
    const tableRows = tableData?.rows;
    const editRowData = tableData?.rowData;
    const newRowFields = tableData?.rowFields;
    const saveEndpoint = tableData?.saveEndpoint;
    const editEndpoint = tableData?.editEndpoint;
    const defaultPerPage = tableData?.perPage;
    const errors = getErrors({ errors: tableData?.errors })

    const onAddRowCancel = (e) => {
        e.preventDefault();
        dispatch.tables.setCancelAddRow({ tableName });
    }

    const onAddRowChange = (field) => {
        dispatch.tables.setRowFields({ tableName, field });
    }

    const onAddRowSubmit = () => {
        dispatch.tables.setSubmitRow({
            tableName,
            saveEndpoint,
            newRowFields
        });
    }

    const onEditRowSubmit = () => {
        const prepareEditedRow = getPreparedEditedRow({
            tableData,
            newRowFields,
            editRowData
        })

        dispatch.tables.setSubmitEditRow({
            tableName,
            editEndpoint,
            editedRow: {...prepareEditedRow, row_id: tableData.editRowId}
        });
    }

    return (
        <div className='table-container'>
            <TableFilter
                filters={tableData?.filters}
                params={tableData?.params?.filters}
                onFilter={onFilter}
                dataTestId={dataTestId}
            />
            <TableHeader
                columns={tableData?.columns}
                params={tableData?.params}
                onSort={onSort}
                dataTestId={dataTestId}
            />
            {
                tableData?.addNewRow && tableData?.rowData?.length ?
                    <TableEditRow
                        rowData={tableData?.rowData}
                        newRowFields={newRowFields}
                        dataTestId={dataTestId}
                        onChange={onAddRowChange}
                        onSubmit={onAddRowSubmit}
                        onCancel={onAddRowCancel}
                        errors={errors}
                    />
                    : null
            }
            {
                tableRows?.map(item => {
                    return (
                        <TableRowContainer
                            key={item.id}
                            row={item}
                            columns={tableData?.columns}
                            menuItems={menuItems}
                            isEdit={item.id === tableData?.editRowId}
                            rowData={tableData?.rowData}
                            newRowFields={newRowFields}
                            isCollapsed={isRowMenuCollapsed}
                            onChange={onAddRowChange}
                            onSubmit={onEditRowSubmit}
                            onCancel={onAddRowCancel}
                            errors={errors}
                            dataTestId={dataTestId}
                        />
                    )
                })
            }
            {
                tableData?.from &&
                tableData?.to &&
                tableData?.total &&
                <div className='table-footer-container'>
                    <PageStatistics fromEntry={tableData?.from} toEntry={tableData?.to} totalEntries={tableData?.total} />
                    <div className='table-pagination-container'>
                        {
                            onPaginationDropdown &&
                                <PaginationDropdown
                                    onChange={onPaginationDropdown}
                                    defaultValue={defaultPerPage}
                                />
                        }
                        {
                            tableData?.total > 10 ?
                                <PaginationButtons
                                    onPagination={onPagination}
                                    currentPage={tableData?.current_page}
                                    lastPage={tableData?.last_page}
                                    dataTestId={dataTestId}
                                />
                                : null
                        }
                    </div>
                </div>
            }
        </div>
    );
};

Table.propTypes = {
    data: PropTypes.object,
    params: PropTypes.object,
    tableName: PropTypes.string.isRequired,
    onSort: PropTypes.func,
    onPagination: PropTypes.func,
    onFilter: PropTypes.func,
    isRowMenuCollapsed: PropTypes.bool,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        onClick: PropTypes.func
    })),
    onPaginationDropdown: PropTypes.func,
    dataTestId: PropTypes.string
}
