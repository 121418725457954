export default {
    state: {
        userTypes: []
    },
    reducers: {
        userTypes(state, payload) {
            return {
                ...state,
                userTypes: payload
            }
        }
    },
    effects: () => ({
        setUserTypes(payload) {
            this.userTypes(payload)
        }
    })
}