import React, { useEffect, useState } from 'react'
import PropTypes, { func, number } from "prop-types";
import tw from 'twin.macro'
import styled from 'styled-components/macro'
import { Icons, IconsNames } from 'utils/icons'
import { AUTHENTICATION_TIMEOUT_MODAL_INFO_CONTINUE, AUTHENTICATION_TIMEOUT_MODAL_INFO_LOGOUT } from 'utils/messages';

const TimerContainer = styled.div`
    ${tw`flex flex-row items-center pt-6 text-16-32`}
`

const Icon = styled.div`
    ${tw`flex mr-2 text-bright-purple`}
`

const Label = styled.div`
    ${tw`flex`}
`
export const TimeoutModal = ({ onTimerFinish, startValue }) => {
    const [time, setTime] = useState(startValue)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(prevTime => prevTime - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        if (time <= 0) {
            if (onTimerFinish) {
                onTimerFinish()
            }
        }
    }, [time])

	return (
		<div>
			<p>{AUTHENTICATION_TIMEOUT_MODAL_INFO_CONTINUE}</p>
			<p>{AUTHENTICATION_TIMEOUT_MODAL_INFO_LOGOUT}</p>
            <TimerContainer>
                <Icon>
                    {Icons[IconsNames.Timer]()}
                </Icon>
                <Label>
                    {time} seconds left
                </Label>
            </TimerContainer>
		</div>
	)
}

TimeoutModal.propTypes = {
    onTimerFinish: func,
    startValue: number
}

TimeoutModal.defaultProps = {
    startValue: 60
}
