import {NAVIGATION_TO_DASHBOARD} from 'utils/messages';

export default {
    state: {
        currentMenuItem: NAVIGATION_TO_DASHBOARD
    },
    reducers: {
        currentMenuItem(state, menuItem) {
            return {
                ...state,
                currentMenuItem: menuItem
            }
        }
    },
    effects: () => ({
        setCurrentMenuItem(menuItem) {
            this.currentMenuItem(menuItem)
        }
    })
}