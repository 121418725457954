import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from "components/buttons/Button/Button";
import {Icons, IconsNames} from "utils/icons";
import {ButtonLabels, ButtonTypes} from "utils/constants";

export const CollapsibleContainer = ({
    entries,
    visibleEntriesAmount,
    renderList
}) => {
    const [isHiddenVisible, setIsHiddenVisible] = useState(false);

    const handleClick = () => {
        setIsHiddenVisible(!isHiddenVisible);
    };

    const shownList = entries?.slice(0, visibleEntriesAmount);
    const hiddenList = entries?.slice(visibleEntriesAmount, entries.length);

    return (
        <>
            {renderList(shownList)}
            {hiddenList?.length && !isHiddenVisible ?
                <Button
                    icon={Icons[IconsNames.Eye]}
                    label={ButtonLabels.SeeAll}
                    alt={ButtonLabels.SeeAll}
                    type={ButtonTypes.SeeAll}
                    handleOnClick={handleClick}
                    dataTestId='see-all-button'
                />
                : null
            }
            {isHiddenVisible && renderList(hiddenList, isHiddenVisible)}
            {isHiddenVisible ?
                <Button
                    icon={Icons[IconsNames.Collapse]}
                    label={ButtonLabels.Collapse}
                    alt={ButtonLabels.Collapse}
                    type={ButtonTypes.SeeAll}
                    handleOnClick={handleClick}
                    dataTestId='collapse-button'
                />
                : null
            }
        </>
    )
}

CollapsibleContainer.propTypes = {
    entries: PropTypes.arrayOf(PropTypes.object).isRequired,
    visibleEntriesAmount: PropTypes.number.isRequired,
    renderList: PropTypes.func.isRequired,
}