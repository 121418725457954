import React from 'react'
import './BenefitFormField.css'
import PropTypes from 'prop-types'
import { buildDropdownOption } from 'utils/helpers/dropdown.helper'
import { Button } from 'components/buttons/Button/Button'
import { FormFieldLabelSizes } from 'components/formFields/FormField/FormField'
import { ButtonTypes , FieldTypes } from 'utils/constants'
import { Icons } from 'utils/icons'
import { FormDropdown2 } from 'components/formFields/FormDropdown2'
import { FormDatePicker2 } from 'components/formFields/FormDatePicker2/FormDatePicker2'
import { FormInput2 } from 'components/formFields/FormInput2/FormInput2'
import { FormDropdownCheckboxList } from 'components/formFields/FormDropdownCheckboxList'
import { sort } from 'utils/helpers/common.helper'
import { FormEditor } from 'components/formFields/FormEditor';
import { FormCheckbox } from 'components/formFields/FormCheckbox';
import { FormFileUpload } from "components/formFields/FormFileUpload/FormFileUpload";
import { BenefitBuilderTable } from "components/benefit/BenefitBuilderTable/BenefitBuilderTable";
import { FormRadioButtonsList } from 'components/formFields/FormRadioButtonsList'
import { getRadioButtonsList, getPreparedRadioButtons } from 'utils/helpers/benefitBuilder.helper'
import { FormRadioButtons } from "components/formFields/FormRadioButtons";
import { DELETE_ROW_MODAL_APPLY, DELETE_ROW_MODAL_BODY, DELETE_ROW_MODAL_CANCEL, DELETE_ROW_MODAL_HEADER } from 'utils/messages'
import { useDispatch } from 'react-redux'

const getSortedOptions = (array) => {
	return sort(array.map(({ id, name, required }) => buildDropdownOption(name, id, required)), 'label')
}

const ModalMessage = <div>{DELETE_ROW_MODAL_BODY}</div>

const dropdownCheckboxListProps = (field, value) => {
	const loadCallback = (_, callback) => callback(getSortedOptions(field.options))

	return {
		checkboxes: value?.checkboxes || [],
		placeholder: field.placeholder,
		isSearchable: true,
		dropdownLabel: field.label,
		isRequired: field.required,
		excludedOptions: value?.checkboxes || [],
		loadCallback,
		fieldName: field.field_id,
		defaultOptions: true,
	}
}

const dropdownProps = (field, value) => {
	const defaultOption = field.options.find((option) => option.default)
	const defaultDropdownOption = defaultOption ? buildDropdownOption(defaultOption.name, defaultOption.id) : null

	const loadCallback = (_, callback) => callback(getSortedOptions(field.options))

	return {
		loadCallback,
		label: field.label,
		value: value,
		defaultValue: defaultDropdownOption,
		isSearchable: true,
		placeholder: field.placeholder,
		fieldName: field.field_id,
		defaultOptions: true,
		isRequired: field.required,
	}
}

const datePickerProps = (field, value) => {
	return {
		label: field.label,
		content: value,
		placeholder: field.placeholder,
		fieldName: field.field_id,
		isRequired: field.required,
	}
}

const inputProps = (field, value) => {
	return {
		type: field.type,
		label: field.label,
		min: field.min,
		max: field.max,
		value: value || '',
		placeholder: field.placeholder,
		fieldName: field.field_id,
		isRequired: field.required,
	}
}

const fileUploadProps = (field, value) => {
	return {
		label: field.label,
		defaultValue: value,
		isRequired: field.required,
		multiple: field.multiple,
		formats: field.formats,
		type: field.field_id,
		description: field.description,
		labelSize: FormFieldLabelSizes.Large,
		download: field.download
	}
}

const editorProps = (field, value) => {
	return {
		label: field.label,
		version: field.version,
		description: field.description,
		labelSize: FormFieldLabelSizes.Large,
		defaultValue: value,
		placeholder: field.placeholder,
		fieldName: field.field_id,
		isRequired: field.required,
	}
}

const checkboxProps = (field, value) => {
	return {
		label: field.label,
		defaultValue: value,
		fieldName: field.field_id,
		isRequired: field.required
	}
}

const tableProps = (field, value) => {
	return {
		data: {
			columns: field.table.columns,
			rows: field.table.rows,
			table_id: field.table.table_id
		},
		tableName: field.field_id
	}
}

const radioButtonsListProps = (field, value) => {
	const preparedOptions = getRadioButtonsList(field.options, value)

	return {
		label: field.label,
		value: value,
		options: preparedOptions,
		fieldName: field.field_id,
		isRequired: field.required
	}
}

const radioButtonsProps = (field, value) => {
	const preparedOptions = getPreparedRadioButtons(field, value)

	return {
		label: field.label,
		options: preparedOptions,
		fieldName: field.field_id,
		isRequired: field.required
	}
}

const Fields = {
	[FieldTypes.Dropdown]: (field, value) => (
		<FormDropdown2 {...dropdownProps(field, value)} />
	),
	[FieldTypes.DropdownCheckboxList]: (field, value) => (
		<FormDropdownCheckboxList {...dropdownCheckboxListProps(field, value)} />
	),
	[FieldTypes.Date]: (field, value) => (
		<FormDatePicker2 {...datePickerProps(field, value)} />
	),
	[FieldTypes.Number]: (field, value) => (
		<FormInput2 {...inputProps(field, value)} />
	),
	[FieldTypes.Text]: (field, value) => (
		<FormInput2 {...inputProps(field, value)} />
	),
	[FieldTypes.Editor]: (field, value) => (
		<FormEditor {...editorProps(field, value)} />
	),
	[FieldTypes.Checkbox]: (field, value) => (
		<FormCheckbox {...checkboxProps(field, value)} />
	),
	[FieldTypes.Table]: (field, value) => (
		<BenefitBuilderTable {...tableProps(field, value)} />
	),
	[FieldTypes.File]: (field, value) => (
		<FormFileUpload {...fileUploadProps(field, value)} />
	),
	[FieldTypes.RadioButtonsList]: (field, value) => (
		<FormRadioButtonsList {...radioButtonsListProps(field, value)} />
	),
	[FieldTypes.RadioButtons]: (field, value) => (
		<FormRadioButtons {...radioButtonsProps(field, value)} />
	)
}

const getFieldComponent = (field, value, dataTestId) =>
	Fields[field.type](field, value, dataTestId)

export const BenefitFormField = ({
	field,
	value,
	control,
	register,
	fieldSize,
	fileUploadMessage,
	fieldErrors,
	excludedOptions,
	hasDuplicatedRows,
	isLastColumn,
	onInnerButtonClick,
	onFieldChanged,
	onFieldRemove,
	dataTestId,
	errorAsHint
}) => {
	const dispatch = useDispatch()
	const fieldDataTestId = `${dataTestId}-${field.type}-${field.field_id}`

	const handleOnChange = (value) => {
		onFieldChanged({
			[field.field_id]: value,
		})
	}

	const handleOnRemove = (field) => {
		dispatch.modal.showModal({
			data: {
				title: DELETE_ROW_MODAL_HEADER,
				body: ModalMessage,
				primaryButtonLabel: DELETE_ROW_MODAL_APPLY,
				onPrimary: () => {
					onFieldRemove({ field })
					dispatch.modal.hideModal()
				},
				secondaryButtonLabel: DELETE_ROW_MODAL_CANCEL
			}
		});
	}

	const getButtons = () => {
		return field.buttons.map((button) => {
			return (
				<Button
					key={button.label}
					label={button.label}
					type={ButtonTypes.Edit}
					icon={Icons[button.icon]}
					handleOnClick={onInnerButtonClick}
				/>
			)
		})
	}

	const getSuffix = () => (
		<div
			className="form-field-suffix"
			data-testid={`${fieldDataTestId}-suffix`}
		>
			{field.suffix}
		</div>
	)
	const getPrefix = () => (
		<div
			className="form-field-prefix"
			data-testid={`${fieldDataTestId}-prefix`}
		>
			{field.prefix}
		</div>
	)

	return React.cloneElement(getFieldComponent(field, value), {
		buttons: field.buttons ? getButtons() : [],
		suffix: field.suffix ? getSuffix() : undefined,
		prefix: field.prefix ? getPrefix() : undefined,
		isError: Boolean(fieldErrors),
		fieldErrors,
		errorAsHint,
		control,
		fieldSize,
		fileUploadMessage,
		excludedOptions,
		isLastColumn,
		hasDuplicatedRows,
		register: undefined,
		dataTestId: fieldDataTestId,
		onRemove: handleOnRemove,
		onChange: handleOnChange,
	})
}

BenefitFormField.propTypes = {
	field: PropTypes.shape({
		label: PropTypes.string,
		placeholder: PropTypes.string,
		field_id: PropTypes.string,
		required: PropTypes.bool,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
					.isRequired,
				name: PropTypes.string,
				required: PropTypes.bool,
			})
		),
		type: PropTypes.string,
		max: PropTypes.number,
		min: PropTypes.number,
	}),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.number,
	]),
	control: PropTypes.object.isRequired,
	fileUploadMessage: PropTypes.string,
	register: PropTypes.func.isRequired,
	onFieldChanged: PropTypes.func.isRequired,
	dataTestId: PropTypes.string,
	onInnerButtonClick: PropTypes.func,
	errorAsHint: PropTypes.bool,
}
