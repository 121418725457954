import axios from 'axios';
import { FetchMethods } from 'utils/constants';
import {appendTrailingSlash, removeTrailingSlash} from "../utils/url";

export function generateAPIURL(baseUrl, endpoint) {
    return appendTrailingSlash(baseUrl) + removeTrailingSlash(endpoint);
}

/**
 * perform a query with specified parameters
 * @param {string} endpoint URL for the query
 * @param {Object} options options wor the query
 */

export default async function fetchApi(endpoint, options = { method: FetchMethods.Get }) {

    return axios(generateAPIURL(process.env.GATSBY_BEAM_API_URL, endpoint), {
            withCredentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-User-Type': window.localStorage.getItem('userTypeId')
            },
            ...options
        }
    )
}