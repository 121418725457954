import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	BenefitBuilderLabels,
	getButtonComponents, getTableId,
	getTableName,
	prepareFieldsData
} from 'utils/helpers/benefitBuilder.helper'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import {BENEFIT_PEOPLE_COVERED_STEP, CREATE_BENEFIT_ROW, GET_BENEFIT_ROW} from 'common/endpoints'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import { FormColumnSizes } from 'utils/constants'
import {useDispatch, useSelector} from "react-redux";
import {benefitBuilder} from "store/selectors/benefitBuilder";

export const PeopleCovered = ({ data, onChange }) => {
	const benefitID = useSelector(benefitBuilder.benefit)?.id
	const dispatch = useDispatch();
	const [state, setState] = useState()
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)
	const tableName = getTableName(state?.columns)
	const tableData = useSelector((state) => state.tables[tableName]);
	let limitChecker = true;

	const { register, control } = useForm()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_PEOPLE_COVERED_STEP((benefitBuilderContextAPI)))
				const [{ content, label, header_buttons, row_limit: multipleLimit }] = response.data.steps
				const columns = prepareFieldsData(content)

				setState({ columns, label, header_buttons, multipleLimit })

				onChange(label, {
					label,
					columns,
					header_buttons,
					multipleLimit,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.PeopleCovered, {
                    columns: [],
					header_buttons: [],
                    isLoaded: true
                })
			}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleAddNewRow = async () => {
		const tableId = getTableId({ columns: state?.columns, tableName })

		dispatch.tables.setAddRowData({
			tableName,
      		endpoint: GET_BENEFIT_ROW(benefitID,tableId),
			saveEndpoint: CREATE_BENEFIT_ROW(benefitID, tableId)
		});
	}

	const headerButtons = getButtonComponents(state?.header_buttons, () =>  handleAddNewRow())

	if (state?.columns) {
		if (tableData?.total && state?.multipleLimit) {
			limitChecker = tableData?.total < state?.multipleLimit;
		}
	}

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			headerButtons={headerButtons}
      		columnSize={FormColumnSizes.Single}
			limitChecker={limitChecker}
			control={control}
			register={register}
			errors={benefitBuilderErrors}
			onStepChanged={handleStepChange}
		/>
	)
}

PeopleCovered.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}
