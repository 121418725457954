import React from 'react';
import PropTypes from 'prop-types';
import './TableField.css';
import { AsyncDropdown } from 'components/commonFields/AsyncDropdown/AsyncDropdown';
import { buildDropdownOption}  from 'utils/helpers/dropdown.helper';

export const TableDropdown = ({ data, onChange, errors, dataTestId }) => {
    const {
        placeholder,
        field_id: fieldId,
        options,
        value,
        disabled
    } = data;

    const preparedOptions = options.map(item => buildDropdownOption(item.name, item.id));
    const defaultOption = options.find((option) => option.default)
    const preparedDefaultValue = value ?
      { label: value?.name, value: value?.id }
      : defaultOption ?
        buildDropdownOption(defaultOption?.name, defaultOption?.id)
        : null

    const handleChange = (item) => {
        onChange({
            value: JSON.parse(item.value),
            fieldId
        });
    }

    return (
        <div className='table-field-container'>
            <AsyncDropdown
                loadOptions={() => {}}
                placeholder={placeholder}
                handleDropdownChange={(e) => handleChange(e)}
                defaultValue={preparedDefaultValue}
                defaultOptions={preparedOptions}
                isDisabled={disabled}
                isError={errors?.length}
                dataTestId={`${dataTestId}-dropdown-${fieldId}`}
            />
            <div className='table-field-errors-wrapper'>
                {errors?.map((error) => <div>{error}</div>)}
            </div>
        </div>
    )
}

TableDropdown.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    errors: PropTypes.array
}
