import React, { useState } from 'react'
import './DropdownCheckboxList.css'
import PropTypes from 'prop-types';
import { AsyncDropdown } from 'components/commonFields/AsyncDropdown/AsyncDropdown';
import { convertOptionToCheckbox, convertCheckboxToOption } from 'components/forms/common/PrepareDataHelpers'
import {CollapsibleContainer} from "components/global/CollapsibleContainer/CollapsibleContainer";
import {Checkbox} from "components/commonFields/Checkbox/Checkbox";

export const DropdownCheckboxList = ({
	checkboxes,
	placeholder,
	dropdownLabel,
	checkboxListHeader,
	excludedOptions,
	onCheckboxListChange,
	isSearchable,
	loadCallback,
	handleDropdownChange,
	defaultOptions,
	onChange,
	dataTestId,
	infiniteScroll
}) => {

	const [state, setState] = useState({
		checkboxes: checkboxes || [],
		options: []
	})

	const onDropdownStateChange = (dropdownOption) => {
		if (Boolean(handleDropdownChange)) {
			handleDropdownChange(dropdownOption)
		}

		const newState = {
			options: [...state.options.filter((option) => option.value !== dropdownOption.value)],
			checkboxes: [...state.checkboxes, convertOptionToCheckbox(dropdownOption)]
		}

		setState(newState)

		if (Boolean(onChange)) {
			onChange(newState)
		}
	}

	const onCheckboxState = (checkboxOption) => {
		if (Boolean(handleDropdownChange)) {
			onCheckboxListChange(checkboxOption)
		}

		if (!checkboxOption.selected) {

			const newState = {
				options: [convertCheckboxToOption(checkboxOption), ...state.options],
				checkboxes: [...state.checkboxes.filter((checkbox) => checkbox.id !== checkboxOption.id)]
			}

			setState(newState)

			if (Boolean(onChange)) {
				onChange(newState)
			}
		}

	}

	const renderList = (array) => {
		return array.map(item => {
			return <Checkbox
				key={item?.id}
				checkboxData={item}
				onCheckboxChanged={onCheckboxState}
			/>
		});
	};
	
	return (
		<div
			className="dropdown-checkbox-fields-container"
			data-testid={dataTestId}
		>
			<div className="dropdown-checkbox-list-container">
				<AsyncDropdown
                    loadOptions={loadCallback}
					placeholder={placeholder}
					isSearchable={isSearchable}
					dropdownLabel={dropdownLabel}
					handleDropdownChange={onDropdownStateChange}
					defaultOptions={defaultOptions}
					excludedOptions={excludedOptions}
					excludeArray={checkboxes}
					dataTestId={dataTestId}
					infiniteScroll={infiniteScroll}
				/>
			</div>
			<div className="checkbox-list-container">
				<div className="checkboxes-list" data-testid={dataTestId + '-checkbox-list'}>
					<div className='checkboxes-list-header'>{checkboxListHeader}</div>
					<div className='checkboxes-list-container'>
						<CollapsibleContainer
							entries={checkboxes}
							visibleEntriesAmount={12}
							renderList={renderList}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

DropdownCheckboxList.propTypes = {
	checkboxes: PropTypes.array,
	placeholder: PropTypes.string,
	dropdownLabel: PropTypes.string,
	checkboxListHeader: PropTypes.string,
	excludedOptions: PropTypes.array,
	onCheckboxListChange: PropTypes.func,
	isSearchable: PropTypes.bool,
	loadCallback: PropTypes.func,
	handleDropdownChange: PropTypes.func,
	defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
	infiniteScroll: PropTypes.bool
}

DropdownCheckboxList.defaultOptions = {
	checkboxListHeader: 'Your selections'
}
