import React from 'react';
import './TableViewItem.css';
import PropTypes from 'prop-types';

export const TableViewItem = ({ value, dataTestId }) => {
    return (
        <div
            className='table-view-item'
            data-testid={`${dataTestId}-item-${value}`}
        >
            {value}
        </div>
    )
}

TableViewItem.propTypes = {
    value: PropTypes.string.isRequired,
    dataTestId: PropTypes.string
}
