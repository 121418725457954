import React from 'react';
import PropTypes from 'prop-types';
import { CollapsedMenu } from "components/global/CollapsedMenu/CollapsedMenu";
import {Icons, IconsNames} from "utils/icons";

export const CollapsedRowMenu = ({ items, dataTestId, rowData, rowId }) => {
    return (
        <CollapsedMenu
            buttonIcon={Icons[IconsNames.ThreeDots]}
            items={items}
            rowData={rowData}
            rowId={rowId}
            dataTestId={`${dataTestId}-row`}
        />
    )
};

CollapsedRowMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.node,
        onClick: PropTypes.func
    })),
    dataTestId: PropTypes.string,
    rowId: PropTypes.number,
    rowData: PropTypes.object
}