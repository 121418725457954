import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import fetchApi from 'common/fetchApi'
import { BenefitBuilderLabels, getButtonComponents, getDuplicatedColumns, prepareFieldsData } from 'utils/helpers/benefitBuilder.helper'
import { BENEFIT_CONTRACT_TERM_STEP } from 'common/endpoints'
import { BenefitFormStep } from 'components/benefit/BenefitFormStep/BenefitFormStep'
import {useSelector} from "react-redux";
import {benefitBuilder} from "store/selectors/benefitBuilder";

export const ContractTerm = ({ data, onChange }) => {
	const [state, setState] = useState()
	const benefitBuilderContextAPI = useSelector(benefitBuilder.contextAPI)
	const benefitBuilderErrors = useSelector(benefitBuilder.errors)
	const { register, control } = useForm()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchApi(BENEFIT_CONTRACT_TERM_STEP(benefitBuilderContextAPI))
				const [{ content, label, footer_buttons }] = response.data.steps
				const columns = prepareFieldsData(content)

				setState({ columns, label, footer_buttons })

				onChange(label, {
					label,
					columns,
					footer_buttons,
					isLoaded: true,
				})
			} catch {
				onChange(BenefitBuilderLabels.ContractTerm, {
                    columns: [],
					footer_buttons: [],
                    isLoaded: true
                })
			}
		}

		if (!data?.isLoaded) {
			fetchData()
		}
	}, [])

	useEffect(() => {
		if (data) {
			setState(data)
		}
	}, [data])

	const handleStepChange = (label, data) => onChange(label, data)

	const handleDuplicateButtonClick = (label, columns) => {
		onChange(label, {
			columns: getDuplicatedColumns(columns),
		})
	}

	const footerButtons = getButtonComponents(state?.footer_buttons, (options) =>
		handleDuplicateButtonClick(state?.label, state?.columns)
	)

	return (
		<BenefitFormStep
			label={state?.label}
			columns={state?.columns}
			errors={benefitBuilderErrors}
			footerButtons={footerButtons}
			control={control}
			register={register}
			onStepChanged={handleStepChange}
		/>
	)
}

ContractTerm.propTypes = {
	data: PropTypes.object,
	onChange: PropTypes.func,
}