import fetchApi from 'common/fetchApi'
import { ADD_USER } from 'common/endpoints'
import { prepareDate } from 'utils/helpers/format.helper'
import { GET_ROLES } from 'common/endpoints';
import { FetchMethods } from 'utils/constants';

const getRoleById = async (id) => {
	try {
		const response = await fetchApi(`${GET_ROLES}/${id}`)
		const { data } = response

		return data
	} catch (error) {}
}

const defaultState = {
	steps: {
		0: {
			isExpanded: true,
		},
		1: {
			isExpanded: false,
		},
		2: {
			isExpanded: false,
		},
	},
	data: {
		first_name: null,
		surname: null,
		email: null,
		date_of_birth: null,
	},

	errors: {
		date_of_birth: []
	},
	isSending: false,
	isLoading: false,
	isError: false,
	user_type_id: null,
	role_name: null,
	successMessage: '',
	role: null,
	permissions: null
}

export default {
	state: defaultState,
	reducers: {
		stepInfo(state, payload) {
			return {
				...state,
				steps: {
					...state.steps,
					[payload.step - 1]: {
						isExpanded: payload.isExpanded,
						isCompleted: payload.isCompleted,
					},
				},
			}
		},

		data(state, { data }) {
			return {
				...state,
				data: {
					...state.data,
					...data,
					date_of_birth: prepareDate(data?.date_of_birth),
				},
			}
		},

		newUserType(state, payload) {
			return {
				...state,
				user_type_id: payload,
			}
		},

		newUserRole(state, payload) {
			return {
				...state,
				role_name: payload,
			}
		},

		collapseAll(state, payload) {
			const collapsedSteps = Object.values(state.steps).map((item, index) => {
				if (index !== payload.step - 1) return { ...item, isExpanded: false }
				return { ...item, isExpanded: true }
			})

			return {
				...state,
				steps: {
					...collapsedSteps,
				},
			}
		},

		isSending(state, { isSending }) {
			return {
				...state,
				isSending,
			}
		},

		role(state, { role }) {
			return {
				...state,
				role,
			}
		},

		permissions(state, { permissions }) {
			return {
				...state,
				permissions,
			}
		},

		isLoading(state, { isLoading }) {
			return {
				...state,
				isLoading,
			}
		},

		isError(state, { isError }) {
			return {
				...state,
				isError,
			}
		},

		successMessage(state, { successMessage }) {
			return {
				...state,
				successMessage,
			}
		},

		clearStore() {
			return defaultState
		},

		errors(state, { errors }) {
			return {
				...state,
				errors: {
					...errors,
				},
			}
		},
	},
	effects: () => ({
		async setSubmittedData({ data, user_type_id, role_name }) {
			this.setIsSending({ isSending: true })
			this.setIsError({ isError: false })
			this.setSuccessMessage({ successMessage: '' })

			try {
				const response = await fetchApi(ADD_USER, {
					method: FetchMethods.Post,
					data: {
						...data,
						user_type_id,
						role_name: role_name || [],
					},
				})

				this.setErrors({})
				this.completeStep({ step: 2 })
				this.setSuccessMessage({ successMessage: response.data.message })
				this.setIsSending({ isSending: false })
			} catch (error) {
				const errors = error.response.data.errors

				this.closeStep({ step: 2 })
				this.openStep({ step: 1 })
				this.setErrors({ errors })
				this.setIsError({ isError: true })
				this.setIsSending({ isSending: false })
			}
		},

		async setRoleData({ role: selectedRole, userTypeId }) {
			this.isLoading({ isLoading: true })

			if (Boolean(selectedRole) && Boolean(selectedRole?.length)) {
				const response = await fetchApi(GET_ROLES, {
					method: FetchMethods.Get,
					params: {
						search: selectedRole,
						user_type_id: userTypeId
					}
				});
				const id = response.data?.data[0]?.id;

				getRoleById(id).then((data) => {
					this.setPermissions({ permissions: data.role.permissions }).then(() => {
						this.setIsLoading({ isLoading: false })
					})
				})
			} else {
				this.setIsLoading({ isLoading: false })
			}
		},

		closeStep({ step }) {
			this.setStepInfo({
				step,
				isExpanded: false,
			})
		},

		openStep({ step }) {
			this.setStepInfo({
				step,
				isExpanded: true,
			})
		},

		completeStep({ step }) {
			this.setStepInfo({
				step,
				isExpanded: false,
				isCompleted: true,
			})
		},

		setStepInfo(payload) {
			this.stepInfo(payload)
		},

		setRole({ role }) {
			this.role({ role })
		},

		setPermissions({ permissions }) {
			this.permissions({ permissions })
		},

		setIsError({ setIsError }) {
			this.isError({ setIsError })
		},

		setIsSending({ isSending }) {
			this.isSending({ isSending })
		},

		setIsLoading({ isLoading }) {
			this.isLoading({ isLoading })
		},

		setData(payload) {
			this.data(payload)
		},

		setSuccessMessage({ successMessage }) {
			this.successMessage({ successMessage })
		},

		setNewUserType(payload) {
			this.newUserType(payload)
		},

		setNewUserRole(payload) {
			this.newUserRole(payload)
		},

		setCollapseAll(payload) {
			this.collapseAll(payload)
		},

		setErrors(payload) {
			this.errors(payload)
		},

		setClearStore() {
			this.clearStore()
		},
	}),
}
