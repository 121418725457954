import React, { useEffect, useRef, useState } from 'react'
import './FormFileUpload.css'
import PropTypes from 'prop-types'
import { FormField } from 'components/formFields/FormField/FormField'
import { Icons, IconsNames } from 'utils/icons'
import {
	BROWSE_FILE,
	FILE_TO_ATTACH,
	FILE_UPLOAD_ERROR_MESSAGE,
	FILE_UPLOAD_SUCCESS_MESSAGE,
	FILE_UPLOAD_WARNING_HEADER,
	FILES_SUPPORTED
} from 'utils/messages'
import { Button } from 'components/buttons/Button/Button'
import { ButtonLabels, ButtonTypes, FetchMethods } from 'utils/constants'
import fetchApi from 'common/fetchApi'
import { REMOVE_FILE, UPLOAD_FILE } from 'common/endpoints'
import Loader from 'components/global/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { benefitBuilder } from 'store/selectors/benefitBuilder'
import { useDropzone } from 'react-dropzone'
import { Hint } from 'components/global/Hint/Hint'

export const FormFileUpload = ({
	label,
	isRequired,
	buttons,
	defaultValue,
	onChange,
	multiple,
	formats,
	type,
	dataTestId,
	fileUploadMessage,
	description,
	labelSize,
	download,
	fieldErrors,
	disabled,
	fileTypes,
	maxFiles,
	maxSize,
	minSize,
	onDrop,
	errorAsHint
}) => {
	const inputRef = useRef();
	const dispatch = useDispatch();
	const [files, setFiles] = useState([]);
	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showHint, setShowHint] = useState(false);
	const benefitID = useSelector(benefitBuilder.benefit)?.id;
	const templateFile = useSelector((state) => state.tables?.pricing_lookup?.templateFile);
	const uploadErrors = useSelector((state) => state.tables?.pricing_lookup?.errors);
	const isDisabled = !multiple && files.length;
	const hintType = errors?.length > 0 ? 'error' : 'success';

	const hintConfig = {
		error: { message: FILE_UPLOAD_ERROR_MESSAGE,
			type: 'error',
			icon: Icons[IconsNames.ExclamationMark],
			withSeparator: true
		},
		success: { message: FILE_UPLOAD_SUCCESS_MESSAGE,
			type: 'success',
			icon: Icons[IconsNames.CircleCheck],
		}
	}

	const {getRootProps, getInputProps} = useDropzone({
		accept: fileTypes,
		maxFiles,
		maxSize,
		minSize,
		disabled,
		onDrop: acceptedFiles => {
			handleOnChange(acceptedFiles)
			onDrop && onDrop(...acceptedFiles)
		}
	});

	const handleClickLink = () => {
		if (!multiple && !download && files.length) {
			return;
		}
		inputRef.current?.click();
	}

	const handleDeleteFile = async (file) => {
		setIsLoading(true);
		try {
			const response = await fetchApi(REMOVE_FILE, {
				method: FetchMethods.Post,
				data: { file }
			});

			if (response.data.success) {
				setFiles(previousState => previousState.filter(item => {
					return item.file !== file;
				}))
			}
			setIsLoading(false);
		} catch (error) {
			console.log(error)
			setIsLoading(false);
		}
	}

	const handleDownloadFile = async (endpoint) => {
		try {
			setIsLoading(true);
			const response = await fetchApi(endpoint, {
				method: FetchMethods.Get,
				responseType: 'blob'
			})
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'lookup-table.xlsx');
			document.body.appendChild(link);
			link.click();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	};

	const handleOnChange = (event) => {
		if (!download) {
			handleFileUpload(event)
			return
		}

		const file = event.target ? event.target.files[0] : event[0];

		if (Boolean(fileUploadMessage) && Boolean(templateFile) && Boolean(file)) {
			const FileUploadModal = <p>{fileUploadMessage}</p>

			const handleOnPrimary = () => {
				dispatch.modal.hideModal()
				handleTableUpload(event)
			}

			dispatch.modal.showModal({
				data: {
					title: FILE_UPLOAD_WARNING_HEADER,
					body: FileUploadModal,
					primaryButtonLabel: ButtonLabels.Ok,
					onPrimary: handleOnPrimary,
					secondaryButtonLabel: ButtonLabels.Cancel
				}
			});
		} else {
			handleTableUpload(event)
		}
		
	}

	const handleTableUpload = async (event) => {
		const formData = new FormData();
		const file = event.target ? event.target.files[0] : event[0];
		formData.append('files[]', file);
		formData.append('type', 'lookup');
		formData.append('benefitID', benefitID);

		try {
			setIsLoading(true);
			setErrors([])
			await dispatch.tables.setUploadTable({
				tableName: 'pricing_lookup',
				tableKey: 'lookup',
				benefitID,
				tableData: formData
			})
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
		finally {
			setShowHint(true);
		}
	}

	const handleFileUpload = async (event) => {
		const formData = new FormData();
		const files = event.target ? Array.from(event.target.files) : event;
		formData.append('type', type);

		files.forEach((item) => {
			formData.append('files[]', item)
		})

		try {
			setIsLoading(true);
			const response = await fetchApi(UPLOAD_FILE, {
				method: FetchMethods.Post,
				data: formData
			})
			response.data.files.forEach(file => {
					setFiles((previousState) => [...previousState, {
						name: file.label,
						file: file.file
					}]);
				})
			setErrors([])
			setIsLoading(false);
		} catch (error) {
			setErrors(Object.values(error.response.data.errors)[0]);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (defaultValue) {
			defaultValue.forEach(item => {
				setFiles((previousState) => [...previousState, {
					name: item.label || item.name,
					file: item.file
				}]);
			})
		}
	}, [])

	useEffect(() => {
		onChange(files);
	}, [files])

	useEffect(() => {
		if (fieldErrors && !errors.find(error => fieldErrors.find(fieldError => fieldError === error))) {
			setErrors([...fieldErrors, ...errors])
		}
		if (!fieldErrors) {
			setErrors([])
		}
		if (uploadErrors) {
			setErrors([...Object.values(uploadErrors), ...errors])
		}
	}, [fieldErrors, uploadErrors])

	if (isLoading) {
		return (
			<div>
				<Loader />
			</div>
		);
	}

	return (
		<FormField
			label={label}
			labelSize={labelSize}
			isRequired={isRequired}
			dataTestId={dataTestId}
			buttons={buttons}
			description={description}
			fieldErrors={errors}
			errorAsHint={errorAsHint}
		>
			<div className='form-file-upload-main-container' >
					<div className={`form-file-upload-container ${isDisabled && !download ? 'disabled' : ''}`}>
						<div className='form-file-upload-icon'>
							{Icons[IconsNames.UploadImage]()}
						</div>
						<div className='form-file-upload-area' {...getRootProps()}>
							<input
								ref={inputRef}
								onChange={handleOnChange}
								type='file'
								multiple={multiple}
								{...getInputProps()}
							/>
							<div>
								<a
									className='form-file-upload-link'
									onClick={handleClickLink}
									data-testid={`${dataTestId}-browse`}
								>
									{BROWSE_FILE}
								</a>
								<span>{FILE_TO_ATTACH}</span>
							</div>
							<span>{`${FILES_SUPPORTED} ${formats}`}</span>
						</div>
					</div>
				{ files.length > 0 && !download &&
						files.map(item => {
							return (
								<div
									key={item.name}
									className='form-file-upload-uploaded-file-container'
								>
									<a
										className='form-file-upload-uploaded-file-name'
										data-testid={`${dataTestId}-file-name`}
										href={item.file}
										target='_blank'
										rel='noopener noreferrer'
									>
										{item.name}
									</a>
									<Button
										type={ButtonTypes.DeleteFile}
										icon={Icons[IconsNames.Delete]}
										handleOnClick={() => handleDeleteFile(item.file)}
										dataTestId={dataTestId}
									/>
								</div>
							)
						})
				}
				{ files.length > 0 && download &&
						files.map(item => {
							return (
								<div
									key={templateFile?.label || item.name}
									className='form-file-upload-uploaded-file-container'
								>
									<span
										className='form-file-upload-uploaded-file-name'
										onClick={() => handleDownloadFile(templateFile?.file || item.file)}
										data-testid={`${dataTestId}-file-name`}
									>
										{templateFile?.label || item.name}
									</span>
									<Button
										type={ButtonTypes.DeleteFile}
										icon={Icons[IconsNames.Download]}
										handleOnClick={() => handleDownloadFile(templateFile?.file || item.file)}
										dataTestId={dataTestId}
									/>
								</div>
							)
						})
				}
				{ showHint && <Hint config={hintConfig[hintType]} /> }
			</div>
		</FormField>
	)
}

FormFileUpload.propTypes = {
	label: PropTypes.string,
	isRequired: PropTypes.bool,
	buttons: PropTypes.array,
	fileUploadMessage: PropTypes.string,
	defaultValue: PropTypes.array,
	onChange: PropTypes.func,
	multiple: PropTypes.bool,
	formats: PropTypes.string,
	type: PropTypes.string,
	dataTestId: PropTypes.string,
	description: PropTypes.string,
	download: PropTypes.bool,
	errorAsHint: PropTypes.bool
}
