import React from 'react'
import PropTypes from 'prop-types'
import { AsyncDropdown } from 'components/commonFields/AsyncDropdown/AsyncDropdown'
import { FormField } from 'components/formFields/FormField/FormField'

export const FormDropdown2 = ({
	label,
	isRequired,
	isDisabled,
	isReadOnly,
	isSearchable,
	hasDuplicatedRows,
	isMulti,
	defaultValue,
	fieldErrors,
	excludedOptions,
	isError,
	fieldSize,
	placeholder,
	buttons,
	value,
	error,
	fieldName,
	defaultOptions,
	loadCallback,
	onChange,
	hideFieldErrors,
	dataTestId,
}) => {
	const dropdownValue = hasDuplicatedRows 
		? value || null 
		: value || defaultValue

	return (
		<FormField
			label={label}
			fieldErrors={fieldErrors}
			fieldSize={fieldSize}
			isRequired={isRequired}
			dataTestId={dataTestId}
			hideFieldErrors={hideFieldErrors}
			buttons={buttons}
		>
			<AsyncDropdown
				loadOptions={loadCallback}
				placeholder={placeholder}
				isError={isError}
				isSearchable={isSearchable}
				handleDropdownChange={onChange}
				value={dropdownValue}
				defaultOptions={defaultOptions}
				excludedOptions={excludedOptions}
				excludeArray={[]}
				isReadOnly={isReadOnly}
				dataTestId={dataTestId}
			/>
		</FormField>
	)
}

FormDropdown2.propTypes = {
	label: PropTypes.string,
	isRequired: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isReadonly: PropTypes.bool,
	buttons: PropTypes.array,
	isSearchable: PropTypes.bool,
	isMulti: PropTypes.bool,
	defaultValue: PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	}),
	placeholder: PropTypes.string,
	error: PropTypes.any,
	fieldName: PropTypes.string,
	defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
	loadCallback: PropTypes.func.isRequired,
	onChange: PropTypes.func,
	dataTestId: PropTypes.string
}

FormDropdown2.defaultProps = {
	excludedOptions: []
}
